import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';

import { Params } from 'react-router-dom';

import { DateRange } from '@cast/types';
import { useCompoundReducer } from '@cast/utils';

import {
  RangePickerProvider,
  useRangePickerContext,
  RangePickerQueryParamKeys,
  DateRangeFilter,
} from 'components/date/RangePicker';
import { RangePickerProviderProps } from 'components/date/RangePicker/providers/types';
import { useReportCapabilitiesQuery } from 'hooks/queries/cost';
import { useCluster } from 'hooks/useCluster';
import { useParams } from 'hooks/useParams';
import { readStorage, writeStorage } from 'utils/storage';

import { CostReportChartType } from '../types/costOverTime';
import { adjustEndDate } from '../utils/costOverTime';

const STORE_KEY_BASE = 'cost-report.cluster';

type ReducerState = {
  /**
   * Report chart type
   */
  chartType: CostReportChartType;
  /**
   * Selected report date range. Use adjustedDateRange for querying
   */
  currentDateRange: DateRange;
  /**
   * Report timezone
   */
  timezone: string;
  /**
   * Report date range filter
   */
  currentDateRangeFilter?: DateRangeFilter;
};

export type CostReportProviderProps = PropsWithChildren<
  RangePickerProviderProps & {
    isDetails?: boolean;
  }
>;

const createInitialState = (
  currentDateRange: DateRange,
  timezone: string,
  currentDateRangeFilter?: DateRangeFilter
): ReducerState => {
  return {
    chartType: readStorage(
      `${STORE_KEY_BASE}.chart-type`,
      CostReportChartType.BAR
    ),
    currentDateRange,
    currentDateRangeFilter,
    timezone,
  };
};

type CostReportActions = {
  /**
   * Change report chart type handler
   */
  changeType: (chartType: CostReportChartType) => void;
  /**
   * Change report date range handler
   */
  changeDateRange: (value: DateRange) => void;
  /**
   * Change report date range filter handler
   */
  changeDateRangeFilter: (value: DateRangeFilter | undefined) => void;
};

type CostOverTimeContextValue = ReducerState &
  CostReportActions & {
    /**
     * Routing properties
     */
    routeProps: Params;

    /**
     * Indicates whether default range filter is applied.
     */
    isDefaultFilter: boolean;

    /**
     * Indicates if capabilities are loading.
     */
    isCapabilitiesLoading: boolean;

    /**
     * Indicates whether GPU exporter is installed.
     */
    isExporterInstalled: boolean;

    /**
     * Indicates if GPU data is being shown.
     */
    showGpuData: boolean;

    /**
     * Indicates if network data is available.
     */
    networkDataAvailable: boolean;

    /**
     * Refetch function for capabilities.
     */
    refetchCapabilities: () => void;

    /**
     * Error that occurred while fetching capabilities.
     */
    capabilitiesError: unknown;

    /**
     * The adjusted date range to use for querying
     */
    adjustedDateRange: DateRange;
  };

export const CostReportContext = createContext<CostOverTimeContextValue>(
  {} as never
);

const CostReportInnerProvider = ({
  children,
  routeProps,
}: CostReportProviderProps) => {
  const rangePickerCtx = useRangePickerContext();
  const { cluster } = useCluster();

  const { state, dispatcher } = useCompoundReducer(
    {
      changeType: (state, chartType: CostReportChartType) => {
        writeStorage(`${STORE_KEY_BASE}.chart-type`, chartType);
        return {
          ...state,
          chartType,
        };
      },
      changeDateRange: (state, dateRange: DateRange) => {
        return {
          ...state,
          currentDateRange: dateRange,
        };
      },
      changeDateRangeFilter: (state, filter: DateRangeFilter | undefined) => {
        return {
          ...state,
          currentDateRangeFilter: filter,
        };
      },
      changeTimezone: (state, timezone: string) => {
        return {
          ...state,
          timezone,
        };
      },
    },
    createInitialState(
      rangePickerCtx.currentDateRange,
      rangePickerCtx.timezone,
      rangePickerCtx.currentDateRangeFilter
    )
  );

  useEffect(() => {
    dispatcher.changeDateRange(rangePickerCtx.currentDateRange);
    dispatcher.changeDateRangeFilter(rangePickerCtx.currentDateRangeFilter);
    dispatcher.changeTimezone(rangePickerCtx.timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rangePickerCtx.currentDateRange,
    rangePickerCtx.currentDateRangeFilter,
    rangePickerCtx.timezone,
  ]);

  const adjustedDateRange = useMemo(
    () =>
      adjustEndDate(rangePickerCtx.currentDateRange, rangePickerCtx.timezone),
    [rangePickerCtx.currentDateRange, rangePickerCtx.timezone]
  );

  const {
    data: reportCapabilities,
    isLoading,
    refetch,
    error,
  } = useReportCapabilitiesQuery({
    clusterId: cluster.id,
    startTime: adjustedDateRange[0].toISOString(),
    endTime: adjustedDateRange[1].toISOString(),
    enabled: !!cluster.id,
  });

  return (
    <CostReportContext.Provider
      value={{
        ...state,
        ...dispatcher,
        isExporterInstalled: reportCapabilities?.gpuMetricsAvailable ?? false,
        showGpuData: reportCapabilities?.gpuDataAvailable ?? false,
        networkDataAvailable: reportCapabilities?.networkDataAvailable ?? false,
        isCapabilitiesLoading: isLoading,
        refetchCapabilities: refetch,
        capabilitiesError: error,
        isDefaultFilter: rangePickerCtx.isDefaultFilter,
        routeProps,
        adjustedDateRange,
      }}
    >
      {children}
    </CostReportContext.Provider>
  );
};

const queryParamKeys: RangePickerQueryParamKeys = {
  fromDate: 'cost_report_from',
  toDate: 'cost_report_to',
  dateRangePreset: 'cost_report_date_preset',
  timezone: 'cost_report_timezone',
};

export const CostReportProvider = ({
  children,
  ...props
}: CostReportProviderProps) => {
  const params = useParams();
  return (
    <RangePickerProvider
      queryParamKeys={queryParamKeys}
      routeProps={params}
      {...props}
    >
      <CostReportInnerProvider routeProps={params} {...props}>
        {children}
      </CostReportInnerProvider>
    </RangePickerProvider>
  );
};
