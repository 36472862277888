import { Box, Stack, Typography } from '@mui/material';

import { Icons } from '@cast/design-system';

import { EXTERNAL_LINKS } from 'common/links';
import { HexIconError } from 'components/hex-icons';
import { ExternalLink } from 'components/router';

export type ErrorStateProps = {
  title: string;
  testId?: string;
};

export const ErrorState = ({ title, testId }: ErrorStateProps) => {
  return (
    <Stack
      display="grid"
      columnGap={12}
      rowGap={4}
      gridTemplateColumns="auto 1fr"
      gridTemplateAreas={`
        "icon title"
        "empty content"
      `}
      alignItems="center"
      data-testid={testId}
    >
      <HexIconError
        display="flex"
        gridArea="icon"
        size={44}
        animationType="pulsateSlow"
        icon={<Icons.Warning />}
        iconSize={16}
      />
      <Typography gridArea="title" variant="h6">
        {title}
      </Typography>
      <Box
        gridArea="content"
        component="ul"
        sx={{
          pl: 18,
          my: 0,
          '& li': { typography: 'P12R', color: 'grey.500' },
        }}
      >
        <li>Double-check if you ran the script correctly</li>
        <li>
          Check our{' '}
          <ExternalLink
            sx={{ typography: 'P12M' }}
            link={EXTERNAL_LINKS.docs}
            noIcon
          >
            documentation
          </ExternalLink>
        </li>
        <li>
          Ask a question on our{' '}
          <ExternalLink
            sx={{ typography: 'P12M' }}
            link={EXTERNAL_LINKS.castai_slack_community}
            noIcon
          >
            Community Slack
          </ExternalLink>
        </li>
      </Box>
    </Stack>
  );
};
