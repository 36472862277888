import dayjs, { Dayjs } from 'dayjs';
import round from 'lodash/round';
import pluralize from 'pluralize';
import { Matcher, TZDate } from 'react-day-picker';

import { FilterPreset, DatePeriod, DatePeriodInTimestamp } from './types';

export const buildPresetRange = (
  preset: FilterPreset,
  timezone: string
): DatePeriod => {
  return {
    from: new TZDate(preset.date(timezone).from, timezone),
    to: new TZDate(preset.date(timezone).to, timezone),
  };
};

export const isSelectedFilterPreset = (
  value: DatePeriod,
  filter: FilterPreset,
  timezone: string
): boolean => {
  const presetRange = buildPresetRange(filter, timezone);

  return (
    !!value?.from &&
    Math.abs(dayjs(value.from).diff(presetRange.from, 'minutes')) < 5 &&
    !!value?.to &&
    Math.abs(dayjs(value.to).diff(presetRange.to, 'minutes')) < 5
  );
};

export const utcToDate = (timestamp: string, timezone: string): TZDate => {
  return new TZDate(timestamp, timezone);
};

export const utcToRange = (
  range: { from?: string; to?: string } | undefined,
  timezone: string
): DatePeriod => {
  return {
    from: range?.from ? new TZDate(range.from, timezone) : undefined,
    to: range?.to ? new TZDate(range.to, timezone) : undefined,
  };
};

export const rangeToUtc = ({ from, to }: DatePeriod): DatePeriodInTimestamp => {
  return {
    from: from ? from.toUTCString() : undefined,
    to: to ? to.toUTCString() : undefined,
  };
};

export const convertDate = (
  date: TZDate | undefined,
  oldTimezone: string,
  newTimezone: string
) => {
  /*
    dayjs.tz does not convert in between +00:00 timezones correctly...
    github issue: https://github.com/iamkun/dayjs/issues/1827#issuecomment-2479062677
  */
  const keepLocalTime =
    dayjs().tz(oldTimezone).format('Z') !== '+00:00' ||
    dayjs().tz(newTimezone).format('Z') !== '+00:00';

  return date
    ? new TZDate(
        dayjs.tz(date, oldTimezone).tz(newTimezone, keepLocalTime).toDate(),
        newTimezone
      )
    : undefined;
};

export const convertRange = (
  range: DatePeriod,
  oldTimezone: string,
  newTimezone: string
): DatePeriod => {
  return {
    from: convertDate(range.from, oldTimezone, newTimezone),
    to: convertDate(range.to, oldTimezone, newTimezone),
  };
};

export const utcNow = (): string => dayjs.utc().toISOString();

export const inputStringToDayjs = (
  dateInput: string,
  format: string,
  timezone: string
): Dayjs => dayjs(dateInput, format).tz(timezone, true);

export const dayjsToTZDate = (date: Dayjs, timezone: string): TZDate =>
  new TZDate(date.toDate(), timezone);

export const dateToInputString = (
  date: TZDate,
  format: string,
  timezone: string
): string => dayjs.tz(date, timezone).format(format);

export const buildDisabledMatcher = (
  minDate: string | undefined,
  maxDate: string | undefined,
  timezone: string
): Matcher | undefined => {
  if (minDate && maxDate) {
    return {
      before: new TZDate(minDate, timezone),
      after: new TZDate(maxDate, timezone),
    };
  }

  if (maxDate) {
    return { after: new TZDate(maxDate, timezone) };
  }

  if (minDate) {
    return { before: new TZDate(minDate, timezone) };
  }
};

export const periodToString = (activePeriod: number) => {
  if (activePeriod < 60) {
    return `${activePeriod} ${pluralize('minute', activePeriod)}`;
  }

  if (activePeriod < 60 * 24) {
    const period = round(activePeriod / 60, 0);
    return `${period} ${pluralize('hour', period)}`;
  }

  const period = round(activePeriod / 60 / 24, 0);
  return `${period} ${pluralize('day', period)}`;
};

export const shouldHideHoverTrail = (
  selectedRange?: DatePeriod,
  hoveredDay?: TZDate,
  maxNumberOfDays?: number
): boolean => {
  if (
    !selectedRange?.from ||
    selectedRange?.to ||
    !hoveredDay ||
    dayjs(selectedRange.from).isSame(hoveredDay)
  ) {
    return true;
  }

  // hide trail if range is after max number of days limit
  if (maxNumberOfDays) {
    const hovered = dayjs(hoveredDay);
    const fromDate = dayjs(selectedRange.from);

    if (
      hovered.isAfter(fromDate) &&
      hovered.isAfter(fromDate.add(maxNumberOfDays - 1, 'day'))
    ) {
      return true;
    }

    if (
      hovered.isBefore(fromDate) &&
      hovered.isBefore(fromDate.subtract(maxNumberOfDays - 1, 'day'))
    ) {
      return true;
    }
  }

  return false;
};
