import { useClusterQuery } from 'hooks/queries/cluster';

import { useWasEnabled } from './useWasEnabled';
import { OnboardingPhase2HookProps } from '../types';

export const useWasCredentialsAssigned = ({
  clusterId,
  onSuccess,
  onError,
  enabled,
}: OnboardingPhase2HookProps): boolean => {
  const { cluster: _cluster, error } = useClusterQuery(
    clusterId,
    enabled ? undefined : false
  );

  const isManaged = Boolean(_cluster?.credentialsId);

  useWasEnabled({
    currentState: isManaged,
    onSuccess: onSuccess,
    onError: onError,
    error,
    enabled,
  });

  return isManaged;
};
