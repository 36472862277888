import { useRef } from 'react';

import { useModal } from 'mui-modal-provider';

import { Cluster } from '@cast/types';

import { clusterEvents } from 'core/analytics';
import { useCluster } from 'hooks/useCluster';
import { ShowModalOutput } from 'types/modal';

import { AssignCredentialsDialog } from './AssignCredentialsDialog';

type Props = {
  cluster?: Cluster;
};

export const useAssignCredentialsDialog = ({
  cluster: clusterProp,
}: Props = {}) => {
  const modalRef = useRef<ShowModalOutput>();
  const { cluster: clusterFromContext } = useCluster();
  const cluster = clusterProp || clusterFromContext;
  const { showModal } = useModal();

  const destroy = () => modalRef.current?.destroy();

  const openAssignCredentialsDialog = () => {
    if (cluster) {
      clusterEvents.startSavingNow(cluster);

      modalRef.current = showModal(
        AssignCredentialsDialog,
        {
          cluster,
          destroy,
        },
        { rootId: 'root' }
      );
    }
  };

  return { openAssignCredentialsDialog, canOpen: !!cluster };
};
