import { useEffect, useMemo, useState } from 'react';

import { AgentStatus } from '@cast/types';
import {
  DEMO_CLUSTER_ID,
  hasNoSecurityAgentsEnabled,
  isSecurityDemoMode,
  removeSecurityDemoMode,
  isDemoMode,
  removeDemoMode,
  setDemoMode,
  setSecurityDemoMode,
} from '@cast/utils';

import { useClustersQuery } from 'hooks/queries/cluster';
import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';
import { useOrganizations } from 'hooks/useOrganizations';

type UseDetermineDemoModeArgs = {
  enabled: boolean;
};

export const useDetermineDemoMode = ({ enabled }: UseDetermineDemoModeArgs) => {
  const { currentOrganization: organization } = useOrganizations();
  const [isDemoDeterminationProcessDone, setDemoDeterminationStatus] =
    useState(false);
  const [
    isSecurityDemoDeterminationProcessDone,
    setSecurityDemoDeterminationStatus,
  ] = useState(false);

  const { clusters } = useClustersQuery({
    polling: false,
    enabled,
  });
  const clusterIds = useMemo(() => {
    const clusterList = clusters ?? [];

    // Only retrieve the ids of active clusters from list
    return clusterList
      .filter(
        (cluster) =>
          ![AgentStatus.NOT_RESPONDING, AgentStatus.DISCONNECTED].includes(
            cluster.agentStatus
          )
      )
      .map((cluster) => cluster.id);
  }, [clusters]);
  const { agentsData, isLoading: isAgentsLoading } = useSecurityInsightsAgents({
    clusterIds,
    // Prevent internal determination logic from being run
    // since it's already handled in this hook
    determineSecurityDemoMode: false,
    enabled: enabled && !!clusterIds.length,
  });

  useEffect(() => {
    if (
      enabled &&
      !isDemoDeterminationProcessDone &&
      clusters &&
      organization
    ) {
      const orgHasOnlyDemoCluster =
        clusters.length === 1 && clusters[0].id === DEMO_CLUSTER_ID;

      if (isDemoMode()) {
        if (!orgHasOnlyDemoCluster) {
          removeDemoMode();
        }
      } else {
        if (orgHasOnlyDemoCluster) {
          setDemoMode(organization?.id);
        }
      }

      if (clusters.length > 0) {
        setDemoDeterminationStatus(true);
      }
    }
  }, [clusters, enabled, isDemoDeterminationProcessDone, organization]);

  useEffect(() => {
    if (
      isDemoDeterminationProcessDone &&
      !isSecurityDemoDeterminationProcessDone &&
      organization &&
      !isAgentsLoading
    ) {
      const securityNotEnabled = hasNoSecurityAgentsEnabled(
        agentsData?.agentStatuses
      );

      if (isSecurityDemoMode()) {
        if (!securityNotEnabled) {
          removeSecurityDemoMode();
        }
      } else {
        if (securityNotEnabled && !isDemoMode()) {
          setSecurityDemoMode(organization?.id);
        }
      }

      setSecurityDemoDeterminationStatus(true);
    }
  }, [
    agentsData?.agentStatuses,
    organization,
    isAgentsLoading,
    isSecurityDemoDeterminationProcessDone,
    isDemoDeterminationProcessDone,
  ]);

  return {
    isDemoDeterminationProcessDone,
    isSecurityDemoDeterminationProcessDone,
  };
};
