import { ReactNode } from 'react';

import { FormControlLabel, Stack, Typography } from '@mui/material';

import { Checkbox, IconButton, Icons } from '@cast/design-system';

import { EXTERNAL_LINKS } from 'common/links';
import { ExternalLink } from 'components/router';

import { ExtendedSettings } from '../types';

type SettingProps = {
  text: ReactNode;
  onChange: (enabled: boolean) => void;
  value: boolean;
  testId: string;
  label: string;
};

const Setting = ({ text, onChange, value, testId, label }: SettingProps) => {
  return (
    <Stack>
      <FormControlLabel
        sx={{ ml: '0' }}
        control={
          <Checkbox
            size="small"
            checked={value}
            onChange={(_, checked) => onChange(checked)}
            testId={testId}
          />
        }
        label={
          <Typography variant="P12B" pl={12}>
            {label}
          </Typography>
        }
      />
      <Typography variant="P12R" color="grey.600" pl={28}>
        {text}
      </Typography>
    </Stack>
  );
};

type ExpandedConfigurationProps = {
  settings: Partial<ExtendedSettings>;
  setSettings: (payload: Partial<ExtendedSettings>) => void;
  onClose: () => void;
};

export const ExpandedConfiguration = ({
  settings,
  setSettings,
  onClose,
}: ExpandedConfigurationProps) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Setting
        label="Install NVIDIA device plugin"
        testId="install-nvidia-checkbox"
        onChange={(checked) => setSettings({ includeGpuPlugin: checked })}
        value={!!settings.includeGpuPlugin}
        text={
          <>
            Required to run GPU enabled containers in your cluster.{' '}
            <ExternalLink link={EXTERNAL_LINKS.docs_gpu_config}>
              Learn more
            </ExternalLink>
          </>
        }
      />

      <IconButton
        variant="ghost"
        size="small"
        onClick={onClose}
        testId="close-manual-config-button"
      >
        <Icons.X />
      </IconButton>
    </Stack>
  );
};
