import dayjs from 'dayjs';
import { TZDate } from 'react-day-picker';

import { isSelectedFilterPreset } from '@cast/design-system';
import { DateRange, DateTime } from '@cast/types';
import { clearQueryParamSilently, setQueryParamSilently } from '@cast/utils';

import { allFilters } from './filters';
import { DateRangeFilter, RangePickerQueryParamKeys } from './types';

export const defaultFilters = [
  DateRangeFilter.ThisMonth,
  DateRangeFilter.Last7Days,
  DateRangeFilter.LastWeek,
  DateRangeFilter.Last2Weeks,
  DateRangeFilter.Last30Days,
  DateRangeFilter.LastMonth,
  DateRangeFilter.Last15Mins,
  DateRangeFilter.Last30Mins,
];

export const defaultQueryParamKeys: RangePickerQueryParamKeys = {
  fromDate: 'date_from',
  toDate: 'date_to',
  dateRangePreset: 'date_preset',
  timezone: 'timezone',
};

const isValidDateRange = (from: DateTime, to: DateTime, maxDiffInDays = 31) =>
  from.isValid() &&
  to.isValid() &&
  from.isBefore(to) &&
  to.diff(from, 'day') <= maxDiffInDays;

const getDateRangeFromQuery = (
  queryParams: URLSearchParams,
  queryParamKeys: RangePickerQueryParamKeys
): DateRange | undefined => {
  const fromDate = dayjs(queryParams.get(queryParamKeys.fromDate));
  const toDate = dayjs(queryParams.get(queryParamKeys.toDate));
  if (isValidDateRange(fromDate, toDate)) {
    return [fromDate, toDate];
  }
  return undefined;
};

const isValidPresetKey = (
  key: string | null | undefined,
  filters: DateRangeFilter[]
): key is DateRangeFilter => {
  return !!key && filters.includes(key as DateRangeFilter);
};

export const getDateRangeFilterFromQuery = (
  queryParams: URLSearchParams,
  filters: DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys
): DateRangeFilter | undefined => {
  const presetKey = queryParams.get(queryParamKeys.dateRangePreset);

  return isValidPresetKey(presetKey, filters) ? presetKey : undefined;
};

export const getDateRangeFilterFromValue = (
  value: DateRange,
  timezone: string,
  filters: DateRangeFilter[]
): DateRangeFilter | undefined => {
  return filters.find((key) => {
    const filter = allFilters[key];
    return isSelectedFilterPreset(
      {
        from: new TZDate(value[0].toISOString(), timezone),
        to: new TZDate(value[1].toISOString(), timezone),
      },
      filter,
      timezone
    );
  });
};

export const checkIfDefaultFilter = (
  value: DateRange,
  defaultFilterKey: DateRangeFilter,
  timezone: string
): boolean => {
  const defaultFilter = allFilters[defaultFilterKey].date(timezone);
  return (
    dayjs.tz(value[0], timezone).isSame(defaultFilter.from) &&
    dayjs.tz(value[1], timezone).isSame(defaultFilter.to)
  );
};

const getDateRangePresetFromQuery = (
  queryParams: URLSearchParams,
  filters: DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys
): DateRange | undefined => {
  const filter = getDateRangeFilterFromQuery(
    queryParams,
    filters,
    queryParamKeys
  );
  const timezone =
    getTimezoneFromQuery(queryParams, queryParamKeys) || dayjs.tz.guess();

  if (!filter) {
    return undefined;
  }

  const { from, to } = allFilters[filter].date(timezone);

  return [dayjs(from), dayjs(to)];
};

export const getTimezoneFromQuery = (
  queryParams: URLSearchParams,
  queryParamKeys: RangePickerQueryParamKeys
): string | undefined => {
  return queryParams.get(queryParamKeys.timezone) || undefined;
};

export const createDefaultDateRange = (
  queryParams: URLSearchParams,
  defaultFilterPreset: DateRangeFilter,
  filters: DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys,
  dateRange?: DateRange
): DateRange => {
  if (dateRange) {
    return dateRange;
  }

  let { from, to } = allFilters[defaultFilterPreset].date();

  const presetFromQueryParams = getDateRangePresetFromQuery(
    queryParams,
    filters,
    queryParamKeys
  );
  if (presetFromQueryParams) {
    from = presetFromQueryParams[0].toISOString();
    to = presetFromQueryParams[1].toISOString();
  } else {
    const dateRangeFromQueryParams = getDateRangeFromQuery(
      queryParams,
      queryParamKeys
    );
    if (dateRangeFromQueryParams) {
      from = dateRangeFromQueryParams[0].toISOString();
      to = dateRangeFromQueryParams[1].toISOString();
    }
  }

  return [dayjs(from), dayjs(to)];
};

export const setDateRangeToQuery = (
  [from, to]: DateRange,
  queryParamKeys: RangePickerQueryParamKeys
) => {
  if (isValidDateRange(from, to)) {
    setQueryParamSilently(queryParamKeys.toDate, to.toISOString());
    setQueryParamSilently(queryParamKeys.fromDate, from.toISOString());
    clearQueryParamSilently([queryParamKeys.dateRangePreset]);
  }
};

export const setTimezoneToQuery = (
  timezone: string,
  queryParamKeys: RangePickerQueryParamKeys
) => {
  setQueryParamSilently(queryParamKeys.timezone, timezone);
};

export const setDateRangeFilterToQuery = (
  key: DateRangeFilter | undefined,
  filters: DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys
) => {
  if (isValidPresetKey(key, filters)) {
    setQueryParamSilently(queryParamKeys.dateRangePreset, key);
    clearQueryParamSilently([queryParamKeys.fromDate, queryParamKeys.toDate]);
  }
};

export const clearQueryParams = (queryParamKeys: RangePickerQueryParamKeys) => {
  clearQueryParamSilently([
    queryParamKeys.dateRangePreset,
    queryParamKeys.fromDate,
    queryParamKeys.toDate,
    queryParamKeys.timezone,
  ]);
};
