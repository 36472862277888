import { useSecurityInsightsAgentQuery } from 'hooks/queries/security-insights';

import { useWasEnabled } from './useWasEnabled';
import { OnboardingPhase2HookProps } from '../types';

export const useWasSecurityEnabled = ({
  clusterId,
  onSuccess,
  onError,
  enabled,
}: OnboardingPhase2HookProps): boolean => {
  const { agent: securityAgent, error } = useSecurityInsightsAgentQuery({
    clusterId,
    refetchInterval: enabled ? 20_000 : false,
    enabled,
  });
  const isSecurityAgentInstalled = Boolean(securityAgent?.installed);

  useWasEnabled({
    currentState: isSecurityAgentInstalled,
    onSuccess: onSuccess,
    onError: onError,
    error,
    enabled,
  });

  return isSecurityAgentInstalled;
};
