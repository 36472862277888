import { ClusterStatus } from '@cast/types';

import { useClusterQuery } from 'hooks/queries/cluster';

import { useWasEnabled } from './useWasEnabled';
import { OnboardingPhase2HookProps } from '../types';

export const useDidClusterBecomeOperable = ({
  clusterId,
  onSuccess,
  onError,
  enabled,
}: OnboardingPhase2HookProps): ClusterStatus | undefined => {
  const { cluster: _cluster, error } = useClusterQuery(
    clusterId,
    enabled ? undefined : false
  );

  const newStatus = _cluster?.status;

  useWasEnabled({
    currentState:
      newStatus &&
      [ClusterStatus.READY, ClusterStatus.WARNING].includes(newStatus),
    onSuccess,
    onError,
    error,
    enabled,
  });

  return newStatus;
};
