import {
  demoClusterRuntimeSecurityAnomalies,
  demoClusterRuntimeSecurityAnomaliesOverview,
  demoClusterRuntimeSecurityAnomalyDetails,
  demoClusterRuntimeSecurityAnomalyEvents,
  demoClusterRuntimeSecurityRuleDetails,
  demoClusterRuntimeSecurityRules,
  demoRuntimeSecurityCustomDenyListEntries,
  demoRuntimeSecurityCustomDenyLists,
  demoRuntimeSecurityEvents,
  demoRuntimeSecurityEventsProcessTree,
} from '@cast/fixtures';

import { RuntimeSecurityApiAdapter } from '../api-client/runtime-security-api-factory';
import {
  isDemoClusterRequest,
  rejectDemoClusterRequest,
  resolveDemoClusterRequest,
} from '../utils';

export const RuntimeSecurityApiProxy = (
  origin: ReturnType<typeof RuntimeSecurityApiAdapter>
): ReturnType<typeof RuntimeSecurityApiAdapter> => {
  return {
    ...origin,
    getAnomalies: async (...args: Parameters<typeof origin.getAnomalies>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRuntimeSecurityAnomalies();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAnomalies(...args);
    },
    getAnomalyDetails: async (
      ...args: Parameters<typeof origin.getAnomalyDetails>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRuntimeSecurityAnomalyDetails(
          args[0].id
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAnomalyDetails(...args);
    },
    getAnomalyEvents: async (
      ...args: Parameters<typeof origin.getAnomalyEvents>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRuntimeSecurityAnomalyEvents(
          args[0].id
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAnomalyEvents(...args);
    },
    getAnomaliesOverview: async (
      ...args: Parameters<typeof origin.getAnomaliesOverview>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRuntimeSecurityAnomaliesOverview();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAnomaliesOverview(...args);
    },
    getRules: async (...args: Parameters<typeof origin.getRules>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRuntimeSecurityRules();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getRules(...args);
    },
    getRuleDetails: async (
      ...args: Parameters<typeof origin.getRuleDetails>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRuntimeSecurityRuleDetails(args[0].id);
        if (!fixture) {
          return rejectDemoClusterRequest();
        }
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getRuleDetails(...args);
    },
    getRuntimeEvents: async (
      ...args: Parameters<typeof origin.getRuntimeEvents>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoRuntimeSecurityEvents();
        return resolveDemoClusterRequest(fixture);
      }
      return origin.getRuntimeEvents(...args);
    },
    getRuntimeEventsProcessTree: async (
      ...args: Parameters<typeof origin.getRuntimeEventsProcessTree>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoRuntimeSecurityEventsProcessTree();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getRuntimeEventsProcessTree(...args);
    },
    getCustomDenyLists: async (
      ...args: Parameters<typeof origin.getCustomDenyLists>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoRuntimeSecurityCustomDenyLists();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getCustomDenyLists(...args);
    },
    getCustomDenyListEntries: async (
      ...args: Parameters<typeof origin.getCustomDenyListEntries>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoRuntimeSecurityCustomDenyListEntries();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getCustomDenyListEntries(...args);
    },
  };
};
