import { LabelChip } from 'components/chips';

type FeatureLabelProps = {
  isPremium?: boolean;
};
const FeatureLabel = ({ isPremium = false }: FeatureLabelProps) => {
  return (
    <LabelChip
      size="extraSmall"
      sx={{
        color: 'white',
        backgroundColor: isPremium ? 'blue.400' : 'green.400',
      }}
    >
      {isPremium ? 'Premium' : 'Free'}
    </LabelChip>
  );
};

export default FeatureLabel;
