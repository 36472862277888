import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { ANALYTICS_ENABLED, IS_TILT } from 'common/constants';
import { promos } from 'common/maps';
import { LogoLoader } from 'components/loaders';
import { useAbility } from 'core/ability';
import { useInitAnalytics } from 'core/analytics';
import { useAuth } from 'core/auth';
import { useInitSupportChat } from 'hooks/useInitSupportChat';
import { useNavigate } from 'hooks/useNavigate';
import { useOrganizations } from 'hooks/useOrganizations';

import { useTrial } from '../../trial/TrialProvider';
import { useConsumeCastAiWebCookie } from '../_hooks/useConsumeCastAiWebCookie';
import { useDetermineDemoMode } from '../_hooks/useDetermineDemoMode';

export const Initializer = ({ children }: PropsWithChildren<unknown>) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { isReady: isOrganizationReady } = useOrganizations();
  const { isAnalyticsEnabled } = useInitAnalytics(isAuthenticated);
  const { isLoading: isLoadingAbilities } = useAbility();
  const { isLoading: isLoadingTrial } = useTrial();

  const {
    isDemoDeterminationProcessDone,
    isSecurityDemoDeterminationProcessDone,
  } = useDetermineDemoMode({
    enabled: !isLoadingAbilities && isAuthenticated && isOrganizationReady,
  });

  const criticalDataState = [
    isAuthenticated,
    isOrganizationReady,
    !isLoadingAbilities,
    isDemoDeterminationProcessDone,
    isSecurityDemoDeterminationProcessDone,
  ];

  if (ANALYTICS_ENABLED) {
    criticalDataState.push(isAnalyticsEnabled);
  }

  if (!IS_TILT) {
    criticalDataState.push(!isLoadingTrial);
  }

  const applicationLoading = criticalDataState.some((isReady) => !isReady);

  useConsumeCastAiWebCookie(applicationLoading);

  useInitSupportChat();

  if (applicationLoading) {
    return (
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="white"
        sx={{ inset: 0 }}
        aria-label="loading-spinner"
      >
        <LogoLoader />
      </Box>
    );
  }

  const promoQuery = new URLSearchParams(window.location.search).get('dest');
  if (promoQuery && Object.keys(promos).includes(promoQuery)) {
    navigate(promos[promoQuery], { replace: true });
  }

  return <>{children}</>;
};
