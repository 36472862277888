import { AxiosInstance, AxiosPromise } from 'axios';

import { ServiceAccountsAPIApiFactory } from '@cast/openapi';
import {
  CreateServiceAccountAccessKeyResponse,
  CreateServiceAccountResponse,
  GetServiceAccountsResponse,
  ServiceAccountResponse,
} from '@cast/types';

import { AXIOS_CONFIGURATION } from './constants';

export const ServiceAccountsApiFactory = (client: AxiosInstance) => {
  const generatedApi = ServiceAccountsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  // prettier-ignore
  return {
    createServiceAccount: (...args: Parameters<typeof generatedApi.serviceAccountsAPICreateServiceAccount>) => generatedApi.serviceAccountsAPICreateServiceAccount(...args) as AxiosPromise<CreateServiceAccountResponse>,
    createServiceAccountAccessKey: (...args: Parameters<typeof generatedApi.serviceAccountsAPICreateServiceAccountKey>) => generatedApi.serviceAccountsAPICreateServiceAccountKey(...args) as AxiosPromise<CreateServiceAccountAccessKeyResponse>,
    getServiceAccounts: (...args: Parameters<typeof generatedApi.serviceAccountsAPIListServiceAccounts>) => generatedApi.serviceAccountsAPIListServiceAccounts(...args) as AxiosPromise<GetServiceAccountsResponse>,
    getServiceAccount: (...args: Parameters<typeof generatedApi.serviceAccountsAPIGetServiceAccount>) => generatedApi.serviceAccountsAPIGetServiceAccount(...args) as AxiosPromise<ServiceAccountResponse>,
    deleteServiceAccount: generatedApi.serviceAccountsAPIDeleteServiceAccount,
    deleteServiceAccountAccessKey: generatedApi.serviceAccountsAPIDeleteServiceAccountKey,
    updateServiceAccoutAccessKey: generatedApi.serviceAccountsAPIUpdateServiceAccountKey,
  };
};
