import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  SecurityInsightsAgentStatus,
  SecurityInsightsAgents,
} from '@cast/types';
import {
  hasNoSecurityAgentsEnabled,
  isDemoCluster,
  isDemoMode,
  removeSecurityDemoMode,
  setSecurityDemoMode,
} from '@cast/utils';

import { useAbility } from 'core/ability';
import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

import { CAN_VIEW_SECURITY } from '../../../constants';

type UseSecurityInsightsAgentQueryArgs = {
  clusterId?: string;
  refetchInterval?: number | false;
  enabled?: boolean;
};

export const useSecurityInsightsAgentQuery = ({
  clusterId,
  refetchInterval = false,
  enabled = true,
}: UseSecurityInsightsAgentQueryArgs) => {
  const queryKey = [QueryKeys.SECURITY_AGENT_STATUS, clusterId];
  const canEdit = useAbility().can('update', 'Clusters');

  const queryClient = useQueryClient();

  const {
    data: agent,
    isLoading,
    error,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const oldData =
        queryClient.getQueryData<SecurityInsightsAgentStatus>(queryKey);

      const { data } = await apiClient.securityInsights.getAgentStatus({
        clusterId: clusterId!,
      });

      queryClient.setQueriesData(
        {
          queryKey: [QueryKeys.SECURITY_AGENT_STATUSES],
        },
        (
          agents: SecurityInsightsAgents | undefined = {}
        ): SecurityInsightsAgents => {
          const updatedData = {
            agentStatuses: {
              ...agents?.agentStatuses,
              [clusterId!]: !!data.installed,
            },
            statuses: {
              ...agents?.statuses,
              [clusterId!]: data,
            },
          };

          if (
            hasNoSecurityAgentsEnabled(updatedData.agentStatuses) &&
            !isDemoMode()
          ) {
            setSecurityDemoMode();
          } else {
            removeSecurityDemoMode();
          }

          return updatedData;
        }
      );

      if (!oldData?.installed) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.SECURITY_CLUSTERS_SETTINGS],
        });
      }

      return data;
    },
    enabled: Boolean(
      enabled &&
        clusterId &&
        !isDemoCluster(clusterId) &&
        canEdit &&
        CAN_VIEW_SECURITY()
    ),
    refetchInterval,
  });
  return { agent, isLoading, error };
};
