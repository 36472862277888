import * as Sentry from '@sentry/react';

import { COOKIE_KEYS } from 'core/cookies/constants';
import { getCookie } from 'utils/cookie';

import { CastAiWebCookie } from './types';

export const getCastAiWebCookie = (): CastAiWebCookie | undefined => {
  const cookieStr = getCookie(COOKIE_KEYS.CAST_AI_WEB_COOKIE);
  if (!cookieStr) {
    return;
  }
  try {
    return JSON.parse(decodeURIComponent(cookieStr));
  } catch (e) {
    console.error(e);
    Sentry.captureMessage(
      `CastAi web cookie was provided, but it was not a valid JSON. Cookie content: ${cookieStr}`
    );
  }
};

export const readCookieParams = (
  params: string
): URLSearchParams | undefined => {
  try {
    return new URLSearchParams(params);
  } catch (e) {
    console.error(e);
    Sentry.captureMessage(
      `CastAi web cookie params were provided, but it was not a valid string. Params content: ${params}`
    );
  }
};
