import { useQuery } from '@tanstack/react-query';

import { ClusterCredentialsResponse } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UseCredentialsScriptQuery = {
  isLoading: boolean;
  isFetching: boolean;
  script?: ClusterCredentialsResponse['script'];
  error: unknown;
  refetch: () => void;
};

type UseCredentialsScriptQueryParams = {
  id?: string;
  installGPUPlugin?: boolean;
  installSecurityAgent?: boolean;
  installAutoscalerAgent?: boolean;
  installGpuMetricsExporter?: boolean;
  installNetflowExporter?: boolean;
  installAiOptimizerProxy?: boolean;
};

export const useCredentialsScriptQuery = ({
  id,
  installGPUPlugin,
  installSecurityAgent,
  installAutoscalerAgent,
  installGpuMetricsExporter,
  installNetflowExporter,
  installAiOptimizerProxy,
}: UseCredentialsScriptQueryParams): UseCredentialsScriptQuery => {
  const {
    isLoading,
    isFetching,
    data: script,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      QueryKeys.EXTERNAL_CLUSTER_CREDENTIALS,
      id,
      installGPUPlugin,
      installSecurityAgent,
      installAutoscalerAgent,
      installGpuMetricsExporter,
      installNetflowExporter,
      installAiOptimizerProxy,
    ],
    queryFn: async () => {
      const { data } = await apiClient.clusters.getCredentialsScript({
        clusterId: id!,
        crossRole: true,
        nvidiaDevicePlugin: installGPUPlugin,
        installSecurityAgent,
        installAutoscalerAgent,
        installGpuMetricsExporter,
        installNetflowExporter,
        installAiOptimizerProxy,
      });

      return data.script;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    isFetching,
    script,
    error,
    refetch: refetch.bind(null, { cancelRefetch: true }),
  };
};
