import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { DataPopperRow, theme } from '@cast/design-system';
import { getCurrencyPrecision } from '@cast/utils';

import { nodeTypeColors } from 'common/maps';
import { Money } from 'components/money';
import { useCostOverTime } from 'features/cost-report/hooks/useCostOverTime';
import {
  CostReportChartType,
  NormalizedCostMode,
} from 'features/cost-report/types/costOverTime';
import { NodeType } from 'types/nodes';

import { CostReportChartTooltip } from '../../../../../cost-report/components/chart/CostReportChartTooltip';
import { useCostOverTimeSection } from '../../../../../cost-report/hooks/useCostOverTimeSection';
import { useCostReportContext } from '../../../../../cost-report/hooks/useCostReportContext';
import { useNormalizedCostsControls } from '../_hooks/useNormalizedCostControls';

type Props = {
  payload?: any[];
  timezone: string;
};

const normalizedCostColors: Record<NormalizedCostMode, string | undefined> = {
  [NormalizedCostMode.NORMALIZED]: theme.palette.blue[700],
  [NormalizedCostMode.ON_DEMAND]: nodeTypeColors[NodeType.ON_DEMAND],
  [NormalizedCostMode.FALLBACK]: nodeTypeColors[NodeType.FALLBACK],
  [NormalizedCostMode.SPOT]: nodeTypeColors[NodeType.SPOT],
};

export const normalizedCostModeMap: Record<NormalizedCostMode, string> = {
  [NormalizedCostMode.NORMALIZED]: 'Normalized',
  [NormalizedCostMode.ON_DEMAND]: 'On-demand',
  [NormalizedCostMode.FALLBACK]: 'Fallback',
  [NormalizedCostMode.SPOT]: 'Spot',
};

type MakeChartTooltipArgs = { unit: string };
export const makeChartTooltip = ({ unit }: MakeChartTooltipArgs) => {
  const ChartTooltip = ({ payload, timezone }: Props) => {
    const { activeTab } = useNormalizedCostsControls();
    const { costRate } = useCostOverTimeSection();
    const tooltipData = payload?.[0]?.payload || {};
    const { timestamp, forecast } = tooltipData;
    const { chartType } = useCostReportContext();
    const { key, projectEndOfDayCost } = useCostOverTime();

    if (isEmpty(tooltipData)) {
      return null;
    }
    if (activeTab === NormalizedCostMode.FALLBACK && forecast) {
      return null;
    }

    const cost = tooltipData[activeTab];
    const projectedCost = projectEndOfDayCost
      ? tooltipData[`${activeTab}Forecast`] || undefined
      : undefined;
    const date = dayjs.utc(timestamp).tz(timezone).format('MMM D');
    const time =
      key === 'cluster' && chartType === CostReportChartType.AREA
        ? dayjs.utc(timestamp).tz(timezone).format('hh:mmA')
        : undefined;
    const hasProjectedCost = projectedCost && cost < projectedCost;

    return (
      <CostReportChartTooltip
        primary={date}
        secondary={time}
        title={`${costRate} cost per ${unit}`}
        forecast={forecast}
        projected={hasProjectedCost}
      >
        <DataPopperRow
          indicator={{
            color: normalizedCostColors[activeTab as NormalizedCostMode] ?? '',
          }}
          left={
            <Typography variant="L10R">
              {normalizedCostModeMap[activeTab as NormalizedCostMode]}
            </Typography>
          }
          right={
            <Stack direction="row" flexWrap="nowrap" gap={8}>
              <Typography variant="inherit" align="right">
                {cost !== null ? (
                  <Money
                    amount={cost}
                    raw={cost < 1}
                    precision={getCurrencyPrecision(cost)}
                  />
                ) : (
                  '--'
                )}
              </Typography>

              {hasProjectedCost && (
                <Typography
                  variant="inherit"
                  align="right"
                  sx={{ opacity: 0.5 }}
                >
                  <Money
                    amount={projectedCost}
                    raw={projectedCost < 1}
                    precision={getCurrencyPrecision(projectedCost)}
                  />
                </Typography>
              )}
            </Stack>
          }
        />
      </CostReportChartTooltip>
    );
  };
  return ChartTooltip;
};
