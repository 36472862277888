import { ReactNode, useState, MouseEvent } from 'react';

import { Stack, SxProps } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { TZDate } from 'react-day-picker';

import { SingleDayInfinitePicker } from './SingleDayInfinitePicker';
import { SingleDayPickerInput } from './SingleDayPickerInput';
import { DatePickerFooter } from '../../components/DatePickerFooter';
import {
  DatePickerInput,
  DatePickerInputProps,
} from '../../components/DatePickerInput/DatePickerInput';
import { DatePickerPopper } from '../../components/DatePickerPopper';
import { defaultDateFormat, defaultDateTimeFormat } from '../../constants';
import DatePickerProvider from '../../DatePickerProvider';
import {
  InfiniteDayPickerRef,
  MarkedDate,
  SingleDayCondition,
} from '../../types';
import { convertDate, dateToInputString, utcNow, utcToDate } from '../../utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export type SingleDayPickerProps = {
  initialDay?: string;
  onChange: (date: string | undefined, timezone: string) => void;
  format?: string;
  endMonth?: SingleDayCondition;
  startMonth?: SingleDayCondition;
  minDate?: SingleDayCondition;
  maxDate?: SingleDayCondition;
  triggerInputProps?: DatePickerInputProps['inputProps'];
  hideDateInput?: boolean;
  allowTimeInput?: boolean;
  hideTimezonePicker?: boolean;
  initialTimezone?: string;
  markedDates?: MarkedDate[];
  hint?:
    | ReactNode
    | ((args: {
        date: string | undefined;
        timezone: string;
        pickerRef: InfiniteDayPickerRef;
      }) => ReactNode);
  inputValue?: (date: string | undefined, timezone: string) => string;
  sx?: SxProps;
};

export const SingleDayPicker = ({
  initialDay,
  onChange,
  allowTimeInput = false,
  format: _format = allowTimeInput ? defaultDateTimeFormat : defaultDateFormat,
  endMonth,
  startMonth,
  triggerInputProps,
  maxDate,
  minDate,
  hideDateInput = false,
  hideTimezonePicker = false,
  initialTimezone = dayjs.tz.guess(),
  markedDates = [],
  hint,
  inputValue,
  sx,
}: SingleDayPickerProps) => {
  const [pickerRef, setPickerRef] = useState<InfiniteDayPickerRef>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [selectedDay, setSelectedDay] = useState<TZDate | undefined>(() =>
    initialDay ? utcToDate(initialDay, initialTimezone) : undefined
  );

  const [initialMonth] = useState<TZDate>(() =>
    utcToDate(initialDay ? initialDay : utcNow(), initialTimezone)
  );
  const [timezone, setTimezone] = useState<string>(initialTimezone);

  const handleOpen = (event: MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClose = () => {
    setTimezone(initialTimezone);
    setSelectedDay(
      initialDay ? utcToDate(initialDay, initialTimezone) : undefined
    );
    handleClose();
  };

  const handleTimezoneChange = (newTimezone: string) => {
    const convertedSelection = selectedDay
      ? convertDate(selectedDay, timezone, newTimezone)
      : undefined;
    setSelectedDay(convertedSelection);
    setTimezone(newTimezone);
  };

  const handleApply = () => {
    onChange(selectedDay?.toUTCString(), timezone);
    handleClose();
  };

  const formattedInputValue = (selected?: TZDate) => {
    if (selected) {
      return dateToInputString(selected, _format, timezone);
    }
    return '';
  };

  const inputValueFormatter = inputValue
    ? inputValue.bind(null, selectedDay?.toUTCString(), timezone)
    : formattedInputValue.bind(null, selectedDay);

  return (
    <DatePickerProvider
      mode="single"
      format={_format}
      inputValue={inputValueFormatter}
      selected={selectedDay}
      setSelected={setSelectedDay}
      popoverAnchor={anchorEl}
      setPopoverAnchor={setAnchorEl}
      handleOpen={handleOpen}
      handleClose={onClose}
      handleApply={handleApply}
      startMonth={startMonth}
      endMonth={endMonth}
      minDate={minDate}
      maxDate={maxDate}
      initialMonth={initialMonth}
      pickerRef={pickerRef}
      setPickerRef={setPickerRef}
      allowTimeInput={allowTimeInput}
      hideTimezonePicker={hideTimezonePicker}
      timezone={timezone}
      setTimezone={handleTimezoneChange}
      markedDates={markedDates}
    >
      <DatePickerInput
        sx={sx}
        inputProps={triggerInputProps}
        popperContent={
          <DatePickerPopper
            footer={
              <DatePickerFooter
                isMini
                hint={
                  typeof hint === 'function'
                    ? hint({
                        date: selectedDay?.toUTCString(),
                        timezone,
                        pickerRef,
                      })
                    : hint
                }
              />
            }
          >
            <Stack pt={16} gap={8} alignItems="center">
              {!hideDateInput && <SingleDayPickerInput />}
              <SingleDayInfinitePicker />
            </Stack>
          </DatePickerPopper>
        }
      />
    </DatePickerProvider>
  );
};
