import clsx from 'clsx';
import round from 'lodash/round';
import NumberFormat from 'react-number-format';

import { Currency } from '@cast/types';
import { formatCurrency } from '@cast/utils';

const currencyToSymbol: Record<Currency, string> = {
  USD: '$',
  EUR: '€',
};

export type MoneyProps = {
  amount?: number;
  currency?: Currency;
  precision?: number;
  fixed?: number;
  raw?: boolean;
  dashesIfUndefined?: boolean;
  className?: string;
  testId?: string;
  suffix?: string;
};

const getValue = (
  amount?: number,
  precision = 2,
  fixed = 2,
  dashesIfUndefined?: boolean,
  raw?: boolean
) => {
  if (!isFinite(amount!)) {
    return dashesIfUndefined ? '--.--' : 0;
  }
  const roundedAmount = round(amount!, precision);
  return raw
    ? roundedAmount
    : formatCurrency(roundedAmount, {
        maximumFractionDigits: fixed,
      });
};

export const Money = ({
  amount,
  currency = 'USD',
  precision,
  fixed = 2,
  raw = false,
  dashesIfUndefined,
  className,
  testId,
  suffix,
}: MoneyProps) => {
  return (
    <NumberFormat
      className={clsx('Money-root', className)}
      thousandSeparator
      displayType="text"
      prefix={currencyToSymbol[currency] ?? currency}
      value={getValue(amount, precision, fixed, dashesIfUndefined, raw)}
      data-testid={testId}
      suffix={suffix}
    />
  );
};
