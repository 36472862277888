import { PropsWithChildren, createContext, useContext } from 'react';

import { useTrialStatusQuery } from 'hooks/queries/trial';

import { usePersistentState } from '../../hooks/usePersistentState';

type TrialContextState = {
  /** Indicates if org is eligible for a trial (does not mean that trial has started) */
  isEligible: boolean;
  /** Indicates if the trial is currently active or extended */
  isActive: boolean;
  /** Start date of the trial */
  startedAt?: string;
  /** End date of the trial */
  endedAt?: string;
  /** Indicates if organization has contacted sales. */
  hasContactedSales: boolean;
  isLoading: boolean;
  hasError: boolean;
  chooseFreePlan: boolean;
  setChooseFreePlan: (value: boolean) => void;
};

export const TrialContext = createContext<TrialContextState>(
  undefined as never
);

export const TrialProvider = ({ children }: PropsWithChildren) => {
  const [chooseFreePlan, setChooseFreePlan] = usePersistentState(
    'PLG-chooseFreePlan',
    false
  );

  const { data, isLoading, error } = useTrialStatusQuery();

  return (
    <TrialContext.Provider
      value={{
        isLoading,
        isEligible: data?.eligibleForTrial ?? false,
        isActive: data?.trialActive ?? false,
        hasContactedSales: data?.salesContacted ?? false,
        startedAt: data?.trialStartDate,
        endedAt: data?.trialEndDate,
        hasError: !!error,
        chooseFreePlan,
        setChooseFreePlan,
      }}
    >
      {children}
    </TrialContext.Provider>
  );
};

export const useTrial = () => {
  return useContext(TrialContext);
};
