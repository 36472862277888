/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * - SECURITY_FEATURE_UNKNOWN: Unknown security feature  - IMAGE_SCANNING: Image scanning is enabled on the cluster  - CONFIGURATION_SCANNING: Configuration scanning is enabled on the cluster  - RUNTIME_SECURITY: Runtime security is enabled on the cluster  - NETFLOW: Netflow collection is enabled on the cluster
 * @export
 * @enum {string}
 */

export const InsightsV1SecurityFeature = {
    SECURITY_FEATURE_UNKNOWN: 'SECURITY_FEATURE_UNKNOWN',
    IMAGE_SCANNING: 'IMAGE_SCANNING',
    CONFIGURATION_SCANNING: 'CONFIGURATION_SCANNING',
    RUNTIME_SECURITY: 'RUNTIME_SECURITY',
    NETFLOW: 'NETFLOW'
} as const;

export type InsightsV1SecurityFeature = typeof InsightsV1SecurityFeature[keyof typeof InsightsV1SecurityFeature];



