import { ReactNode } from 'react';

import { Stack, Box, Typography, useTheme } from '@mui/material';

import { Icons } from '@cast/design-system';

type AgentSetupCompleteBlockProps = {
  heading: string;
  body?: ReactNode;
  icon?: ReactNode;
  testId?: string;
  checked?: boolean;
  isNestedBlock?: boolean;
};

export const AgentSetupCompleteBlock = ({
  heading,
  body,
  icon,
  testId,
  checked = false,
  isNestedBlock = false,
}: AgentSetupCompleteBlockProps) => {
  const { palette } = useTheme();

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={12}
        py={isNestedBlock ? 8 : 12}
        borderTop={!isNestedBlock ? 'grey.100' : undefined}
        data-testid={testId}
        data-enabled={checked}
      >
        <Stack direction="row" gap={10}>
          {checked ? (
            <Icons.CheckCircle
              size={20}
              weight="fill"
              color={palette.green[500]}
            />
          ) : (
            <Icons.XCircle size={20} weight="fill" color={palette.grey[400]} />
          )}
          <Stack gap={2}>
            <Stack direction="row" alignItems="center" gap={8}>
              <Typography
                variant={isNestedBlock ? 'P12B' : 'P14B'}
                color="grey.900"
              >
                {heading}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box
          alignSelf="flex-start"
          sx={{
            svg: {
              width: 40,
              height: 40,
            },
          }}
        >
          {icon}
        </Box>
      </Stack>
      {body}
    </Stack>
  );
};
