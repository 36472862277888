/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { DboV1Account } from '../models';
// @ts-ignore
import type { DboV1CacheConfiguration } from '../models';
// @ts-ignore
import type { DboV1CacheGroup } from '../models';
// @ts-ignore
import type { DboV1CreateDatabaseInstanceRequestNewInstance } from '../models';
// @ts-ignore
import type { DboV1DatabaseCluster } from '../models';
// @ts-ignore
import type { DboV1DatabaseInstance } from '../models';
// @ts-ignore
import type { DboV1ExchangeCacheStateResponse } from '../models';
// @ts-ignore
import type { DboV1GetCacheQueriesResponse } from '../models';
// @ts-ignore
import type { DboV1GetCacheSummaryResponse } from '../models';
// @ts-ignore
import type { DboV1ListCacheConfigurationsResponse } from '../models';
// @ts-ignore
import type { DboV1ListCacheGroupsResponse } from '../models';
// @ts-ignore
import type { DboV1ListCacheTTLsResponse } from '../models';
// @ts-ignore
import type { DboV1ListDatabaseComponentsResponse } from '../models';
// @ts-ignore
import type { DboV1ListDatabaseInstancesResponse } from '../models';
// @ts-ignore
import type { DboV1LogicalDatabase } from '../models';
// @ts-ignore
import type { DboV1OperationalMetrics } from '../models';
// @ts-ignore
import type { DboV1ProxyState } from '../models';
// @ts-ignore
import type { DboV1Registration } from '../models';
// @ts-ignore
import type { DboV1RegistrationStatusUpdate } from '../models';
// @ts-ignore
import type { DboV1TTLConfiguration } from '../models';
// @ts-ignore
import type { DboV1UpdateDatabaseInstanceRequestUpdateInstance } from '../models';
// @ts-ignore
import type { GoogleApiHttpBody } from '../models';
/**
 * DboAPIApi - axios parameter creator
 * @export
 */
export const DboAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new cache configuration in a given cache group.
         * @summary Create cache configuration
         * @param {string} groupId 
         * @param {DboV1CacheConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateCacheConfiguration: async (groupId: string, config: DboV1CacheConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPICreateCacheConfiguration', 'groupId', groupId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('dboAPICreateCacheConfiguration', 'config', config)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new cache group.
         * @summary Create cache group.
         * @param {DboV1CacheGroup} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateCacheGroup: async (group: DboV1CacheGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group' is not null or undefined
            assertParamExists('dboAPICreateCacheGroup', 'group', group)
            const localVarPath = `/v1/dbo/cache-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new cache TTL configuration.
         * @summary Create cache TTL configuration
         * @param {string} groupId 
         * @param {string} id 
         * @param {DboV1TTLConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateCacheTTL: async (groupId: string, id: string, config: DboV1TTLConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPICreateCacheTTL', 'groupId', groupId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPICreateCacheTTL', 'id', id)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('dboAPICreateCacheTTL', 'config', config)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{id}/rules`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new database instances.
         * @summary Create database instance
         * @param {DboV1CreateDatabaseInstanceRequestNewInstance} instance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateDatabaseInstance: async (instance: DboV1CreateDatabaseInstanceRequestNewInstance, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instance' is not null or undefined
            assertParamExists('dboAPICreateDatabaseInstance', 'instance', instance)
            const localVarPath = `/v1/dbo/db-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instance, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new logical database.
         * @summary Create logical database.
         * @param {string} instanceId 
         * @param {DboV1LogicalDatabase} db 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateLogicalDatabase: async (instanceId: string, db: DboV1LogicalDatabase, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('dboAPICreateLogicalDatabase', 'instanceId', instanceId)
            // verify required parameter 'db' is not null or undefined
            assertParamExists('dboAPICreateLogicalDatabase', 'db', db)
            const localVarPath = `/v1/dbo/db-instances/{instanceId}/logical-db`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(db, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new registration.
         * @param {DboV1Registration} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateRegistration: async (registration: DboV1Registration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registration' is not null or undefined
            assertParamExists('dboAPICreateRegistration', 'registration', registration)
            const localVarPath = `/v1/dbo/registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create registration status update.
         * @param {string} registrationId 
         * @param {DboV1RegistrationStatusUpdate} statusUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateRegistrationStatusUpdate: async (registrationId: string, statusUpdate: DboV1RegistrationStatusUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('dboAPICreateRegistrationStatusUpdate', 'registrationId', registrationId)
            // verify required parameter 'statusUpdate' is not null or undefined
            assertParamExists('dboAPICreateRegistrationStatusUpdate', 'statusUpdate', statusUpdate)
            const localVarPath = `/v1/dbo/registrations/{registrationId}/status`
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove the onboarded cloud account.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIDeleteAccount', 'id', id)
            const localVarPath = `/v1/dbo/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes cache configuration.
         * @summary Deletes cache configuration
         * @param {string} groupId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteCacheConfiguration: async (groupId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIDeleteCacheConfiguration', 'groupId', groupId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIDeleteCacheConfiguration', 'id', id)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{id}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a given cache group.
         * @summary Deletes cache group.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteCacheGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIDeleteCacheGroup', 'id', id)
            const localVarPath = `/v1/dbo/cache-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes TTL cache configuration.
         * @summary Deletes TTL cache configuration
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteCacheTTL: async (groupId: string, cacheId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIDeleteCacheTTL', 'groupId', groupId)
            // verify required parameter 'cacheId' is not null or undefined
            assertParamExists('dboAPIDeleteCacheTTL', 'cacheId', cacheId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIDeleteCacheTTL', 'id', id)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{cacheId}/rules/{id}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"cacheId"}}`, encodeURIComponent(String(cacheId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes database instance.
         * @summary Delete database instance
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteDatabaseInstance: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIDeleteDatabaseInstance', 'id', id)
            const localVarPath = `/v1/dbo/db-instances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete logical database.
         * @summary Delete logical database.
         * @param {string} instanceId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteLogicalDatabase: async (instanceId: string, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('dboAPIDeleteLogicalDatabase', 'instanceId', instanceId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('dboAPIDeleteLogicalDatabase', 'name', name)
            const localVarPath = `/v1/dbo/db-instances/{instanceId}/logical-db/{name}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State/config exchange between a proxy instance and CAST AI control plane.
         * @summary State exchange
         * @param {string} cacheGroupId 
         * @param {DboV1ProxyState} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIExchangeCacheState: async (cacheGroupId: string, state: DboV1ProxyState, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cacheGroupId' is not null or undefined
            assertParamExists('dboAPIExchangeCacheState', 'cacheGroupId', cacheGroupId)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('dboAPIExchangeCacheState', 'state', state)
            const localVarPath = `/v1/dbo/cache-state-exchange/{cacheGroupId}`
                .replace(`{${"cacheGroupId"}}`, encodeURIComponent(String(cacheGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(state, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves cache queries for the current organization.
         * @summary Get cache queries
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {DboAPIGetCacheQueriesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetCacheQueries: async (groupId: string, cacheId: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: DboAPIGetCacheQueriesSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIGetCacheQueries', 'groupId', groupId)
            // verify required parameter 'cacheId' is not null or undefined
            assertParamExists('dboAPIGetCacheQueries', 'cacheId', cacheId)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{cacheId}/queries`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"cacheId"}}`, encodeURIComponent(String(cacheId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves cache summary for the current organization.
         * @summary Get cache summary
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetCacheSummary: async (groupId: string, cacheId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIGetCacheSummary', 'groupId', groupId)
            // verify required parameter 'cacheId' is not null or undefined
            assertParamExists('dboAPIGetCacheSummary', 'cacheId', cacheId)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{cacheId}/summary`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"cacheId"}}`, encodeURIComponent(String(cacheId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves database cluster details.
         * @summary Get database cluster details
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetDatabaseCluster: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('dboAPIGetDatabaseCluster', 'clusterId', clusterId)
            const localVarPath = `/v1/dbo/db-clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves database instances details.
         * @summary Get database instance details
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetDatabaseInstance: async (instanceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('dboAPIGetDatabaseInstance', 'instanceId', instanceId)
            const localVarPath = `/v1/dbo/db-instances/{instanceId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns shell script to execute a registration
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetRegistrationScript: async (registrationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('dboAPIGetRegistrationScript', 'registrationId', registrationId)
            const localVarPath = `/v1/dbo/registrations/{registrationId}/script`
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get registration status.
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetRegistrationStatus: async (registrationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('dboAPIGetRegistrationStatus', 'registrationId', registrationId)
            const localVarPath = `/v1/dbo/registrations/{registrationId}/status`
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ingests operational metrics from proxy and query-processor.
         * @summary Ingest Operational Processor metrics
         * @param {DboV1OperationalMetrics} metrics 
         * @param {string} [instanceId] 
         * @param {string} [component] 
         * @param {string} [cacheGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIIngestOperationalMetrics: async (metrics: DboV1OperationalMetrics, instanceId?: string, component?: string, cacheGroupId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metrics' is not null or undefined
            assertParamExists('dboAPIIngestOperationalMetrics', 'metrics', metrics)
            const localVarPath = `/v1/dbo/query-processor/operational-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (instanceId !== undefined) {
                localVarQueryParameter['instanceId'] = instanceId;
            }

            if (component !== undefined) {
                localVarQueryParameter['component'] = component;
            }

            if (cacheGroupId !== undefined) {
                localVarQueryParameter['cacheGroupId'] = cacheGroupId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metrics, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List onboarded cloud accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dbo/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists cache configurations for a given cache group.
         * @summary List cache configurations
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListCacheConfigurations: async (groupId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIListCacheConfigurations', 'groupId', groupId)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists cache groups in a given organization.
         * @summary List cache groups.
         * @param {string} [instanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListCacheGroups: async (instanceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dbo/cache-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (instanceId !== undefined) {
                localVarQueryParameter['instanceId'] = instanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists cache TTL configurations for a given cache configuration.
         * @summary List cache TTL configurations
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} [table] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListCacheTTLs: async (groupId: string, cacheId: string, table?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIListCacheTTLs', 'groupId', groupId)
            // verify required parameter 'cacheId' is not null or undefined
            assertParamExists('dboAPIListCacheTTLs', 'cacheId', cacheId)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{cacheId}/rules`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"cacheId"}}`, encodeURIComponent(String(cacheId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (table !== undefined) {
                localVarQueryParameter['table'] = table;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists database components for the current organization.
         * @summary List database components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListDatabaseComponents: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dbo/db-components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists database instances for the current organization.
         * @summary List database instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListDatabaseInstances: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dbo/db-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates cache configuration.
         * @summary Updates cache configuration
         * @param {string} groupId 
         * @param {string} id 
         * @param {DboV1CacheConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateCacheConfiguration: async (groupId: string, id: string, config: DboV1CacheConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIUpdateCacheConfiguration', 'groupId', groupId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIUpdateCacheConfiguration', 'id', id)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('dboAPIUpdateCacheConfiguration', 'config', config)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{id}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates cache group.
         * @summary Update cache group.
         * @param {string} id 
         * @param {DboV1CacheGroup} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateCacheGroup: async (id: string, group: DboV1CacheGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIUpdateCacheGroup', 'id', id)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('dboAPIUpdateCacheGroup', 'group', group)
            const localVarPath = `/v1/dbo/cache-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates TTL cache configuration.
         * @summary Updates TTL cache configuration
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} id 
         * @param {DboV1TTLConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateCacheTTL: async (groupId: string, cacheId: string, id: string, config: DboV1TTLConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('dboAPIUpdateCacheTTL', 'groupId', groupId)
            // verify required parameter 'cacheId' is not null or undefined
            assertParamExists('dboAPIUpdateCacheTTL', 'cacheId', cacheId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIUpdateCacheTTL', 'id', id)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('dboAPIUpdateCacheTTL', 'config', config)
            const localVarPath = `/v1/dbo/cache-groups/{groupId}/cache-configurations/{cacheId}/rules/{id}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"cacheId"}}`, encodeURIComponent(String(cacheId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates database instance.
         * @summary Update database instance
         * @param {string} id 
         * @param {DboV1UpdateDatabaseInstanceRequestUpdateInstance} instance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateDatabaseInstance: async (id: string, instance: DboV1UpdateDatabaseInstanceRequestUpdateInstance, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dboAPIUpdateDatabaseInstance', 'id', id)
            // verify required parameter 'instance' is not null or undefined
            assertParamExists('dboAPIUpdateDatabaseInstance', 'instance', instance)
            const localVarPath = `/v1/dbo/db-instances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instance, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DboAPIApi - functional programming interface
 * @export
 */
export const DboAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DboAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new cache configuration in a given cache group.
         * @summary Create cache configuration
         * @param {string} groupId 
         * @param {DboV1CacheConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPICreateCacheConfiguration(groupId: string, config: DboV1CacheConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1CacheConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPICreateCacheConfiguration(groupId, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPICreateCacheConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates new cache group.
         * @summary Create cache group.
         * @param {DboV1CacheGroup} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPICreateCacheGroup(group: DboV1CacheGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1CacheGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPICreateCacheGroup(group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPICreateCacheGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates new cache TTL configuration.
         * @summary Create cache TTL configuration
         * @param {string} groupId 
         * @param {string} id 
         * @param {DboV1TTLConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPICreateCacheTTL(groupId: string, id: string, config: DboV1TTLConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1TTLConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPICreateCacheTTL(groupId, id, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPICreateCacheTTL']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates new database instances.
         * @summary Create database instance
         * @param {DboV1CreateDatabaseInstanceRequestNewInstance} instance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPICreateDatabaseInstance(instance: DboV1CreateDatabaseInstanceRequestNewInstance, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1DatabaseInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPICreateDatabaseInstance(instance, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPICreateDatabaseInstance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates new logical database.
         * @summary Create logical database.
         * @param {string} instanceId 
         * @param {DboV1LogicalDatabase} db 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPICreateLogicalDatabase(instanceId: string, db: DboV1LogicalDatabase, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1LogicalDatabase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPICreateLogicalDatabase(instanceId, db, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPICreateLogicalDatabase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create new registration.
         * @param {DboV1Registration} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPICreateRegistration(registration: DboV1Registration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPICreateRegistration(registration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPICreateRegistration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create registration status update.
         * @param {string} registrationId 
         * @param {DboV1RegistrationStatusUpdate} statusUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPICreateRegistrationStatusUpdate(registrationId: string, statusUpdate: DboV1RegistrationStatusUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPICreateRegistrationStatusUpdate(registrationId, statusUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPICreateRegistrationStatusUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove the onboarded cloud account.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIDeleteAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIDeleteAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIDeleteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes cache configuration.
         * @summary Deletes cache configuration
         * @param {string} groupId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIDeleteCacheConfiguration(groupId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIDeleteCacheConfiguration(groupId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIDeleteCacheConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a given cache group.
         * @summary Deletes cache group.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIDeleteCacheGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIDeleteCacheGroup(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIDeleteCacheGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes TTL cache configuration.
         * @summary Deletes TTL cache configuration
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIDeleteCacheTTL(groupId: string, cacheId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIDeleteCacheTTL(groupId, cacheId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIDeleteCacheTTL']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes database instance.
         * @summary Delete database instance
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIDeleteDatabaseInstance(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIDeleteDatabaseInstance(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIDeleteDatabaseInstance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete logical database.
         * @summary Delete logical database.
         * @param {string} instanceId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIDeleteLogicalDatabase(instanceId: string, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIDeleteLogicalDatabase(instanceId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIDeleteLogicalDatabase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * State/config exchange between a proxy instance and CAST AI control plane.
         * @summary State exchange
         * @param {string} cacheGroupId 
         * @param {DboV1ProxyState} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIExchangeCacheState(cacheGroupId: string, state: DboV1ProxyState, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1ExchangeCacheStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIExchangeCacheState(cacheGroupId, state, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIExchangeCacheState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves cache queries for the current organization.
         * @summary Get cache queries
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {DboAPIGetCacheQueriesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIGetCacheQueries(groupId: string, cacheId: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: DboAPIGetCacheQueriesSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1GetCacheQueriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIGetCacheQueries(groupId, cacheId, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIGetCacheQueries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves cache summary for the current organization.
         * @summary Get cache summary
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIGetCacheSummary(groupId: string, cacheId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1GetCacheSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIGetCacheSummary(groupId, cacheId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIGetCacheSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves database cluster details.
         * @summary Get database cluster details
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIGetDatabaseCluster(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1DatabaseCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIGetDatabaseCluster(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIGetDatabaseCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves database instances details.
         * @summary Get database instance details
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIGetDatabaseInstance(instanceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1DatabaseInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIGetDatabaseInstance(instanceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIGetDatabaseInstance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns shell script to execute a registration
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIGetRegistrationScript(registrationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleApiHttpBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIGetRegistrationScript(registrationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIGetRegistrationScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get registration status.
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIGetRegistrationStatus(registrationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1RegistrationStatusUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIGetRegistrationStatus(registrationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIGetRegistrationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ingests operational metrics from proxy and query-processor.
         * @summary Ingest Operational Processor metrics
         * @param {DboV1OperationalMetrics} metrics 
         * @param {string} [instanceId] 
         * @param {string} [component] 
         * @param {string} [cacheGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIIngestOperationalMetrics(metrics: DboV1OperationalMetrics, instanceId?: string, component?: string, cacheGroupId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIIngestOperationalMetrics(metrics, instanceId, component, cacheGroupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIIngestOperationalMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List onboarded cloud accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIListAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DboV1Account>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIListAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIListAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists cache configurations for a given cache group.
         * @summary List cache configurations
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIListCacheConfigurations(groupId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1ListCacheConfigurationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIListCacheConfigurations(groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIListCacheConfigurations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists cache groups in a given organization.
         * @summary List cache groups.
         * @param {string} [instanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIListCacheGroups(instanceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1ListCacheGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIListCacheGroups(instanceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIListCacheGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists cache TTL configurations for a given cache configuration.
         * @summary List cache TTL configurations
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} [table] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIListCacheTTLs(groupId: string, cacheId: string, table?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1ListCacheTTLsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIListCacheTTLs(groupId, cacheId, table, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIListCacheTTLs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists database components for the current organization.
         * @summary List database components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIListDatabaseComponents(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1ListDatabaseComponentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIListDatabaseComponents(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIListDatabaseComponents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists database instances for the current organization.
         * @summary List database instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIListDatabaseInstances(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1ListDatabaseInstancesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIListDatabaseInstances(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIListDatabaseInstances']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates cache configuration.
         * @summary Updates cache configuration
         * @param {string} groupId 
         * @param {string} id 
         * @param {DboV1CacheConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIUpdateCacheConfiguration(groupId: string, id: string, config: DboV1CacheConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1CacheConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIUpdateCacheConfiguration(groupId, id, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIUpdateCacheConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates cache group.
         * @summary Update cache group.
         * @param {string} id 
         * @param {DboV1CacheGroup} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIUpdateCacheGroup(id: string, group: DboV1CacheGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1CacheGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIUpdateCacheGroup(id, group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIUpdateCacheGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates TTL cache configuration.
         * @summary Updates TTL cache configuration
         * @param {string} groupId 
         * @param {string} cacheId 
         * @param {string} id 
         * @param {DboV1TTLConfiguration} config 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIUpdateCacheTTL(groupId: string, cacheId: string, id: string, config: DboV1TTLConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1TTLConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIUpdateCacheTTL(groupId, cacheId, id, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIUpdateCacheTTL']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates database instance.
         * @summary Update database instance
         * @param {string} id 
         * @param {DboV1UpdateDatabaseInstanceRequestUpdateInstance} instance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dboAPIUpdateDatabaseInstance(id: string, instance: DboV1UpdateDatabaseInstanceRequestUpdateInstance, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DboV1DatabaseInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dboAPIUpdateDatabaseInstance(id, instance, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DboAPIApi.dboAPIUpdateDatabaseInstance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DboAPIApi - factory interface
 * @export
 */
export const DboAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DboAPIApiFp(configuration)
    return {
        /**
         * Creates new cache configuration in a given cache group.
         * @summary Create cache configuration
         * @param {DboAPIApiDboAPICreateCacheConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateCacheConfiguration(requestParameters: DboAPIApiDboAPICreateCacheConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1CacheConfiguration> {
            return localVarFp.dboAPICreateCacheConfiguration(requestParameters.groupId, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new cache group.
         * @summary Create cache group.
         * @param {DboAPIApiDboAPICreateCacheGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateCacheGroup(requestParameters: DboAPIApiDboAPICreateCacheGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1CacheGroup> {
            return localVarFp.dboAPICreateCacheGroup(requestParameters.group, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new cache TTL configuration.
         * @summary Create cache TTL configuration
         * @param {DboAPIApiDboAPICreateCacheTTLRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateCacheTTL(requestParameters: DboAPIApiDboAPICreateCacheTTLRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1TTLConfiguration> {
            return localVarFp.dboAPICreateCacheTTL(requestParameters.groupId, requestParameters.id, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new database instances.
         * @summary Create database instance
         * @param {DboAPIApiDboAPICreateDatabaseInstanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateDatabaseInstance(requestParameters: DboAPIApiDboAPICreateDatabaseInstanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1DatabaseInstance> {
            return localVarFp.dboAPICreateDatabaseInstance(requestParameters.instance, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new logical database.
         * @summary Create logical database.
         * @param {DboAPIApiDboAPICreateLogicalDatabaseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateLogicalDatabase(requestParameters: DboAPIApiDboAPICreateLogicalDatabaseRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1LogicalDatabase> {
            return localVarFp.dboAPICreateLogicalDatabase(requestParameters.instanceId, requestParameters.db, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new registration.
         * @param {DboAPIApiDboAPICreateRegistrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateRegistration(requestParameters: DboAPIApiDboAPICreateRegistrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1Registration> {
            return localVarFp.dboAPICreateRegistration(requestParameters.registration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create registration status update.
         * @param {DboAPIApiDboAPICreateRegistrationStatusUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPICreateRegistrationStatusUpdate(requestParameters: DboAPIApiDboAPICreateRegistrationStatusUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPICreateRegistrationStatusUpdate(requestParameters.registrationId, requestParameters.statusUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove the onboarded cloud account.
         * @param {DboAPIApiDboAPIDeleteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteAccount(requestParameters: DboAPIApiDboAPIDeleteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPIDeleteAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes cache configuration.
         * @summary Deletes cache configuration
         * @param {DboAPIApiDboAPIDeleteCacheConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteCacheConfiguration(requestParameters: DboAPIApiDboAPIDeleteCacheConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPIDeleteCacheConfiguration(requestParameters.groupId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a given cache group.
         * @summary Deletes cache group.
         * @param {DboAPIApiDboAPIDeleteCacheGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteCacheGroup(requestParameters: DboAPIApiDboAPIDeleteCacheGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPIDeleteCacheGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes TTL cache configuration.
         * @summary Deletes TTL cache configuration
         * @param {DboAPIApiDboAPIDeleteCacheTTLRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteCacheTTL(requestParameters: DboAPIApiDboAPIDeleteCacheTTLRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPIDeleteCacheTTL(requestParameters.groupId, requestParameters.cacheId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes database instance.
         * @summary Delete database instance
         * @param {DboAPIApiDboAPIDeleteDatabaseInstanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteDatabaseInstance(requestParameters: DboAPIApiDboAPIDeleteDatabaseInstanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPIDeleteDatabaseInstance(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete logical database.
         * @summary Delete logical database.
         * @param {DboAPIApiDboAPIDeleteLogicalDatabaseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIDeleteLogicalDatabase(requestParameters: DboAPIApiDboAPIDeleteLogicalDatabaseRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPIDeleteLogicalDatabase(requestParameters.instanceId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * State/config exchange between a proxy instance and CAST AI control plane.
         * @summary State exchange
         * @param {DboAPIApiDboAPIExchangeCacheStateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIExchangeCacheState(requestParameters: DboAPIApiDboAPIExchangeCacheStateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1ExchangeCacheStateResponse> {
            return localVarFp.dboAPIExchangeCacheState(requestParameters.cacheGroupId, requestParameters.state, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves cache queries for the current organization.
         * @summary Get cache queries
         * @param {DboAPIApiDboAPIGetCacheQueriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetCacheQueries(requestParameters: DboAPIApiDboAPIGetCacheQueriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1GetCacheQueriesResponse> {
            return localVarFp.dboAPIGetCacheQueries(requestParameters.groupId, requestParameters.cacheId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves cache summary for the current organization.
         * @summary Get cache summary
         * @param {DboAPIApiDboAPIGetCacheSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetCacheSummary(requestParameters: DboAPIApiDboAPIGetCacheSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1GetCacheSummaryResponse> {
            return localVarFp.dboAPIGetCacheSummary(requestParameters.groupId, requestParameters.cacheId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves database cluster details.
         * @summary Get database cluster details
         * @param {DboAPIApiDboAPIGetDatabaseClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetDatabaseCluster(requestParameters: DboAPIApiDboAPIGetDatabaseClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1DatabaseCluster> {
            return localVarFp.dboAPIGetDatabaseCluster(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves database instances details.
         * @summary Get database instance details
         * @param {DboAPIApiDboAPIGetDatabaseInstanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetDatabaseInstance(requestParameters: DboAPIApiDboAPIGetDatabaseInstanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1DatabaseInstance> {
            return localVarFp.dboAPIGetDatabaseInstance(requestParameters.instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns shell script to execute a registration
         * @param {DboAPIApiDboAPIGetRegistrationScriptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetRegistrationScript(requestParameters: DboAPIApiDboAPIGetRegistrationScriptRequest, options?: RawAxiosRequestConfig): AxiosPromise<GoogleApiHttpBody> {
            return localVarFp.dboAPIGetRegistrationScript(requestParameters.registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get registration status.
         * @param {DboAPIApiDboAPIGetRegistrationStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIGetRegistrationStatus(requestParameters: DboAPIApiDboAPIGetRegistrationStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1RegistrationStatusUpdate> {
            return localVarFp.dboAPIGetRegistrationStatus(requestParameters.registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Ingests operational metrics from proxy and query-processor.
         * @summary Ingest Operational Processor metrics
         * @param {DboAPIApiDboAPIIngestOperationalMetricsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIIngestOperationalMetrics(requestParameters: DboAPIApiDboAPIIngestOperationalMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.dboAPIIngestOperationalMetrics(requestParameters.metrics, requestParameters.instanceId, requestParameters.component, requestParameters.cacheGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List onboarded cloud accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListAccounts(options?: RawAxiosRequestConfig): AxiosPromise<Array<DboV1Account>> {
            return localVarFp.dboAPIListAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * Lists cache configurations for a given cache group.
         * @summary List cache configurations
         * @param {DboAPIApiDboAPIListCacheConfigurationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListCacheConfigurations(requestParameters: DboAPIApiDboAPIListCacheConfigurationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1ListCacheConfigurationsResponse> {
            return localVarFp.dboAPIListCacheConfigurations(requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists cache groups in a given organization.
         * @summary List cache groups.
         * @param {DboAPIApiDboAPIListCacheGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListCacheGroups(requestParameters: DboAPIApiDboAPIListCacheGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DboV1ListCacheGroupsResponse> {
            return localVarFp.dboAPIListCacheGroups(requestParameters.instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists cache TTL configurations for a given cache configuration.
         * @summary List cache TTL configurations
         * @param {DboAPIApiDboAPIListCacheTTLsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListCacheTTLs(requestParameters: DboAPIApiDboAPIListCacheTTLsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1ListCacheTTLsResponse> {
            return localVarFp.dboAPIListCacheTTLs(requestParameters.groupId, requestParameters.cacheId, requestParameters.table, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists database components for the current organization.
         * @summary List database components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListDatabaseComponents(options?: RawAxiosRequestConfig): AxiosPromise<DboV1ListDatabaseComponentsResponse> {
            return localVarFp.dboAPIListDatabaseComponents(options).then((request) => request(axios, basePath));
        },
        /**
         * Lists database instances for the current organization.
         * @summary List database instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIListDatabaseInstances(options?: RawAxiosRequestConfig): AxiosPromise<DboV1ListDatabaseInstancesResponse> {
            return localVarFp.dboAPIListDatabaseInstances(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates cache configuration.
         * @summary Updates cache configuration
         * @param {DboAPIApiDboAPIUpdateCacheConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateCacheConfiguration(requestParameters: DboAPIApiDboAPIUpdateCacheConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1CacheConfiguration> {
            return localVarFp.dboAPIUpdateCacheConfiguration(requestParameters.groupId, requestParameters.id, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates cache group.
         * @summary Update cache group.
         * @param {DboAPIApiDboAPIUpdateCacheGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateCacheGroup(requestParameters: DboAPIApiDboAPIUpdateCacheGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1CacheGroup> {
            return localVarFp.dboAPIUpdateCacheGroup(requestParameters.id, requestParameters.group, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates TTL cache configuration.
         * @summary Updates TTL cache configuration
         * @param {DboAPIApiDboAPIUpdateCacheTTLRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateCacheTTL(requestParameters: DboAPIApiDboAPIUpdateCacheTTLRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1TTLConfiguration> {
            return localVarFp.dboAPIUpdateCacheTTL(requestParameters.groupId, requestParameters.cacheId, requestParameters.id, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates database instance.
         * @summary Update database instance
         * @param {DboAPIApiDboAPIUpdateDatabaseInstanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dboAPIUpdateDatabaseInstance(requestParameters: DboAPIApiDboAPIUpdateDatabaseInstanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<DboV1DatabaseInstance> {
            return localVarFp.dboAPIUpdateDatabaseInstance(requestParameters.id, requestParameters.instance, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dboAPICreateCacheConfiguration operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPICreateCacheConfigurationRequest
 */
export interface DboAPIApiDboAPICreateCacheConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPICreateCacheConfiguration
     */
    readonly groupId: string

    /**
     * 
     * @type {DboV1CacheConfiguration}
     * @memberof DboAPIApiDboAPICreateCacheConfiguration
     */
    readonly config: DboV1CacheConfiguration
}

/**
 * Request parameters for dboAPICreateCacheGroup operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPICreateCacheGroupRequest
 */
export interface DboAPIApiDboAPICreateCacheGroupRequest {
    /**
     * 
     * @type {DboV1CacheGroup}
     * @memberof DboAPIApiDboAPICreateCacheGroup
     */
    readonly group: DboV1CacheGroup
}

/**
 * Request parameters for dboAPICreateCacheTTL operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPICreateCacheTTLRequest
 */
export interface DboAPIApiDboAPICreateCacheTTLRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPICreateCacheTTL
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPICreateCacheTTL
     */
    readonly id: string

    /**
     * 
     * @type {DboV1TTLConfiguration}
     * @memberof DboAPIApiDboAPICreateCacheTTL
     */
    readonly config: DboV1TTLConfiguration
}

/**
 * Request parameters for dboAPICreateDatabaseInstance operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPICreateDatabaseInstanceRequest
 */
export interface DboAPIApiDboAPICreateDatabaseInstanceRequest {
    /**
     * 
     * @type {DboV1CreateDatabaseInstanceRequestNewInstance}
     * @memberof DboAPIApiDboAPICreateDatabaseInstance
     */
    readonly instance: DboV1CreateDatabaseInstanceRequestNewInstance
}

/**
 * Request parameters for dboAPICreateLogicalDatabase operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPICreateLogicalDatabaseRequest
 */
export interface DboAPIApiDboAPICreateLogicalDatabaseRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPICreateLogicalDatabase
     */
    readonly instanceId: string

    /**
     * 
     * @type {DboV1LogicalDatabase}
     * @memberof DboAPIApiDboAPICreateLogicalDatabase
     */
    readonly db: DboV1LogicalDatabase
}

/**
 * Request parameters for dboAPICreateRegistration operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPICreateRegistrationRequest
 */
export interface DboAPIApiDboAPICreateRegistrationRequest {
    /**
     * 
     * @type {DboV1Registration}
     * @memberof DboAPIApiDboAPICreateRegistration
     */
    readonly registration: DboV1Registration
}

/**
 * Request parameters for dboAPICreateRegistrationStatusUpdate operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPICreateRegistrationStatusUpdateRequest
 */
export interface DboAPIApiDboAPICreateRegistrationStatusUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPICreateRegistrationStatusUpdate
     */
    readonly registrationId: string

    /**
     * 
     * @type {DboV1RegistrationStatusUpdate}
     * @memberof DboAPIApiDboAPICreateRegistrationStatusUpdate
     */
    readonly statusUpdate: DboV1RegistrationStatusUpdate
}

/**
 * Request parameters for dboAPIDeleteAccount operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIDeleteAccountRequest
 */
export interface DboAPIApiDboAPIDeleteAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteAccount
     */
    readonly id: string
}

/**
 * Request parameters for dboAPIDeleteCacheConfiguration operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIDeleteCacheConfigurationRequest
 */
export interface DboAPIApiDboAPIDeleteCacheConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteCacheConfiguration
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteCacheConfiguration
     */
    readonly id: string
}

/**
 * Request parameters for dboAPIDeleteCacheGroup operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIDeleteCacheGroupRequest
 */
export interface DboAPIApiDboAPIDeleteCacheGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteCacheGroup
     */
    readonly id: string
}

/**
 * Request parameters for dboAPIDeleteCacheTTL operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIDeleteCacheTTLRequest
 */
export interface DboAPIApiDboAPIDeleteCacheTTLRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteCacheTTL
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteCacheTTL
     */
    readonly cacheId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteCacheTTL
     */
    readonly id: string
}

/**
 * Request parameters for dboAPIDeleteDatabaseInstance operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIDeleteDatabaseInstanceRequest
 */
export interface DboAPIApiDboAPIDeleteDatabaseInstanceRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteDatabaseInstance
     */
    readonly id: string
}

/**
 * Request parameters for dboAPIDeleteLogicalDatabase operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIDeleteLogicalDatabaseRequest
 */
export interface DboAPIApiDboAPIDeleteLogicalDatabaseRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteLogicalDatabase
     */
    readonly instanceId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIDeleteLogicalDatabase
     */
    readonly name: string
}

/**
 * Request parameters for dboAPIExchangeCacheState operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIExchangeCacheStateRequest
 */
export interface DboAPIApiDboAPIExchangeCacheStateRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIExchangeCacheState
     */
    readonly cacheGroupId: string

    /**
     * 
     * @type {DboV1ProxyState}
     * @memberof DboAPIApiDboAPIExchangeCacheState
     */
    readonly state: DboV1ProxyState
}

/**
 * Request parameters for dboAPIGetCacheQueries operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIGetCacheQueriesRequest
 */
export interface DboAPIApiDboAPIGetCacheQueriesRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetCacheQueries
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetCacheQueries
     */
    readonly cacheId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetCacheQueries
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof DboAPIApiDboAPIGetCacheQueries
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof DboAPIApiDboAPIGetCacheQueries
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof DboAPIApiDboAPIGetCacheQueries
     */
    readonly sortOrder?: DboAPIGetCacheQueriesSortOrderEnum
}

/**
 * Request parameters for dboAPIGetCacheSummary operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIGetCacheSummaryRequest
 */
export interface DboAPIApiDboAPIGetCacheSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetCacheSummary
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetCacheSummary
     */
    readonly cacheId: string
}

/**
 * Request parameters for dboAPIGetDatabaseCluster operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIGetDatabaseClusterRequest
 */
export interface DboAPIApiDboAPIGetDatabaseClusterRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetDatabaseCluster
     */
    readonly clusterId: string
}

/**
 * Request parameters for dboAPIGetDatabaseInstance operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIGetDatabaseInstanceRequest
 */
export interface DboAPIApiDboAPIGetDatabaseInstanceRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetDatabaseInstance
     */
    readonly instanceId: string
}

/**
 * Request parameters for dboAPIGetRegistrationScript operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIGetRegistrationScriptRequest
 */
export interface DboAPIApiDboAPIGetRegistrationScriptRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetRegistrationScript
     */
    readonly registrationId: string
}

/**
 * Request parameters for dboAPIGetRegistrationStatus operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIGetRegistrationStatusRequest
 */
export interface DboAPIApiDboAPIGetRegistrationStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIGetRegistrationStatus
     */
    readonly registrationId: string
}

/**
 * Request parameters for dboAPIIngestOperationalMetrics operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIIngestOperationalMetricsRequest
 */
export interface DboAPIApiDboAPIIngestOperationalMetricsRequest {
    /**
     * 
     * @type {DboV1OperationalMetrics}
     * @memberof DboAPIApiDboAPIIngestOperationalMetrics
     */
    readonly metrics: DboV1OperationalMetrics

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIIngestOperationalMetrics
     */
    readonly instanceId?: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIIngestOperationalMetrics
     */
    readonly component?: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIIngestOperationalMetrics
     */
    readonly cacheGroupId?: string
}

/**
 * Request parameters for dboAPIListCacheConfigurations operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIListCacheConfigurationsRequest
 */
export interface DboAPIApiDboAPIListCacheConfigurationsRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIListCacheConfigurations
     */
    readonly groupId: string
}

/**
 * Request parameters for dboAPIListCacheGroups operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIListCacheGroupsRequest
 */
export interface DboAPIApiDboAPIListCacheGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIListCacheGroups
     */
    readonly instanceId?: string
}

/**
 * Request parameters for dboAPIListCacheTTLs operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIListCacheTTLsRequest
 */
export interface DboAPIApiDboAPIListCacheTTLsRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIListCacheTTLs
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIListCacheTTLs
     */
    readonly cacheId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIListCacheTTLs
     */
    readonly table?: string
}

/**
 * Request parameters for dboAPIUpdateCacheConfiguration operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIUpdateCacheConfigurationRequest
 */
export interface DboAPIApiDboAPIUpdateCacheConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIUpdateCacheConfiguration
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIUpdateCacheConfiguration
     */
    readonly id: string

    /**
     * 
     * @type {DboV1CacheConfiguration}
     * @memberof DboAPIApiDboAPIUpdateCacheConfiguration
     */
    readonly config: DboV1CacheConfiguration
}

/**
 * Request parameters for dboAPIUpdateCacheGroup operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIUpdateCacheGroupRequest
 */
export interface DboAPIApiDboAPIUpdateCacheGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIUpdateCacheGroup
     */
    readonly id: string

    /**
     * 
     * @type {DboV1CacheGroup}
     * @memberof DboAPIApiDboAPIUpdateCacheGroup
     */
    readonly group: DboV1CacheGroup
}

/**
 * Request parameters for dboAPIUpdateCacheTTL operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIUpdateCacheTTLRequest
 */
export interface DboAPIApiDboAPIUpdateCacheTTLRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIUpdateCacheTTL
     */
    readonly groupId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIUpdateCacheTTL
     */
    readonly cacheId: string

    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIUpdateCacheTTL
     */
    readonly id: string

    /**
     * 
     * @type {DboV1TTLConfiguration}
     * @memberof DboAPIApiDboAPIUpdateCacheTTL
     */
    readonly config: DboV1TTLConfiguration
}

/**
 * Request parameters for dboAPIUpdateDatabaseInstance operation in DboAPIApi.
 * @export
 * @interface DboAPIApiDboAPIUpdateDatabaseInstanceRequest
 */
export interface DboAPIApiDboAPIUpdateDatabaseInstanceRequest {
    /**
     * 
     * @type {string}
     * @memberof DboAPIApiDboAPIUpdateDatabaseInstance
     */
    readonly id: string

    /**
     * 
     * @type {DboV1UpdateDatabaseInstanceRequestUpdateInstance}
     * @memberof DboAPIApiDboAPIUpdateDatabaseInstance
     */
    readonly instance: DboV1UpdateDatabaseInstanceRequestUpdateInstance
}

/**
 * DboAPIApi - object-oriented interface
 * @export
 * @class DboAPIApi
 * @extends {BaseAPI}
 */
export class DboAPIApi extends BaseAPI {
    /**
     * Creates new cache configuration in a given cache group.
     * @summary Create cache configuration
     * @param {DboAPIApiDboAPICreateCacheConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPICreateCacheConfiguration(requestParameters: DboAPIApiDboAPICreateCacheConfigurationRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPICreateCacheConfiguration(requestParameters.groupId, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new cache group.
     * @summary Create cache group.
     * @param {DboAPIApiDboAPICreateCacheGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPICreateCacheGroup(requestParameters: DboAPIApiDboAPICreateCacheGroupRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPICreateCacheGroup(requestParameters.group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new cache TTL configuration.
     * @summary Create cache TTL configuration
     * @param {DboAPIApiDboAPICreateCacheTTLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPICreateCacheTTL(requestParameters: DboAPIApiDboAPICreateCacheTTLRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPICreateCacheTTL(requestParameters.groupId, requestParameters.id, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new database instances.
     * @summary Create database instance
     * @param {DboAPIApiDboAPICreateDatabaseInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPICreateDatabaseInstance(requestParameters: DboAPIApiDboAPICreateDatabaseInstanceRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPICreateDatabaseInstance(requestParameters.instance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new logical database.
     * @summary Create logical database.
     * @param {DboAPIApiDboAPICreateLogicalDatabaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPICreateLogicalDatabase(requestParameters: DboAPIApiDboAPICreateLogicalDatabaseRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPICreateLogicalDatabase(requestParameters.instanceId, requestParameters.db, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new registration.
     * @param {DboAPIApiDboAPICreateRegistrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPICreateRegistration(requestParameters: DboAPIApiDboAPICreateRegistrationRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPICreateRegistration(requestParameters.registration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create registration status update.
     * @param {DboAPIApiDboAPICreateRegistrationStatusUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPICreateRegistrationStatusUpdate(requestParameters: DboAPIApiDboAPICreateRegistrationStatusUpdateRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPICreateRegistrationStatusUpdate(requestParameters.registrationId, requestParameters.statusUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove the onboarded cloud account.
     * @param {DboAPIApiDboAPIDeleteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIDeleteAccount(requestParameters: DboAPIApiDboAPIDeleteAccountRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIDeleteAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes cache configuration.
     * @summary Deletes cache configuration
     * @param {DboAPIApiDboAPIDeleteCacheConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIDeleteCacheConfiguration(requestParameters: DboAPIApiDboAPIDeleteCacheConfigurationRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIDeleteCacheConfiguration(requestParameters.groupId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a given cache group.
     * @summary Deletes cache group.
     * @param {DboAPIApiDboAPIDeleteCacheGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIDeleteCacheGroup(requestParameters: DboAPIApiDboAPIDeleteCacheGroupRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIDeleteCacheGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes TTL cache configuration.
     * @summary Deletes TTL cache configuration
     * @param {DboAPIApiDboAPIDeleteCacheTTLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIDeleteCacheTTL(requestParameters: DboAPIApiDboAPIDeleteCacheTTLRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIDeleteCacheTTL(requestParameters.groupId, requestParameters.cacheId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes database instance.
     * @summary Delete database instance
     * @param {DboAPIApiDboAPIDeleteDatabaseInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIDeleteDatabaseInstance(requestParameters: DboAPIApiDboAPIDeleteDatabaseInstanceRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIDeleteDatabaseInstance(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete logical database.
     * @summary Delete logical database.
     * @param {DboAPIApiDboAPIDeleteLogicalDatabaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIDeleteLogicalDatabase(requestParameters: DboAPIApiDboAPIDeleteLogicalDatabaseRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIDeleteLogicalDatabase(requestParameters.instanceId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State/config exchange between a proxy instance and CAST AI control plane.
     * @summary State exchange
     * @param {DboAPIApiDboAPIExchangeCacheStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIExchangeCacheState(requestParameters: DboAPIApiDboAPIExchangeCacheStateRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIExchangeCacheState(requestParameters.cacheGroupId, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves cache queries for the current organization.
     * @summary Get cache queries
     * @param {DboAPIApiDboAPIGetCacheQueriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIGetCacheQueries(requestParameters: DboAPIApiDboAPIGetCacheQueriesRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIGetCacheQueries(requestParameters.groupId, requestParameters.cacheId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves cache summary for the current organization.
     * @summary Get cache summary
     * @param {DboAPIApiDboAPIGetCacheSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIGetCacheSummary(requestParameters: DboAPIApiDboAPIGetCacheSummaryRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIGetCacheSummary(requestParameters.groupId, requestParameters.cacheId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves database cluster details.
     * @summary Get database cluster details
     * @param {DboAPIApiDboAPIGetDatabaseClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIGetDatabaseCluster(requestParameters: DboAPIApiDboAPIGetDatabaseClusterRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIGetDatabaseCluster(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves database instances details.
     * @summary Get database instance details
     * @param {DboAPIApiDboAPIGetDatabaseInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIGetDatabaseInstance(requestParameters: DboAPIApiDboAPIGetDatabaseInstanceRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIGetDatabaseInstance(requestParameters.instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns shell script to execute a registration
     * @param {DboAPIApiDboAPIGetRegistrationScriptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIGetRegistrationScript(requestParameters: DboAPIApiDboAPIGetRegistrationScriptRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIGetRegistrationScript(requestParameters.registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get registration status.
     * @param {DboAPIApiDboAPIGetRegistrationStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIGetRegistrationStatus(requestParameters: DboAPIApiDboAPIGetRegistrationStatusRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIGetRegistrationStatus(requestParameters.registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ingests operational metrics from proxy and query-processor.
     * @summary Ingest Operational Processor metrics
     * @param {DboAPIApiDboAPIIngestOperationalMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIIngestOperationalMetrics(requestParameters: DboAPIApiDboAPIIngestOperationalMetricsRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIIngestOperationalMetrics(requestParameters.metrics, requestParameters.instanceId, requestParameters.component, requestParameters.cacheGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List onboarded cloud accounts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIListAccounts(options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIListAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists cache configurations for a given cache group.
     * @summary List cache configurations
     * @param {DboAPIApiDboAPIListCacheConfigurationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIListCacheConfigurations(requestParameters: DboAPIApiDboAPIListCacheConfigurationsRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIListCacheConfigurations(requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists cache groups in a given organization.
     * @summary List cache groups.
     * @param {DboAPIApiDboAPIListCacheGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIListCacheGroups(requestParameters: DboAPIApiDboAPIListCacheGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIListCacheGroups(requestParameters.instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists cache TTL configurations for a given cache configuration.
     * @summary List cache TTL configurations
     * @param {DboAPIApiDboAPIListCacheTTLsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIListCacheTTLs(requestParameters: DboAPIApiDboAPIListCacheTTLsRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIListCacheTTLs(requestParameters.groupId, requestParameters.cacheId, requestParameters.table, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists database components for the current organization.
     * @summary List database components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIListDatabaseComponents(options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIListDatabaseComponents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists database instances for the current organization.
     * @summary List database instances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIListDatabaseInstances(options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIListDatabaseInstances(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates cache configuration.
     * @summary Updates cache configuration
     * @param {DboAPIApiDboAPIUpdateCacheConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIUpdateCacheConfiguration(requestParameters: DboAPIApiDboAPIUpdateCacheConfigurationRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIUpdateCacheConfiguration(requestParameters.groupId, requestParameters.id, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates cache group.
     * @summary Update cache group.
     * @param {DboAPIApiDboAPIUpdateCacheGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIUpdateCacheGroup(requestParameters: DboAPIApiDboAPIUpdateCacheGroupRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIUpdateCacheGroup(requestParameters.id, requestParameters.group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates TTL cache configuration.
     * @summary Updates TTL cache configuration
     * @param {DboAPIApiDboAPIUpdateCacheTTLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIUpdateCacheTTL(requestParameters: DboAPIApiDboAPIUpdateCacheTTLRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIUpdateCacheTTL(requestParameters.groupId, requestParameters.cacheId, requestParameters.id, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates database instance.
     * @summary Update database instance
     * @param {DboAPIApiDboAPIUpdateDatabaseInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DboAPIApi
     */
    public dboAPIUpdateDatabaseInstance(requestParameters: DboAPIApiDboAPIUpdateDatabaseInstanceRequest, options?: RawAxiosRequestConfig) {
        return DboAPIApiFp(this.configuration).dboAPIUpdateDatabaseInstance(requestParameters.id, requestParameters.instance, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DboAPIGetCacheQueriesSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type DboAPIGetCacheQueriesSortOrderEnum = typeof DboAPIGetCacheQueriesSortOrderEnum[keyof typeof DboAPIGetCacheQueriesSortOrderEnum];
