import { Divider, Stack, Typography } from '@mui/material';

import { Button, DialogTitle, IconButton, Icons } from '@cast/design-system';

import { TerraformSvg } from 'assets/svg';
import { EXTERNAL_LINKS } from 'common/links';
import { K8sProviderIcon } from 'components/icons';
import { ExternalLink } from 'components/router';
import { openChat } from 'utils/supportChat';

import { useCluster } from '../../../useCluster';

type Props = {
  dismiss: () => void;
  showTerraformLink?: boolean;
};

export const AssignCredentialsDialogHeader = ({
  dismiss,
  showTerraformLink = true,
}: Props) => {
  const { cluster, isTerraformManaged } = useCluster();
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        height={28}
        position="absolute"
        top={8}
        right={8}
      >
        {!isTerraformManaged && showTerraformLink && (
          <>
            <Button
              variant="text"
              component={ExternalLink}
              link={EXTERNAL_LINKS.castai_terraform}
              startIcon={<TerraformSvg />}
              noIcon
            >
              Terraform
            </Button>

            <Divider
              orientation="vertical"
              sx={{
                height: 22,
                borderRight: 'grey.300',
                mx: 8,
              }}
            />
          </>
        )}

        <IconButton variant="ghost" size="small" onClick={openChat}>
          <Icons.Question />
        </IconButton>
        <IconButton
          variant="ghost"
          size="small"
          onClick={dismiss}
          testId="close-dialog"
        >
          <Icons.X />
        </IconButton>
      </Stack>
      <DialogTitle component="div" sx={{ py: 24 }}>
        <Stack direction="row" alignItems="center" gap={8}>
          <K8sProviderIcon provider={cluster.providerType} size={24} />
          <Typography variant="h5">{cluster.name}</Typography>
        </Stack>
      </DialogTitle>
    </>
  );
};
