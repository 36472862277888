import { ReactNode, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { SubRow } from './SubRow';
import { VerticalLine } from './VerticalLine';
import { isLoadingRow } from '../../../../_utils';
import { RowSkeleton } from '../../../../data-table/RowSkeleton';
import { useTableContext } from '../../../../hooks/useTableContext';
import { RowState } from '../../../../types';

type Props = {
  row: any;
  index: number;
  level?: number;
  drawHorizLine?: boolean;
  drawExpander?: boolean;
  isTreeExpanded?: boolean;
  isLastRow?: boolean;
};

export const TreeRow = ({ row, index, level = 0 }: Props) => {
  const ctx = useTableContext();

  const api = ctx.api.current;
  const utils = ctx.tableProps.treeUiUtils!;
  const getRowKey = ctx.tableProps.rowKey;
  const isExpanded = api.state.expandedTreeRows?.includes(getRowKey(row));

  const rowState: RowState = {
    row,
    index,
    tableApi: api,
    isExpanded,
    rowKey: getRowKey(row),
    level,
  };

  const config = ctx.tableProps.treeUiConfig!;
  const childRowElements: ReactNode[] = [];
  const [isRowLoading, setIsRowLoading] = useState(false);
  const [childRows, setChildRows] = useState<any>([]);
  const hasChildren =
    !!ctx.tableProps?.hasChildren?.(row, rowState) ||
    (typeof ctx.tableProps?.childRows === 'string' &&
      !!row[ctx.tableProps.childRows]);

  useEffect(() => {
    const getTreeRows = async () => {
      setIsRowLoading(true);
      try {
        const treeRows = await api.getTreeRows(
          row,
          ctx.tableProps.childRows,
          rowState
        );
        setChildRows(treeRows);
      } catch {
        setChildRows([]);
        api.collapseTreeRow(row);
      } finally {
        setIsRowLoading(false);
      }
    };
    if (isExpanded && hasChildren) {
      getTreeRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded, hasChildren]);

  const childRowsCount = childRows?.length || 0;
  const thisRowRef = useRef<HTMLDivElement | null>();
  const [childRowsWrapper, setChildRowsWrapper] =
    useState<HTMLDivElement | null>(null);

  let thisRow: ReactNode = (
    <SubRow
      row={row}
      index={index}
      level={level}
      hasChildren={hasChildren}
      isTreeExpanded={isExpanded}
    />
  );

  const isLoading = isLoadingRow(row);
  if (isLoading) {
    thisRow = ctx.tableProps.loadingRowSkeleton;
  }

  if (hasChildren && isExpanded) {
    for (let i = 0; i < childRowsCount; i++) {
      const c = childRows![i];
      const rowKey = getRowKey(c);
      childRowElements.push(
        <TreeRow
          key={rowKey}
          row={c}
          index={index}
          level={level + 1}
          isLastRow={i === childRowsCount - 1}
        />
      );
    }
  }

  return (
    <div
      className="DS-Table-TreeRow-root"
      ref={thisRowRef as any}
      data-has-children={hasChildren ? 1 : 0}
    >
      {!isRowLoading && hasChildren && isExpanded && thisRowRef.current && (
        <VerticalLine
          rowRoot={thisRowRef.current}
          subRow={childRowsWrapper?.previousSibling as HTMLElement} // {thisRow}
          childWrapper={childRowsWrapper as HTMLElement} // data-child-rows-container
          config={config}
          utils={utils}
          level={level}
        />
      )}
      {thisRow}
      {hasChildren && (
        <div
          data-child-rows-container
          className={clsx(
            'DS-Table-TreeChildRowsWrapper',
            !level && 'DS-Table-TreeChildRowsWrapper-rootLevel',
            isLoading && 'DS-Table-loadingRow'
          )}
          ref={setChildRowsWrapper}
        >
          {isExpanded && <>{childRowElements}</>}
          {isRowLoading && (
            <RowSkeleton
              marginLeft={`${utils.getVerticalLineIndentation(level)}px`}
              width={`calc(100% - ${utils.getVerticalLineIndentation(
                level
              )}px)`}
              height={ctx.tableProps.rowHeight}
            />
          )}
        </div>
      )}
    </div>
  );
};
