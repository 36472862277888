import * as Sentry from '@sentry/react';

import { ApiClientFactory } from '@cast/api-client';
import { client } from '@cast/core';

export const apiClient = ApiClientFactory(client);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config.url.includes('agent.yaml')) {
      Sentry.captureException(error);
      return Promise.reject(error);
    }

    if (
      error.config.url === 'auth/session' ||
      error.config.url.includes('/chat/completions')
    ) {
      Sentry.captureException(error);
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      return apiClient.auth
        .logout({ returnTo: window.location.origin })
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log('logout');
          // eslint-disable-next-line no-console
          console.log({
            data: response?.data,
            headers: response?.headers,
            status: response?.status,
          });
        })
        .finally(() => {
          // eslint-disable-next-line no-console
          console.log('reload');
          window.location.reload();
        });
    }

    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export { client };
