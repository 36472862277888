import { useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import { useDatePickerContext } from './useDatePickerContext';
import { Autocomplete } from '../../controls';
import { Globe } from '../../icons';
import { ListItem } from '../../lists';
import { Tooltip } from '../../tooltip';

const getGMTOffset = (timeZone: string) => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'shortOffset',
  });
  const parts = formatter.formatToParts(new Date());
  return parts.find((part) => part.type === 'timeZoneName')?.value || '';
};

const getCityFromTimeZone = (timeZone: string) => {
  const splitZone = timeZone.split('/');
  return splitZone[splitZone.length - 1].replace('_', ' ');
};

const getTimezones = () => {
  const timezones = Intl.supportedValuesOf('timeZone');
  return [
    { label: 'UTC', zone: 'UTC', offset: '' },
    ...timezones.map((zone) => {
      const offset = getGMTOffset(zone);
      const city = getCityFromTimeZone(zone);
      return {
        label: city,
        zone,
        offset: offset === 'GMT' ? 'GMT+0' : offset,
      };
    }),
  ];
};

type TimezonePickerProps = {
  isMini?: boolean;
};

export const TimezonePicker = ({ isMini = false }: TimezonePickerProps) => {
  const ctx = useDatePickerContext();

  const [isOpen, setIsOpen] = useState(false);
  const timezones = useMemo(() => getTimezones(), []);

  const autocomplete = (
    <Autocomplete
      testId="timezone-picker"
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      sx={{ width: isMini ? 32 : 154 }}
      inputProps={{
        sx: isMini
          ? {
              '.DS-Input-endAdornment-root': {
                display: 'none',
              },
            }
          : null,
      }}
      startAdornment={<Globe />}
      value={ctx.timezone}
      onChange={(option) => {
        ctx.setTimezone(option.zone);
        setIsOpen(false);
      }}
      options={timezones}
      popoverProps={{
        anchorOrigin: {
          vertical: -4,
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      virtualization
      virtualizationProps={{
        maxHeight: 200,
      }}
      isOptionEqualToValue={(option, value) => option.zone === value}
      optionKey={(option) => option.zone}
      optionLabel={(option) => option.label}
      renderValue={({ offset, label }) =>
        `${label}${offset ? `, ${offset}` : ''}`
      }
      renderOption={(option, props) => {
        return (
          <ListItem {...props}>
            <Stack direction="row" gap={4}>
              <Typography variant="P12M">{option.label}</Typography>
              {option.offset && (
                <Typography
                  variant="P10R"
                  color="textSecondary"
                  alignSelf="center"
                >
                  ({option.offset})
                </Typography>
              )}
            </Stack>
          </ListItem>
        );
      }}
      size="small"
    />
  );

  if (isMini) {
    return (
      <Tooltip title={!isOpen ? ctx.timezone : ''} placement="top">
        <div>{autocomplete}</div>
      </Tooltip>
    );
  }

  return autocomplete;
};
