import { ChipProps, mergeSx } from '@cast/design-system';

import { LabelChip } from 'components/chips';

export const DemoLabel = ({ sx }: ChipProps) => (
  <LabelChip
    size="micro"
    sx={mergeSx(
      {
        backgroundColor: 'aqua.200',
        color: 'aqua.900',
        mr: 'auto',
        alignSelf: 'flex-start',
        transform: 'translateX(-8px)',

        '& > *': {
          cursor: 'inherit',
        },
      },
      sx
    )}
  >
    Demo
  </LabelChip>
);
