import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys, apiClient } from 'core/react-query';

export const useMarkEligibleForTrialMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => apiClient.costReport.markEligibleForTrial(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL_STATUS],
      });
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });
};
