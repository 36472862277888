import { useQuery } from '@tanstack/react-query';

import { TrialStatusResponse } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';

import { IS_TILT } from '../../../common/constants';
import { useAuth } from '../../../core/auth';

type useTrialStatusQueryArgs = {
  data?: TrialStatusResponse;
  isLoading: boolean;
  error: unknown;
  refetch: () => void;
};

export const useTrialStatusQuery = (): useTrialStatusQueryArgs => {
  const { isAuthenticated } = useAuth();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [QueryKeys.TRIAL_STATUS],
    queryFn: async () => {
      const { data } = await apiClient.costReport.getTrialStatus();
      return data;
    },
    enabled: isAuthenticated && !IS_TILT,
  });

  return { data, isLoading, error, refetch };
};
