import { useMemo } from 'react';

import { hasNoSecurityAgentsEnabled } from '@cast/utils';

import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';

import { useActiveClusters } from '../../_hooks';

export const useHasNoAgentsEnabled = () => {
  const { clusters } = useActiveClusters();
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { agentsData, isLoading, error, refetch } = useSecurityInsightsAgents({
    clusterIds,
    enabled: !!clusterIds.length,
  });
  const hasNoAgentsEnabled = useMemo(
    () => hasNoSecurityAgentsEnabled(agentsData?.agentStatuses),
    [agentsData?.agentStatuses]
  );

  return { hasNoAgentsEnabled, isLoading, error, refetch };
};
