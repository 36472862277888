import { useContext, useMemo, useState } from 'react';

import { Box, Slide, Stack, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';

import { CAN_VIEW_SECURITY } from '@cast/console/constants';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Icons,
  ShadowDivider,
} from '@cast/design-system';
import { K8sProvider } from '@cast/types';

import { AgentCodeSnippet } from 'components/code';
import { RffForm } from 'core/forms/rff';
import { useUpdateClusterMutation } from 'hooks/mutations';
import { useCredentialsScriptQuery } from 'hooks/queries/cluster';
import { useCluster } from 'hooks/useCluster';
import { isEKSCluster } from 'types/cluster';
import { parseApiError } from 'utils/api';

import { AgentAutoscalerSetup } from './setup-blocks/AgentAutoscalerSetup';
import { AgentCostSetup } from './setup-blocks/AgentCostSetup';
import { AgentSecuritySetup } from './setup-blocks/AgentSecuritySetup';
import { ExpandedConfiguration } from '../../../_components/ExpandedConfiguration';
import { AssignCredentialsDialogHeader } from '../../AssignCredentialsDialogHeader';
import {
  ManualCredentialsInputFormValue,
  useManualCredentialsInput,
} from '../_hooks/useManualCredentialsInput';
import { AssignCredentialsDialogContext } from '../context';

const securityConstructByProvider = {
  [K8sProvider.EKS]: 'generated role ARN',
  [K8sProvider.AKS]: 'App secret',
  [K8sProvider.GKE]: 'Service Account JSON',
  [K8sProvider.KOPS]: 'IAM user key/secret',
  [K8sProvider.ROSA]: '', // TODO: add value
};

type AssignCredentialsSetupSlideProps = {
  hasSelection: boolean;
  onSubmit: () => void;
};

export const AssignCredentialsSetupSlide = ({
  hasSelection,
  onSubmit,
}: AssignCredentialsSetupSlideProps) => {
  const {
    setActiveSlide,
    includeAutoscaler,
    setIncludeAutoscaler,
    includeSecurity,
    setIncludeSecurity,
    extendedSettings,
    setExtendedSettings,
    activeFeatures,
    dismiss,
  } = useContext(AssignCredentialsDialogContext);
  const [isEnteringManually, setIsEnteringManually] = useState(false);
  const [isConfigExpanded, setIsConfigExpanded] = useState(false);
  const hasActiveFeatures = useMemo(
    () =>
      Object.entries(activeFeatures).some(
        ([key, value]) => key !== 'costMonitoring' && value // exclude cost monitoring since it's always enabled
      ),
    [activeFeatures]
  );

  const [apiError, setApiError] = useState<string | undefined>();
  const { cluster } = useCluster();
  const { isLoading, isFetching, error, refetch, script } =
    useCredentialsScriptQuery({
      id: cluster.id,
      installGPUPlugin: extendedSettings.includeGpuPlugin,
      installSecurityAgent: includeSecurity,
      installAutoscalerAgent: includeAutoscaler,
      installNetflowExporter: extendedSettings.includeNetworkCosts,
      installGpuMetricsExporter: extendedSettings.includeGpuExporter,
    });

  const securityConstruct = securityConstructByProvider[cluster.providerType];
  const { validate, input, initialValue, getValue } =
    useManualCredentialsInput();

  const { mutate, isPending: isSubmitting } = useUpdateClusterMutation(
    cluster.id
  );

  return (
    <RffForm
      initialValues={initialValue}
      initialValuesEqual={isEqual}
      validate={validate}
      onSubmit={(value: ManualCredentialsInputFormValue) => {
        onSubmit();
        setApiError(undefined);
        mutate(getValue(value), {
          onSuccess: () => setActiveSlide('success'),
          onError: (error: unknown) => setApiError(parseApiError(error)),
        });
      }}
      testId="phase-2-onboarding-setup-step"
    >
      <AssignCredentialsDialogHeader dismiss={dismiss} />
      <DialogContent sx={{ pb: 0 }}>
        {CAN_VIEW_SECURITY() && (
          <>
            <Typography variant="L10M" color="grey.400" mb={8}>
              Select features to enable:
            </Typography>

            <Stack>
              <AgentAutoscalerSetup
                checked={includeAutoscaler}
                readonly={activeFeatures.autoscaler}
                onChange={(checked) => {
                  // Reset includeGpuPlugin value if autoscaler is deselected
                  if (!checked && isEKSCluster(cluster)) {
                    setExtendedSettings({
                      ...extendedSettings,
                      includeGpuPlugin: false,
                    });
                  }

                  setIncludeAutoscaler(checked);
                }}
              />
              <AgentSecuritySetup
                checked={includeSecurity}
                readonly={activeFeatures.security}
                onChange={setIncludeSecurity}
              />
              <AgentCostSetup
                checked
                readonly={activeFeatures.costMonitoring}
                extendedSettingsSelection={extendedSettings}
                activeFeatures={activeFeatures}
                onAdvancedOptionChange={(payload) =>
                  setExtendedSettings({
                    ...extendedSettings,
                    ...payload,
                  })
                }
              />
            </Stack>
          </>
        )}

        {(hasActiveFeatures || hasSelection) && (
          <Stack>
            <Typography variant="L10M" color="grey.400" py={8}>
              Run the script:
            </Typography>
            <Box mx={-24}>
              <ShadowDivider orientation="horizontal" size={16} />
              <Stack px={24} pb={16} bgcolor="grey.50">
                <Stack
                  sx={isEnteringManually ? { opacity: 0.5 } : undefined}
                  mb={8}
                  data-testid="script-container"
                >
                  {isEKSCluster(cluster) && includeAutoscaler && (
                    <>
                      {isConfigExpanded ? (
                        <Box overflow="hidden" mb={12}>
                          <Slide in mountOnEnter unmountOnExit>
                            <div>
                              <ExpandedConfiguration
                                settings={extendedSettings}
                                setSettings={(payload) =>
                                  setExtendedSettings({
                                    ...extendedSettings,
                                    ...payload,
                                  })
                                }
                                onClose={() => setIsConfigExpanded(false)}
                              />
                            </div>
                          </Slide>
                        </Box>
                      ) : (
                        <Button
                          variant="text"
                          sx={{ mb: 8 }}
                          onClick={
                            !isEnteringManually
                              ? () => setIsConfigExpanded(true)
                              : undefined
                          }
                          disabled={isSubmitting}
                          testId="manual-config-button"
                        >
                          Configure
                        </Button>
                      )}
                    </>
                  )}

                  <AgentCodeSnippet
                    code={script}
                    isLoading={isLoading || isFetching}
                    skeletonHeight={132}
                    error={error}
                    refetch={refetch}
                  />
                </Stack>

                <Box overflow="hidden">
                  {isEnteringManually ? (
                    <Slide in mountOnEnter unmountOnExit>
                      <Stack>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={8}
                        >
                          <Typography variant="P12B">
                            Add {securityConstruct} manually
                          </Typography>

                          <IconButton
                            variant="ghost"
                            size="small"
                            onClick={() => setIsEnteringManually(false)}
                            disabled={isSubmitting}
                            testId="close-manual-inputs-button"
                          >
                            <Icons.X />
                          </IconButton>
                        </Box>

                        {isEnteringManually && (
                          <Stack>
                            {input}
                            {!!apiError && (
                              <Typography
                                variant="P12R"
                                color="red.500"
                                data-testid="manual-input-api-error"
                              >
                                {apiError}
                              </Typography>
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </Slide>
                  ) : (
                    <Box display="flex" alignItems="center" gap={4} pt={8}>
                      <Typography variant="P12R" color="grey.500">
                        Add {securityConstruct}
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => setIsEnteringManually(true)}
                        testId="open-manual-inputs-button"
                      >
                        manually
                      </Button>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ borderTop: 'grey.100' }}>
        {isEnteringManually ? (
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            testId="submit-button"
          >
            Submit
          </Button>
        ) : (
          <Button
            onClick={() => {
              setActiveSlide('inProgress');
              onSubmit();
            }}
            testId="i-ran-the-script-button"
            disabled={!hasSelection}
          >
            I ran the script
          </Button>
        )}
      </DialogActions>
    </RffForm>
  );
};
