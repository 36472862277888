import { ThemeOptions } from '@mui/material/styles/createTheme';

import { DataPopperProps, DataPopperRowProps } from './types';
import { getThemeColor } from '../../hooks/theme';
import { DsShadow } from '../../theme/shadows';

export const dataPopperTheme: ThemeOptions['components'] = {
  DsDataPopper: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: theme.shadows[DsShadow.TOOLTIP],
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '4px 0',
        display: 'flex',
        flexDirection: 'column',
      }),
    },
  },

  DsDataPopperRow: {
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const size = ownerState?.size || ownerState.popperProps?.size;
        let paddingX = 20;
        let gap = 8;
        let height = 32;
        if (size === 'small') {
          paddingX = 12;
          gap = 4;
          height = 28;
        } else if (size === 'large') {
          height = 36;
        }
        let typography = theme.typography.P12R;
        if (size === 'small') {
          typography = theme.typography.P10R;
        } else if (size === 'large') {
          typography = theme.typography.P14R;
        }
        return {
          display: 'flex',
          alignItems: 'center',
          gap,
          height,
          paddingLeft: paddingX,
          paddingRight: paddingX,
          ...typography,
        };
      },
      rowContent: ({ ownerState }) => {
        const hasLeft = ownerState?.left !== undefined;
        const hasRight = ownerState?.right !== undefined;
        let justifyContent = 'space-between';

        if (hasLeft && !hasRight) {
          justifyContent = 'flex-start';
        } else if (hasRight && !hasLeft) {
          justifyContent = 'flex-end';
        }
        return {
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent,
        };
      },
    },
  },

  DsDataPopperRowIndicator: {
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const rowProps = ownerState?.rowProps as DataPopperRowProps;
        const popperProps = ownerState?.popperProps as DataPopperProps;
        const size =
          (ownerState?.size || rowProps?.size || popperProps?.size) === 'small'
            ? 8
            : 12;

        const variantStyles = {
          backgroundColor:
            ownerState.variant === 'filled'
              ? getThemeColor(theme, ownerState?.color)
              : 'none',
          border: `1px solid ${getThemeColor(theme, ownerState?.color)}`,
          borderStyle: ownerState.variant === 'dashed' ? 'dashed' : 'solid',
        };

        return {
          boxSizing: 'border-box',
          width: size,
          height: size,
          borderRadius: '2px',
          ...variantStyles,
        };
      },
    },
  },

  DsDataPopperRowDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: getThemeColor(theme, 'grey.100'),
        '&:first-child, &:last-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */':
          {
            display: 'none',
          },
      }),
    },
  },

  DsDataPopperCell: {
    styleOverrides: {
      root: {
        display: 'flex',
      },
    },
  },
};
