import { Stack, Typography } from '@mui/material';

import { Cluster } from '@cast/types';

import { HexIconSuccess } from 'components/hex-icons';
import { K8sProviderIcon } from 'components/icons';

type ConnectedStateProps = {
  cluster?: Cluster;
  reconnect?: boolean;
};

export const ConnectedState = ({ cluster, reconnect }: ConnectedStateProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={12}
      data-testid="connected-state"
    >
      <HexIconSuccess
        display="flex"
        alignSelf="flex-start"
        size={44}
        animationType="pulsateSlow"
        iconSize={16}
      />

      <Typography
        variant="h6"
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        Great!{' '}
        {cluster?.providerType && (
          <K8sProviderIcon provider={cluster.providerType} size={16} />
        )}{' '}
        {cluster?.name} {reconnect ? 'reconnected' : 'connected'} successfully!
      </Typography>
    </Stack>
  );
};
