import {
  ComponentType,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { styled, Popover, PopoverProps } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { bindPopover, bindToggle } from 'material-ui-popup-state/hooks';

import { DropdownList } from './DropdownList';
import { ListSearchInput } from '../../../lists';
import { DropdownContext } from '../DropdownContext';

const StyledDropdownPopper = styled('div', {
  name: 'DsDropdown',
  slot: 'Popper',
  target: 'DsDropdown-Popper',
  overridesResolver: (_, styles) => {
    return [styles.popper];
  },
})({});

StyledDropdownPopper.displayName = 'StyledDropdownPopper';

type Props = {
  PopoverComponent?: ComponentType<PopoverProps>;
};

export const DropdownPopper = ({ PopoverComponent = Popover }: Props) => {
  const {
    dropdownProps,
    popupState,
    autocomplete: {
      inputRef,
      getters: { getInputProps, isOptionEqualToValue },
      actions: { clearValue, resetValue, saveValue },
      states: { allowSearch, searchInputProps, value, options },
      popoverActionsRef,
    },
  } = useContext(DropdownContext);

  const { onClose, ...popoverProps } = bindPopover(popupState);

  useEffect(() => {
    if (popoverProps.open) {
      dropdownProps.onOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownProps.onOpen, popoverProps.open]);

  const handleOnApply = useCallback(
    (e: SyntheticEvent) => {
      saveValue();
      const _value = cloneDeep(value);
      const _options = (options ?? []).filter((item) => {
        return _value.some((v: any) => isOptionEqualToValue?.(item, v));
      });
      dropdownProps.onChange?.(_options, _value);
      bindToggle(popupState).onClick(e);
    },
    [saveValue, value, options, dropdownProps, popupState, isOptionEqualToValue]
  );

  return (
    <PopoverComponent
      action={popoverActionsRef}
      componentsProps={
        { root: { 'data-testid': `${dropdownProps.testId}-popover` } } as any
      }
      {...popoverProps}
      onClose={(e, r) => {
        if (dropdownProps.multiple) {
          handleOnApply(e as SyntheticEvent);
        } else if (r === 'backdropClick') {
          resetValue();
        }
        onClose?.();
        dropdownProps.onClose?.();
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...dropdownProps.popoverProps?.anchorOrigin,
      }}
      transformOrigin={{
        vertical:
          dropdownProps.inputProps?.error || dropdownProps.inputProps?.hint
            ? 18
            : -6,
        horizontal: 'left',
        ...dropdownProps.popoverProps?.transformOrigin,
      }}
      transitionDuration={
        // https://github.com/petyosi/react-virtuoso/issues/542
        // totalListHeightChanged is triggered too soon, while animation is still in transition, resulting with wrong initial height
        dropdownProps.virtualization ? 0 : { enter: 150, exit: 0 }
      }
      disableScrollLock
    >
      {allowSearch && inputRef && (
        <ListSearchInput
          autoFocus
          autoComplete="off"
          size={dropdownProps.size}
          placeholder={searchInputProps?.placeholder ?? 'Search'}
          showClearButton
          inputRef={inputRef}
          inputProps={{
            ...getInputProps(),
            ...searchInputProps?.inputProps,
          }}
          clearButtonProps={{ onClick: () => clearValue() }}
        />
      )}

      <DropdownList />
    </PopoverComponent>
  );
};
