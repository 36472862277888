import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import meanBy from 'lodash/meanBy';
import reduce from 'lodash/reduce';

import {
  CostReportChartDataByPeriod,
  CostReportDataByPeriod,
  UptimeWorkloadCostReportData,
} from '../../types/costOverTime';

const getAverageOfActiveDatapoints = <
  T extends CostReportChartDataByPeriod | CostReportDataByPeriod
>(
  data: T[],
  key: keyof T
) => {
  const datapoints = data.filter((d) => {
    const v = d[key];
    return typeof v === 'number';
  });
  return meanBy(datapoints, key);
};

const emptyDataPoint: Omit<
  CostReportDataByPeriod & UptimeWorkloadCostReportData,
  'timestamp' | 'forecast' | 'generated'
> = {
  totalCost: 0,
  totalCpu: 0,
  totalGpu: 0,
  totalRamGib: 0,
  totalStorage: 0,
  totalCpuCost: 0,
  totalRamCost: 0,
  totalGpuCost: 0,
  totalStorageCost: 0,
  normalizedCostPerCpu: 0,
  normalizedCostPerGib: 0,
  normalizedCostPerGpu: 0,
  costPerStorage: 0,

  onDemandCost: 0,
  onDemandCpuCost: 0,
  onDemandCostPerCpu: 0,
  onDemandCostPerGib: 0,
  onDemandCostPerGpu: 0,
  onDemandCpuCount: 0,
  onDemandUptime: 0,
  onDemandRamGib: 0,
  onDemandRamCost: 0,
  onDemandGpuCount: 0,
  onDemandGpuCost: 0,

  fallbackCost: 0,
  fallbackCpuCost: 0,
  fallbackCostPerCpu: 0,
  fallbackCostPerGib: 0,
  fallbackCostPerGpu: 0,
  fallbackCpuCount: 0,
  fallbackUptime: 0,
  fallbackRamGib: 0,
  fallbackRamCost: 0,
  fallbackGpuCount: 0,
  fallbackGpuCost: 0,

  spotCost: 0,
  spotCpuCost: 0,
  spotCostPerCpu: 0,
  spotCostPerGib: 0,
  spotCostPerGpu: 0,
  spotCpuCount: 0,
  spotUptime: 0,
  spotRamGib: 0,
  spotRamCost: 0,
  spotGpuCount: 0,
  spotGpuCost: 0,

  onDemandPodCount: 0,
  spotPodCount: 0,
  fallbackPodCount: 0,
  onDemandCostPerPod: 0,
  spotCostPerPod: 0,
  fallbackCostPerPod: 0,
  totalPods: 0,
  normalizedCostPerPod: 0,
  normalizedCostPerStorage: 0,
};

type DailyAverageMetricsOptions = {
  includeGenerated: boolean;
  includeForecasted: boolean;
};

export const getDailyAverageMetrics = <
  T extends CostReportChartDataByPeriod | CostReportDataByPeriod
>(
  data: T[],
  {
    includeGenerated = false,
    includeForecasted = false,
  }: DailyAverageMetricsOptions,
  timezone: string
): T[] => {
  const groupedHoursByDay = groupBy(data, ({ timestamp }) =>
    dayjs.utc(timestamp).tz(timezone).startOf('d').toISOString()
  );

  return reduce(
    groupedHoursByDay,
    (acc, hourlyDataPoints, key: string) => {
      const forecast = hourlyDataPoints.every(({ forecast }) => forecast);
      const validDataPoints = dayjs(key).isToday()
        ? hourlyDataPoints.filter(
            ({ totalCpu, forecast }) => totalCpu && !forecast
          )
        : hourlyDataPoints;

      const dataPoints = validDataPoints.filter(({ generated, forecast }) => {
        if (!includeGenerated && generated) {
          return false;
        }

        if (!includeForecasted && forecast) {
          return false;
        }

        return true;
      });

      if (!dataPoints.length) {
        acc.push({
          ...emptyDataPoint,
          timestamp: key,
          forecast,
        } as T);
      } else {
        const averageRates = reduce(
          Object.keys(emptyDataPoint),
          (acc, key) => {
            const k = key as keyof T;
            const rate = getAverageOfActiveDatapoints(dataPoints, k);

            return {
              ...acc,
              [key]: rate || 0,
            };
          },
          emptyDataPoint
        );

        acc.push({
          ...averageRates,
          timestamp: key,
          forecast,
        } as T);
      }

      return acc;
    },
    [] as T[]
  );
};
