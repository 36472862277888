import { useQuery } from '@tanstack/react-query';

import { CAN_VIEW_SECURITY } from '@cast/console/constants';
import {
  hasNoSecurityAgentsEnabled,
  isDemoMode,
  removeSecurityDemoMode,
  setSecurityDemoMode,
} from '@cast/utils';

import { QueryKeys, apiClient } from 'core/react-query';

type UseSecurityInsightsAgents = {
  clusterIds: string[];
  determineSecurityDemoMode?: boolean;
  enabled?: boolean;
};

export const useSecurityInsightsAgents = ({
  clusterIds,
  determineSecurityDemoMode = true,
  enabled = true,
}: UseSecurityInsightsAgents) => {
  const {
    data: agentsData,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.SECURITY_AGENT_STATUSES, clusterIds],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights
        .getAgentsStatuses({
          body: { clusterIds },
        })
        .then((response) => {
          if (determineSecurityDemoMode) {
            const { agentStatuses } = response.data;

            if (hasNoSecurityAgentsEnabled(agentStatuses) && !isDemoMode()) {
              setSecurityDemoMode();
            } else {
              removeSecurityDemoMode();
            }
          }

          return response;
        });
      return data;
    },
    enabled: enabled && CAN_VIEW_SECURITY(),
  });
  return { agentsData, isLoading, error, refetch };
};
