import { Stack } from '@mui/material';

import { DialogContent, DialogActions, Button } from '@cast/design-system';

import { useSwipeableViews } from 'components/boxes';
import { AssignCredentialsDialogHeader } from 'hooks/dialogs/cluster/_components/AssignCredentialsDialogHeader';
import { ErrorState } from 'hooks/dialogs/cluster/_components/ErrorState';

type Props = {
  onClose: () => void;
};

export const ErrorSlide = ({ onClose }: Props) => {
  const swipeableCtx = useSwipeableViews();

  return (
    <Stack position="relative">
      <AssignCredentialsDialogHeader dismiss={onClose} />
      <DialogContent>
        <ErrorState title="Failed to enable Kubernetes automation" />
      </DialogContent>
      <DialogActions sx={{ py: 16, borderTop: 'grey.100' }}>
        <Button onClick={onClose} variant="tertiary">
          Close
        </Button>
        <Button onClick={() => swipeableCtx?.setSlide('runTheScript')}>
          Retry
        </Button>
      </DialogActions>
    </Stack>
  );
};
