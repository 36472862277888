import { useLocation } from 'react-router-dom';

import { isSecurityDemoMode } from '@cast/utils';

import { useDataOrFixture } from 'hooks/useDataOrFixture';

export const useSecurityDataOrFixture = <T>(
  data: T | undefined,
  fixture: () => Promise<T>
) => {
  const { pathname } = useLocation();
  const match = pathname.startsWith('/organization/security');

  const showDemoData = match && isSecurityDemoMode();

  return useDataOrFixture(data, fixture, showDemoData);
};
