import { isDemoCluster } from '../demo';

export const hasNoSecurityAgentsEnabled = (agentStatuses?: {
  [key: string]: boolean;
}) => {
  if (!agentStatuses) {
    return true;
  }

  return Object.entries(agentStatuses).every(
    ([clusterId, agentStatus]) => isDemoCluster(clusterId) || !agentStatus
  );
};
