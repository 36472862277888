import { AutoscalerVisualSvg } from 'assets/svg';

import {
  AgentSetupBlock,
  AgentSetupBlockProps,
} from './_components/AgentSetupBlock';
import { AgentSetupCompleteBlock } from './_components/AgentSetupCompleteBlock';
import FeatureLabel from './_components/FeatureLabel';

export const AgentAutoscalerSetup = (props: Partial<AgentSetupBlockProps>) => {
  const heading = 'Advanced Kubernetes automation';
  const icon = <AutoscalerVisualSvg />;

  if (props.setupComplete) {
    return (
      <AgentSetupCompleteBlock
        heading={heading}
        icon={icon}
        testId="agent-autoscaler-complete"
        {...props}
      />
    );
  }

  return (
    <AgentSetupBlock
      heading={heading}
      description="Optimize cluster performance and reduce costs with intelligent automated scaling"
      icon={icon}
      labelChip={<FeatureLabel isPremium />}
      checkboxTestId="agent-autoscaler-checkbox"
      {...props}
    />
  );
};
