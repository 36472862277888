/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RuntimeV1ListEntryKind = {
    UNKNOWN: 'LIST_ENTRY_KIND_UNKNOWN',
    SHA256: 'LIST_ENTRY_KIND_SHA256',
    IP: 'LIST_ENTRY_KIND_IP',
    CIDR: 'LIST_ENTRY_KIND_CIDR',
    STRING: 'LIST_ENTRY_KIND_STRING'
} as const;

export type RuntimeV1ListEntryKind = typeof RuntimeV1ListEntryKind[keyof typeof RuntimeV1ListEntryKind];



