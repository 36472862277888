import { AxiosError, AxiosResponse } from 'axios';

import { DEMO_CLUSTER_ID, isDemoMode, isSecurityDemoMode } from '@cast/utils';

export const isDemoClusterRequest = (args: unknown[]) => {
  const urlPath = window.location.pathname;

  return (
    args.includes(DEMO_CLUSTER_ID) ||
    urlPath.includes(DEMO_CLUSTER_ID) ||
    isDemoMode() ||
    (urlPath.includes('organization/security') && isSecurityDemoMode())
  );
};

export const resolveDemoClusterRequest = <T>(data: T) => {
  return Promise.resolve({
    data,
  } as AxiosResponse<T>);
};

export const rejectDemoClusterRequest = () =>
  Promise.reject(
    new AxiosError(
      `Demo organization doesn't support this action`,
      '400',
      undefined,
      undefined,
      {
        data: {
          message: `Demo organization doesn't support this action`,
        },
      } as AxiosResponse
    )
  );

export const rejectDemoOrgRequest = () =>
  Promise.reject(
    new AxiosError(
      `Demo org doesn't support this action`,
      '400',
      undefined,
      undefined,
      {
        data: {
          message: `Demo org doesn't support this action`,
        },
      } as AxiosResponse
    )
  );

export const rejectWithErrorMessage = (message: string) =>
  Promise.reject(
    new AxiosError(message, '500', undefined, undefined, {
      data: {
        message,
      },
    } as AxiosResponse)
  );

export const getOrgId = (): string =>
  new URLSearchParams(window.location.search).get('org')!;
