import { alpha, Stack, styled, Typography, useTheme } from '@mui/material';

import { Button, Icons } from '@cast/design-system';
import { K8sProvider } from '@cast/types';

import { CloseableAlert } from 'components/alerts/CloseableAlert';
import { K8sProviderIcon } from 'components/icons';

import { GroupedNotification, NotificationItem } from './types';
import { generateNotificationId } from './utils';

type CriticalNotificationItemProps = {
  notification: GroupedNotification;
  index: number;
  notificationTotal: number;
  isExpanded: boolean;
  setExpanded: (expanded: boolean) => void;
  onClick: (notification: NotificationItem) => void;
  onDismiss: (notification: GroupedNotification) => void;
};

const NotificationRow = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  padding: '8px 12px 8px 40px',
  margin: '0 -44px 0 -40px',

  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[300], 0.3),
  },
}));

export const CriticalNotificationItem = ({
  notification,
  index,
  notificationTotal,
  isExpanded,
  setExpanded,
  onClick,
  onDismiss,
}: CriticalNotificationItemProps) => {
  const { palette } = useTheme();
  const isGroupedNotificationClickable =
    (index === 0 || isExpanded) && notification.items.length === 1;
  const hasMultipleItems = notification.items.length > 1;
  const showNotificationGroup = isExpanded && hasMultipleItems;

  return (
    <CloseableAlert
      closeable={generateNotificationId(notification.id)}
      key={notification.id}
      data-testid={`critical-notification-item-${notification.id}`}
      color="advisory"
      size="small"
      icon={<Icons.Warning color={palette.red[500]} size={20} />}
      onMouseEnter={() => {
        // Trigger expansion when the first item is hovered
        // if there are multiple notifications or if there are multiple items in the group
        if (
          (notificationTotal > 1 || hasMultipleItems) &&
          index === 0 &&
          !isExpanded
        ) {
          setExpanded(true);
        }
      }}
      onClose={(e: React.SyntheticEvent) => {
        e.stopPropagation();
        onDismiss(notification);
      }}
      onClick={
        isGroupedNotificationClickable ? () => onClick(notification) : undefined
      }
      sx={{
        cursor: isGroupedNotificationClickable ? 'pointer' : 'initial',
        '& .MuiAlert-message': {
          width: '100%',
          ...(showNotificationGroup && {
            overflow: 'visible',
            paddingBottom: 0,
          }),
        },
        '& .DSuiAlert-content': { display: 'block' },
        width: '100%',
        alignSelf: 'center',
        transition: 'all 0.2s linear',
        position: 'relative',
        zIndex: notificationTotal - index,
        // Add a shadow to the last expanded notification
        boxShadow:
          isExpanded && index === notificationTotal - 1
            ? '0px 11px 14px 0px rgba(0, 0, 0, 0.10)'
            : undefined,

        ...(!isExpanded && {
          transform: `translateY(-${index * 61}px)`,
          width: `calc(100% - ${index * 16}px)`,
          // Only display the first three notifications in the stack
          opacity: index > 2 ? 0 : 1 - index * 0.2,
          pointerEvents: index > 2 ? 'none' : 'auto',
        }),

        ...(showNotificationGroup && {
          '&.MuiAlert-root': {
            paddingBottom: 0,
          },
        }),
      }}
    >
      <Typography variant="P14B">{notification.name}</Typography>
      <Stack direction="row" gap={4} flexWrap={isExpanded ? 'wrap' : 'nowrap'}>
        <Typography
          variant="P12R"
          color="grey.900"
          sx={
            // Limit the collapsed height of the notification
            !isExpanded
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : undefined
          }
        >
          {notification.message}
          {hasMultipleItems && ' Clusters affected:'}
        </Typography>
        {!hasMultipleItems && (
          <Button variant="text" size="medium">
            Learn more
          </Button>
        )}
      </Stack>
      {showNotificationGroup && (
        <Stack pt={10}>
          {notification.items.map((item) => (
            <NotificationRow
              key={item.id}
              data-testid={`critical-notification-nested-item-${item.id}`}
              direction="row"
              gap={8}
              alignItems="center"
              justifyContent="space-between"
              onClick={() => onClick(item)}
            >
              <Stack direction="row" gap={8} alignItems="center">
                <K8sProviderIcon
                  provider={item.clusterMetadata?.providerType as K8sProvider}
                  size={14}
                />
                <Typography variant="P12M" color="blue.500">
                  {item.clusterMetadata?.name}
                </Typography>
              </Stack>
              <Icons.CaretRight
                weight="bold"
                size={16}
                color={palette.grey[400]}
              />
            </NotificationRow>
          ))}
        </Stack>
      )}
    </CloseableAlert>
  );
};
