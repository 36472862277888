import groupBy from 'lodash/groupBy';

import { Notification } from '@cast/types';

import { isAlertDismissed } from 'components/alerts';

import { GroupedNotification } from './types';

export const generateNotificationId = (id?: string) =>
  `critical-notification-${id}`;

export const groupNotificationsByType = (
  items: Notification[]
): GroupedNotification[] => {
  const groupedNotifications = Object.entries(groupBy(items, 'name')).map(
    ([key, value]) => ({
      id: value.map((item) => item.id).join('-'),
      name: key,
      message: value[0].message,
      items: value,
    })
  );

  return groupedNotifications.filter(
    (item) => !isAlertDismissed(generateNotificationId(item.id))
  );
};
