import { useEffect, useState } from 'react';

import { Cluster } from '@cast/types';

import { useAbility } from 'core/ability';
import { isAKSCluster } from 'types/cluster';

import { useDidClusterBecomeOperable } from './useDidClusterBecomeOperable';
import { useWasCredentialsAssigned } from './useWasCredentialsAssigned';
import { useWasNetworkMetricsEnabled } from './useWasNetworkMetricsEnabled';
import { useWasSecurityEnabled } from './useWasSecurityEnabled';
import { ProductName } from '../types';

type UseWereFeaturesEnabledArgs = {
  cluster: Cluster;
  featureSelection: ProductName[];
  isSubmitted: boolean;
  onSuccess: () => void;
  onError: () => void;
};

export type EnabledFeatures = Partial<Record<ProductName, boolean>>;

export const useWereFeaturesEnabled = ({
  cluster,
  featureSelection,
  isSubmitted,
  onSuccess,
  onError,
}: UseWereFeaturesEnabledArgs) => {
  const canViewNetworkCostMetrics = useAbility().can(
    'view',
    'FeatureOrganizationNetworkCostMetrics'
  );

  const [featuresToMonitor, setFeaturesToMonitor] = useState<EnabledFeatures>(
    {}
  );
  const hasSelectedFeatures = !!Object.keys(featuresToMonitor).length;

  const wasCredentialsAssigned = useWasCredentialsAssigned({
    clusterId: cluster.id,
    onSuccess: () => {
      if (isAKSCluster(cluster)) {
        setFeaturesToMonitor((previousFeatures) => ({
          ...previousFeatures,
          autoscaler: true,
        }));
      }
    },
    onError,
    enabled: isSubmitted && featuresToMonitor.hasOwnProperty('autoscaler'),
  });

  useWasSecurityEnabled({
    clusterId: cluster.id,
    onSuccess: () => {
      setFeaturesToMonitor((previousFeatures) => ({
        ...previousFeatures,
        security: true,
      }));
    },
    onError,
    enabled: isSubmitted && featuresToMonitor.hasOwnProperty('security'),
  });

  useDidClusterBecomeOperable({
    clusterId: cluster.id,
    onSuccess: () => {
      setFeaturesToMonitor((previousFeatures) => ({
        ...previousFeatures,
        autoscaler: true,
      }));
    },
    onError,
    enabled:
      !isAKSCluster(cluster) &&
      isSubmitted &&
      featuresToMonitor.hasOwnProperty('autoscaler') &&
      wasCredentialsAssigned,
  });

  useWasNetworkMetricsEnabled({
    clusterId: cluster.id,
    onSuccess: () => {
      setFeaturesToMonitor((previousFeatures) => ({
        ...previousFeatures,
        networkCostMetrics: true,
      }));
    },
    onError,
    enabled:
      canViewNetworkCostMetrics &&
      isSubmitted &&
      featuresToMonitor.hasOwnProperty('networkCostMetrics'),
  });

  useEffect(() => {
    // Populate the features to monitor based on the user selection
    if (isSubmitted && !hasSelectedFeatures) {
      const features = featureSelection.reduce((acc, feature) => {
        // TODO (Alex): Enablement detection will be implemented in UI-3586
        if (feature === 'gpuMetricsExporter') {
          acc[feature] = true;
        } else {
          acc[feature] = false;
        }

        return acc;
      }, {} as EnabledFeatures);

      setFeaturesToMonitor(features);
    }
  }, [featureSelection, hasSelectedFeatures, isSubmitted]);

  useEffect(() => {
    const allFeaturesEnabled = Object.values(featuresToMonitor).every(Boolean);

    if (isSubmitted && hasSelectedFeatures && allFeaturesEnabled) {
      onSuccess();
    }
  }, [isSubmitted, onSuccess, featuresToMonitor, hasSelectedFeatures]);
};
