import { useCallback, useState } from 'react';

import { Dialog } from '@cast/design-system';
import { Cluster } from '@cast/types';

import { SwipeableViews } from 'components/boxes';
import { ClusterProvider } from 'core/providers';
import { isE2E } from 'utils/isE2E';

import { EnablingSlide } from './_components/EnablingSlide';
import { RunTheScriptSlide } from './_components/RunTheScriptSlide';
import { SuccessSlide } from './_components/SuccessSlide';
import { ErrorSlide } from '../../_components/AssignCredentialsDialogContent/_components/ErrorSlide';
import { useWasSecurityEnabled } from '../../_hooks/useWasSecurityEnabled';

type Props = {
  cluster: Cluster;
  destroy: () => void;
  onEnabled?: () => void;
};

export const AssignSecurityCredentialsDialog = ({
  destroy,
  cluster,
  onEnabled: _onEnabled,
}: Props) => {
  const [activeSlide, setActiveSlide] = useState<
    'runTheScript' | 'enabling' | 'success' | 'error'
  >('runTheScript');

  const onEnabled = useCallback(() => {
    setActiveSlide('success');
    _onEnabled?.();
  }, [_onEnabled]);

  const onError = useCallback(() => {
    setActiveSlide('error');
  }, []);

  useWasSecurityEnabled({
    clusterId: cluster.id,
    onSuccess: onEnabled,
    onError,
    enabled: activeSlide === 'enabling',
  });

  return (
    <Dialog
      open
      onClose={destroy}
      disableCloseOnBackdropClick
      testId="assign-security-credentials-dialog"
    >
      <ClusterProvider cluster={cluster} isLoading={false}>
        <SwipeableViews
          slides={{
            runTheScript: (
              <RunTheScriptSlide onClose={destroy} clusterId={cluster.id} />
            ),
            enabling: <EnablingSlide onClose={destroy} />,
            error: (
              <ErrorSlide
                title="Failed to enable Kubernetes & cloud security"
                onRetry={() => setActiveSlide('runTheScript')}
                onClose={destroy}
              />
            ),
            success: <SuccessSlide onClose={destroy} />,
          }}
          disableAnimations={isE2E()}
          activeSlide={activeSlide}
          onSlideChange={setActiveSlide}
        />
      </ClusterProvider>
    </Dialog>
  );
};
