import uniq from 'lodash/uniq';

import { ClusterDisplayStatus, K8sProvider } from '@cast/types';

export const IS_TILT = window.location.hostname.includes('local.cast.ai');
export const IS_DEV = window.env.VITE_ENV === 'dev-master';
export const IS_PROD_US = window.env.VITE_ENV === 'prod-master';
export const IS_PROD_EU = window.env.VITE_ENV === 'prod-eu';
export const IS_PROD = IS_PROD_US || IS_PROD_EU;
export const ANALYTICS_ENABLED = IS_PROD;

export const SUPPORTS_NODE_CONFIGURATION: K8sProvider[] = [
  K8sProvider.EKS,
  K8sProvider.GKE,
  K8sProvider.AKS,
];

export const SUPPORTS_STORAGE: K8sProvider[] = [
  K8sProvider.GKE,
  K8sProvider.EKS,
];

export const AFFILIATE_ID_COOKIE_KEY = '_cast_refer_id';
export const VISIT_ID_COOKIE_KEY = '_cast_refer_visit';

export const HUBSPOT_COOKIE_KEY = 'hubspotutk';
export const AUTH_PROVIDER_QUERY_KEY = 'auth-provider';
export const REBALANCE_NODES_KEY = 'rebalance-nodes';

export const uniqClusterStatuses = uniq([
  ClusterDisplayStatus.WAITING_CONNECTION,
  ClusterDisplayStatus.READ_ONLY,
  ClusterDisplayStatus.READY,
  ClusterDisplayStatus.PAUSED,
  ClusterDisplayStatus.WARNING,
  ClusterDisplayStatus.NOT_RESPONDING,
  ClusterDisplayStatus.PAUSING,
  ClusterDisplayStatus.RESUMING,
  ClusterDisplayStatus.CONNECTING,
  ClusterDisplayStatus.DISCONNECTING,
  ClusterDisplayStatus.DELETING,
  ClusterDisplayStatus.DELETED,
  ClusterDisplayStatus.FAILED,
  ClusterDisplayStatus.DISCONNECTED,
]);

export const premiumFeaturePaths: string[] = [
  '/external-clusters/:clusterId/autoscaler',
  '/external-clusters/:clusterId/rebalance',
  '/external-clusters/:clusterId/audit',
  '/external-clusters/:clusterId/cost-report/comparison',
];

export const nestedPaths: string[] = [
  '/external-clusters/:clusterId/rebalance/:rebalancingPlanId/*',
  '/external-clusters/:clusterId/cost-report/workloads/compute-cost/:workloadName/:namespace/:workloadType',
  '/external-clusters/:clusterId/cost-report/workloads/network-cost/:workloadName/:namespace/:workloadType',
  '/external-clusters/:clusterId/cost-report/workloads/efficiency/:workloadName/:namespace/:workloadType',
];

export const MOST_EXPENSIVE_ITEM_COLOR_MAP = {
  insignificant: 'grey.200',
  colorByUsageRank: [
    'blue.700',
    'aqua.600',
    'indigo.100',
    'yellow.200',
    'green.300',
  ],
};

export const drainFailedAnnotation = 'rebalancing.cast.ai/status=drain-failed';
