import { useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { useLocation } from 'react-router-dom';

import { CAN_VIEW_SECURITY } from '@cast/console/constants';
import { Cluster } from '@cast/types';
import { isManagedByTerraform, usePrevious } from '@cast/utils';

import { SwipeableViews } from 'components/boxes';
import { ClusterProvider } from 'core/providers';
import { isE2E } from 'utils/isE2E';

import { AssignCredentialsDialogSuccessSlide } from './_components/AssignCredentialsDialogSuccessSlide';
import { AssignCredentialsSetupSlide } from './_components/AssignCredentialsSetupSlide';
import { EnablingCastAiSlide } from './_components/EnablingCastAiSlide';
import { ErrorSlide } from './_components/ErrorSlide';
import { TerraformClusterSlide } from './_components/TerraformClusterSlide';
import { AssignCredentialsDialogContext } from './context';
import { AssignCredentialsDialogSlide } from './types';
import { generateRedirectPath } from './utils';
import { useNavigate } from '../../../../useNavigate';
import { useActiveFeatures } from '../../_hooks/useActiveFeatures';
import { useWereFeaturesEnabled } from '../../_hooks/useWereFeaturesEnabled';
import { ExtendedSettings, ProductFeatures, ProductName } from '../../types';

type Props = {
  cluster: Cluster;
  destroy: () => void;
  onSlideChange?: (slide: AssignCredentialsDialogSlide) => void;
};

export const AssignCredentialsDialogContent = ({
  destroy,
  cluster,
  onSlideChange,
}: Props) => {
  const [activeSlideState, setActiveSlideState] =
    useState<AssignCredentialsDialogSlide>('setup');
  const location = useLocation();
  const navigate = useNavigate();
  const activeFeatures = useActiveFeatures({
    cluster,
    enabled: activeSlideState === 'setup',
  });
  const prevActiveFeatures = usePrevious(activeFeatures);
  const [includeAutoscaler, setIncludeAutoscaler] = useState(true);
  const [includeSecurity, setIncludeSecurity] = useState(CAN_VIEW_SECURITY());
  const [extendedSettings, setExtendedSettings] = useState<ExtendedSettings>({
    includeGpuPlugin: false,
    includeNetworkCosts: false,
    includeGpuExporter: false,
  });
  // Selected features which have not already been enabled
  const currentSelection = useMemo(() => {
    const selectedFeatures = pickBy(
      {
        autoscaler: includeAutoscaler,
        security: includeSecurity,
        networkCostMetrics: extendedSettings.includeNetworkCosts,
        gpuMetricsExporter: extendedSettings.includeGpuExporter,
      },
      (value, key) =>
        value && !isEqual(value, activeFeatures[key as keyof ProductFeatures])
    );

    return Object.keys(selectedFeatures) as ProductName[];
  }, [
    activeFeatures,
    extendedSettings.includeGpuExporter,
    extendedSettings.includeNetworkCosts,
    includeAutoscaler,
    includeSecurity,
  ]);
  // A snapshot of the selected features when user proceeds to next step
  const [selectionSnapshot, setSelectionSnapshot] = useState<ProductName[]>([]);

  // Update the default form values based on the already enabled features
  useEffect(() => {
    if (
      activeSlideState === 'setup' &&
      !isEqual(activeFeatures, prevActiveFeatures)
    ) {
      setExtendedSettings({
        ...extendedSettings,
        includeNetworkCosts: activeFeatures.networkCostMetrics,
        includeGpuExporter: activeFeatures.gpuMetricsExporter,
      });
    }
  }, [activeFeatures, activeSlideState, extendedSettings, prevActiveFeatures]);

  const setActiveSlide = useCallback(
    (slide: AssignCredentialsDialogSlide) => {
      if (slide === 'success') {
        const isInSecurityPage = location.pathname.includes(
          'organization/security'
        );
        const redirectPath = generateRedirectPath({
          featureSelection: selectionSnapshot,
          cluster,
          isInSecurityPage,
        });

        if (redirectPath && redirectPath !== location.pathname) {
          navigate({ pathname: redirectPath });
        }
      }

      setActiveSlideState(slide);
      onSlideChange?.(slide);
    },
    [cluster, location.pathname, navigate, onSlideChange, selectionSnapshot]
  );

  const onError = useCallback(() => {
    setActiveSlide('error');
  }, [setActiveSlide]);

  useWereFeaturesEnabled({
    cluster,
    featureSelection: selectionSnapshot,
    isSubmitted: activeSlideState === 'inProgress',
    onSuccess: () => {
      setActiveSlide('success');
    },
    onError,
  });

  return (
    <AssignCredentialsDialogContext.Provider
      value={{
        dismiss: destroy,
        setActiveSlide,
        includeAutoscaler,
        setIncludeAutoscaler,
        includeSecurity,
        setIncludeSecurity,
        extendedSettings,
        setExtendedSettings,
        activeFeatures,
      }}
    >
      <ClusterProvider cluster={cluster} isLoading={false}>
        {isManagedByTerraform(cluster.managedBy) ? (
          <TerraformClusterSlide />
        ) : (
          <SwipeableViews
            slides={{
              setup: (
                <AssignCredentialsSetupSlide
                  hasSelection={currentSelection.length > 0}
                  onSubmit={() => setSelectionSnapshot(currentSelection)}
                />
              ),
              inProgress: <EnablingCastAiSlide />,
              error: (
                <ErrorSlide
                  title="Failed to enable CAST AI features"
                  testId="enabling-features-failed"
                  onRetry={() => setActiveSlide('setup')}
                  onClose={destroy}
                />
              ),
              success: (
                <AssignCredentialsDialogSuccessSlide cluster={cluster} />
              ),
            }}
            activeSlide={activeSlideState}
            disableAnimations={isE2E()}
            data-testid="assign-credentials-dialog-content"
          />
        )}
      </ClusterProvider>
    </AssignCredentialsDialogContext.Provider>
  );
};
