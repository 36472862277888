import { organizationHeader, organizationQueryKey } from '@cast/constants';
import { client } from '@cast/core';

import { getQueryParam } from './url';

export const DEMO_CLUSTER_ID = '11111111-1111-1111-1111-111111111111';

export const isDemoCluster = (id: string) => {
  return id === DEMO_CLUSTER_ID;
};

const getDemoKey = (orgId: string) => `demo-mode-${orgId}`;

export const setDemoMode = (orgId?: string | null) => {
  if (!orgId) {
    orgId = new URLSearchParams(window.location.search).get('org');
  }

  if (orgId) {
    sessionStorage.setItem(getDemoKey(orgId), 'true');
  }
};

export const removeDemoMode = () => {
  const orgId = new URLSearchParams(window.location.search).get('org');

  if (orgId) {
    sessionStorage.removeItem(getDemoKey(orgId));
  }
};

export const getOrgId = () => {
  return (
    getQueryParam(organizationQueryKey) ??
    (client.defaults.headers.common[organizationHeader] as string) ??
    sessionStorage.getItem(organizationQueryKey) ??
    localStorage.getItem(organizationQueryKey)
  );
};

export const isDemoMode = () => {
  const orgId = getOrgId();
  if (orgId) {
    return Boolean(sessionStorage.getItem(getDemoKey(orgId)));
  }
  return false;
};
