import {
  Fragment,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AutocompleteGroupedOption, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import sum from 'lodash/sum';
import { bindToggle } from 'material-ui-popup-state/core';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';

import { mergeSx } from '../../../../utils';
import {
  List as DSList,
  ListItem as DsListItem,
  ListItemText,
} from '../../../lists';
import { VirtuosoScroller } from '../../../scroller';
import Scroller from '../../../scroller/Scroller';
import Checkbox from '../../checkbox/Checkbox';
import { DropdownContext } from '../DropdownContext';
import { DropdownProps } from '../types';

const checkAllOption = { isCheckAll: true };

const StyledList = styled(Scroller, {
  name: 'DsDropdown',
  slot: 'List',
  target: 'DsDropdown-List',
  overridesResolver: (_, style) => {
    return [style.list];
  },
})({});
StyledList.displayName = 'StyledList';

type ListItemStartAdornmentProps = {
  checkable?: boolean;
  optionIcon?: ReactNode;
  selected: boolean;
  disabled?: boolean;
  size?: DropdownProps['size'];
};
const ListItemStartAdornment = ({
  checkable,
  optionIcon,
  selected,
  disabled,
  size,
}: ListItemStartAdornmentProps) => {
  if (!checkable && !optionIcon) {
    return null;
  }
  if (!checkable && optionIcon) {
    return <>{optionIcon}</>;
  }
  if (checkable && !optionIcon) {
    return (
      <Checkbox
        checked={selected}
        size={size === 'large' ? 'medium' : size}
        disabled={disabled}
      />
    );
  }
  return null;
};

type ListItemProps<T = unknown> = {
  option: T;
  index: number;
  isSubItem?: boolean;
  testId?: string;
};
const ListItem = ({ option, index, isSubItem, testId }: ListItemProps) => {
  const {
    dropdownProps,
    popupState,
    autocomplete: {
      getters: { getOptionProps, getOptionIcon, isOptionSelected },
    },
  } = useContext(DropdownContext);

  const { onClick, label, ...rest } = getOptionProps({
    option,
    index,
  });

  const checkable =
    'checkable' in dropdownProps ? dropdownProps.checkable : false;
  const isDisabled = dropdownProps.optionDisabled?.(option) || false;
  const isSelected = isOptionSelected(option);

  const classes = clsx(
    'DS-Option',
    isSelected && 'DS-Option-selected',
    checkable && 'DS-Option-checkable',
    isSubItem && 'DS-Option-subItem'
  );

  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      if (!dropdownProps.multiple) {
        bindToggle(popupState).onClick(e);
      }

      onClick?.(e);
    },
    [dropdownProps.multiple, onClick, popupState]
  );

  if (dropdownProps.renderOption && !checkable) {
    return dropdownProps.renderOption(
      option,
      { className: classes, onClick: handleOnClick, testId, ...rest },
      isSelected
    );
  }

  return (
    <li
      {...rest}
      // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
      role="option"
      onClick={!isDisabled ? handleOnClick : undefined}
      data-testid={testId}
    >
      <DsListItem
        className={classes}
        selected={isSelected}
        disabled={isDisabled}
        startAdornment={
          <ListItemStartAdornment
            checkable={checkable}
            optionIcon={getOptionIcon?.(option)}
            selected={isSelected}
            disabled={isDisabled}
          />
        }
      >
        {dropdownProps.renderOption ? (
          dropdownProps.renderOption(
            option,
            {
              className: classes,
              onClick: !isDisabled ? handleOnClick : undefined,
              label,
              ...rest,
            },
            isSelected
          )
        ) : (
          <ListItemText
            primary={label}
            primaryTypographyProps={{ sx: { wordBreak: 'break-all' } }}
          />
        )}
      </DsListItem>
    </li>
  );
};

const SelectAllListItem = () => {
  const {
    dropdownProps: { size },
    autocomplete: {
      states: { value, options },
      actions: { selectAll, clearValue },
    },
  } = useContext(DropdownContext);
  const selectedCount = Array.isArray(value) ? value.length : 0;
  const total = options.length;
  const allChecked = total === selectedCount;
  const indeterminate = selectedCount > 0 && selectedCount < total;
  const isSelected = allChecked || indeterminate;

  const classes = clsx(
    isSelected && 'DS-Option-selected',
    'DS-Option-checkable'
  );

  const handleSelectAll = () => {
    if (isSelected) {
      clearValue();
    } else {
      selectAll();
    }
  };

  return (
    <li onClick={handleSelectAll} data-testid="dropdown-list-select-all">
      <DsListItem
        className={classes}
        selected={isSelected}
        startAdornment={
          <Checkbox
            checked={isSelected}
            indeterminate={indeterminate}
            size={size === 'large' ? 'medium' : size}
          />
        }
      >
        <ListItemText primary="Select all" />
      </DsListItem>
    </li>
  );
};

const List = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const { dropdownProps, popupState } = useContext(DropdownContext);

  return (
    <StyledList
      {...dropdownProps.listAttributes}
      className={clsx(
        'DS-DropdownList-root',
        className,
        dropdownProps.listAttributes?.className
      )}
      sx={mergeSx(dropdownProps.listSx)}
      data-testid={`${dropdownProps.testId || 'dropdown'}-list`}
    >
      <DSList
        className="DS-DropdownList-list"
        sx={{
          width: '100%',
          minWidth: popupState.anchorEl?.offsetWidth,
          maxHeight: dropdownProps.listHeight ?? 400,
        }}
        size={dropdownProps.size}
        component="div"
        disablePadding
      >
        {children}
      </DSList>
    </StyledList>
  );
};

type SimpleListProps<T = unknown> = {
  options: T[];
};
const SimpleList = ({ options }: SimpleListProps) => {
  const {
    dropdownProps,
    popupState,
    autocomplete: {
      getters: { getOptionKey },
      popoverActionsRef,
    },
  } = useContext(DropdownContext);
  const { virtualization, virtualizationProps, multiple } = dropdownProps;
  const checkable =
    'checkable' in dropdownProps ? dropdownProps.checkable : false;
  const showSelectAll =
    'checkable' in dropdownProps &&
    (dropdownProps.showSelectAll != null
      ? dropdownProps.showSelectAll
      : true) &&
    multiple &&
    checkable;
  const maxHeight = virtualizationProps?.maxHeight ?? 400;
  const [height, setHeight] = useState<number>(maxHeight);
  const handleListHeight = useCallback((h: number) => {
    setHeight(h);
  }, []);

  useEffect(() => {
    popoverActionsRef.current?.updatePosition?.();
  }, [height, maxHeight, popoverActionsRef]);

  if (!virtualization) {
    return (
      <List data-testid="dropdown-list">
        {showSelectAll && <SelectAllListItem />}
        {options.map((option, index) => {
          return (
            <ListItem
              key={getOptionKey(option)}
              option={option}
              index={index}
              testId={'dropdown-list-item-' + index}
            />
          );
        })}
      </List>
    );
  }

  const virtuosoOptions = showSelectAll
    ? [checkAllOption, ...options]
    : options;

  return (
    <List data-testid="dropdown-list">
      <Virtuoso
        totalListHeightChanged={handleListHeight}
        data={virtuosoOptions}
        style={{
          maxHeight,
          height: height || maxHeight,
          minWidth:
            virtualizationProps?.minWidth || popupState.anchorEl?.offsetWidth,
          maxWidth: virtualizationProps?.maxWidth,
        }}
        itemContent={(index) => {
          const option = virtuosoOptions[index];
          if (option === checkAllOption) {
            return <SelectAllListItem />;
          }
          index = showSelectAll ? index - 1 : index;
          return (
            <ListItem
              key={getOptionKey(option)}
              option={option}
              index={index}
              testId={'dropdown-list-item-' + index}
            />
          );
        }}
        components={{
          Scroller: VirtuosoScroller,
        }}
        overscan={maxHeight}
      />
    </List>
  );
};

type GroupableProps<T extends unknown> = {
  data: T[];
  groups: string[];
  groupCounts: number[];
};

type GroupedListWithVirtualization<T extends unknown> = {
  virtualization: true;
  groupableProps: GroupableProps<T>;
};

type GroupedListWithNoVirtualization<T extends unknown> = {
  virtualization: false | undefined;
  groupedOptions: AutocompleteGroupedOption<T>[];
};

type GroupedListProps<T extends unknown> =
  | GroupedListWithVirtualization<T>
  | GroupedListWithNoVirtualization<T>;

const isVirtualization = <T extends unknown>(
  props: GroupedListProps<T>
): props is GroupedListWithVirtualization<T> => {
  return !!props.virtualization;
};

type DropdownListSubheaderProps<T = unknown> = {
  groupOptions: T[];
  children: string;
};

const DropdownListSubheader = <T extends unknown>({
  children,
  groupOptions,
}: DropdownListSubheaderProps) => {
  const {
    dropdownProps,
    autocomplete: {
      getters: { isOptionEqualToValue },
      states: { value },
      actions: { select },
    },
  } = useContext(DropdownContext);
  const { size } = dropdownProps;
  const checkable =
    'checkable' in dropdownProps ? dropdownProps.checkable : false;
  const multiple = 'multiple' in dropdownProps ? dropdownProps.multiple : false;
  const selectedGroupOptions = useMemo(() => {
    if (checkable && multiple) {
      return ((value as T[]) || []).filter((v) =>
        groupOptions.some((o) => isOptionEqualToValue(o, v))
      );
    }
    const selectedOption = groupOptions.find((o) =>
      isOptionEqualToValue(o, value)
    );
    return selectedOption ? [selectedOption] : [];
  }, [checkable, groupOptions, isOptionEqualToValue, multiple, value]);
  const selectedCount = selectedGroupOptions.length;
  const total = groupOptions.length;
  const allChecked = total === selectedCount;
  const indeterminate = selectedCount > 0 && selectedCount < total;
  const isSelected = allChecked || indeterminate;

  const classes = clsx(
    isSelected && 'DS-Option-selected',
    checkable && 'DS-Option-checkable'
  );

  return (
    <li
      onClick={
        checkable ? () => select(groupOptions, !selectedCount) : undefined
      }
      data-testid={`dropdown-list-select-group-${children}`}
    >
      <DsListItem
        className={classes}
        selected={isSelected}
        startAdornment={
          checkable && (
            <Checkbox
              checked={isSelected}
              indeterminate={indeterminate}
              size={size === 'large' ? 'medium' : size}
            />
          )
        }
      >
        <Typography variant="L10B" color="grey.400">
          {children}
        </Typography>
      </DsListItem>
    </li>
  );
};

const GroupedList = <T extends unknown>(props: GroupedListProps<T>) => {
  const {
    dropdownProps,
    popupState,
    autocomplete: {
      getters: { getOptionLabel },
      popoverActionsRef,
    },
  } = useContext(DropdownContext);

  const { virtualizationProps, optionKey, multiple } = dropdownProps;

  const checkable =
    'checkable' in dropdownProps ? dropdownProps.checkable : false;
  const showSelectAll = checkable && multiple; //

  const height = virtualizationProps?.maxHeight ?? 400;

  useEffect(() => {
    popoverActionsRef.current?.updatePosition?.();
  }, [height, popoverActionsRef]);

  if (!isVirtualization(props)) {
    return (
      <List className="DS-DropdownList-grouped" data-testid="dropdown-list">
        {showSelectAll && <SelectAllListItem />}
        {props.groupedOptions.map(({ group, options, index }, groupIndex) => {
          return (
            <Fragment key={group}>
              {!!group.length && (
                <DropdownListSubheader groupOptions={options}>
                  {group}
                </DropdownListSubheader>
              )}
              {options.map((option, optionIndex) => (
                <ListItem
                  key={
                    typeof optionKey === 'function'
                      ? optionKey(option)
                      : `${getOptionLabel(option)}_${group}`
                  }
                  option={option}
                  index={index + optionIndex}
                  testId={
                    'dropdown-list-item-' + groupIndex + '_' + optionIndex
                  }
                  isSubItem
                />
              ))}
            </Fragment>
          );
        })}
      </List>
    );
  }

  const {
    groupableProps: { groupCounts, data, groups },
  } = props;

  return (
    <List className="DS-DropdownList-grouped" data-testid="dropdown-list">
      <GroupedVirtuoso
        style={{
          height,
          minWidth:
            virtualizationProps?.minWidth || popupState.anchorEl?.offsetWidth,
          maxWidth: virtualizationProps?.maxWidth,
        }}
        groupCounts={groupCounts}
        groupContent={(index) => {
          const startGroupIndex = sum(groupCounts.slice(0, index));
          return (
            <DropdownListSubheader
              groupOptions={data.slice(
                startGroupIndex,
                startGroupIndex + groupCounts[index]
              )}
            >
              {groups[index]}
            </DropdownListSubheader>
          );
        }}
        itemContent={(optionIndex, groupIndex) => (
          <ListItem
            key={`${getOptionLabel(data[optionIndex])}_${groups[groupIndex]}`}
            option={data[optionIndex]}
            index={groupIndex + optionIndex}
            testId={'dropdown-list-item-' + groupIndex + '_' + optionIndex}
            isSubItem
          />
        )}
        components={{
          Scroller: VirtuosoScroller,
        }}
        increaseViewportBy={height}
      />
    </List>
  );
};

export const DropdownList = <T extends unknown>() => {
  const {
    dropdownProps,
    autocomplete: {
      states: { searchInputValue, allowSearch, groupedOptions, options },
    },
  } = useContext(DropdownContext);

  const isGrouped = !!dropdownProps.groupBy;
  const _options = isGrouped ? groupedOptions : options;

  const groupableProps: GroupableProps<T> | undefined = useMemo(() => {
    if (!isGrouped || !dropdownProps.virtualization) {
      return undefined;
    }
    const data = (_options as AutocompleteGroupedOption<T>[]).reduce(
      (acc, group) => [...acc, ...group.options],
      [] as T[]
    );
    const { groups, groupCounts } = (
      _options as AutocompleteGroupedOption<T>[]
    ).reduce(
      (acc, { group, options }) => ({
        groups: [...acc.groups, group],
        groupCounts: [...acc.groupCounts, options.length],
      }),
      { groups: [], groupCounts: [] } as {
        groups: string[];
        groupCounts: number[];
      }
    );

    return { data, groups, groupCounts };
  }, [_options, isGrouped, dropdownProps.virtualization]);

  if (allowSearch && searchInputValue && !_options.length) {
    return (
      <List>
        <DsListItem>
          <ListItemText>No items found</ListItemText>
        </DsListItem>
      </List>
    );
  }

  if (isGrouped) {
    return (
      <GroupedList
        {...(dropdownProps.virtualization
          ? { virtualization: true, groupableProps: groupableProps! }
          : { virtualization: false, groupedOptions: _options })}
      />
    );
  }

  return <SimpleList options={_options} />;
};
