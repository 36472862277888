import { useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { TZDate } from 'react-day-picker';

import { InfiniteDayPicker } from '../../components/InfiniteDayPicker';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { DatePeriod, RangePickerProviderProps } from '../../types';
import {
  buildDisabledMatcher,
  dateToInputString,
  dayjsToTZDate,
  rangeToUtc,
  shouldHideHoverTrail,
} from '../../utils';

export const RangeInfinitePicker = () => {
  const ctx = useDatePickerContext<RangePickerProviderProps>();

  const [hoveredDay, setHoveredDay] = useState<TZDate | undefined>();

  const disabled = useMemo(() => {
    const minDate =
      typeof ctx.minDate === 'function'
        ? ctx.minDate({
            range: rangeToUtc(ctx.selected),
            timezone: ctx.timezone,
          })
        : ctx.minDate;

    const maxDate =
      typeof ctx.maxDate === 'function'
        ? ctx.maxDate({
            range: rangeToUtc(ctx.selected),
            timezone: ctx.timezone,
          })
        : ctx.maxDate;

    return buildDisabledMatcher(minDate, maxDate, ctx.timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.selected, ctx.timezone]);

  const startMonth = useMemo(() => {
    const startMonth =
      typeof ctx.startMonth === 'function'
        ? ctx.startMonth({
            range: rangeToUtc(ctx.selected),
            timezone: ctx.timezone,
          })
        : ctx.startMonth;

    if (startMonth) {
      return new TZDate(startMonth, ctx.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.selected, ctx.timezone]);

  const endMonth = useMemo(() => {
    const endMonth =
      typeof ctx.endMonth === 'function'
        ? ctx.endMonth({
            range: rangeToUtc(ctx.selected),
            timezone: ctx.timezone,
          })
        : ctx.endMonth;

    if (endMonth) {
      return new TZDate(endMonth, ctx.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.selected, ctx.timezone]);

  const handleDayHoverStart = (day: TZDate) => {
    const hovered = dateToInputString(day, ctx.format, ctx.timezone);
    setHoveredDay(day);

    if (ctx.selected?.from) {
      ctx.setEndPlaceholder(hovered);
    } else {
      ctx.setStartPlaceholder(hovered);
    }
  };

  const handleDayHoverEnd = () => {
    setHoveredDay(undefined);
    ctx.setStartPlaceholder('');
    ctx.setEndPlaceholder('');
  };

  const handleOnRangeChange = (
    dateRange: DatePeriod | undefined,
    triggerDate: TZDate
  ) => {
    const tzTriggerDate = dayjs.tz(triggerDate, ctx.timezone);
    if (
      (!ctx.selected.from && !ctx.selected.to) ||
      (ctx.selected.from && ctx.selected.to)
    ) {
      // if no range or full range was selected - start from beginning
      ctx.setSelected({
        from: dayjsToTZDate(tzTriggerDate.startOf('day'), ctx.timezone),
        to: undefined,
      });
    } else if (
      ctx.selected.from &&
      !ctx.selected.to &&
      !dateRange?.from &&
      !dateRange?.to
    ) {
      // if clicked again on the same date set full range
      ctx.setSelected({
        from: dayjsToTZDate(tzTriggerDate.startOf('day'), ctx.timezone),
        to: dayjsToTZDate(tzTriggerDate.endOf('day'), ctx.timezone),
      });
    } else {
      ctx.setSelected({
        from: dateRange?.from
          ? dayjsToTZDate(
              dayjs.tz(dateRange.from, ctx.timezone).startOf('day'),
              ctx.timezone
            )
          : dateRange?.from,
        to: dateRange?.to
          ? dayjsToTZDate(
              dayjs.tz(dateRange.to, ctx.timezone).endOf('day'),
              ctx.timezone
            )
          : dateRange?.to,
      });
    }
  };

  const buildHoverTrailMatcher = () => {
    const hideTrail = shouldHideHoverTrail(
      ctx.selected,
      hoveredDay,
      ctx.maxNumberOfDays
    );

    if (hideTrail || !ctx.selected?.from || !hoveredDay) {
      return false;
    }

    if (dayjs(ctx.selected.from).isAfter(hoveredDay)) {
      return { before: ctx.selected.from, after: hoveredDay };
    }

    return { before: hoveredDay, after: ctx.selected.from };
  };

  const buildHoverTrailEndMatcher = () => {
    const hideTrail = shouldHideHoverTrail(
      ctx.selected,
      hoveredDay,
      ctx.maxNumberOfDays
    );

    if (hideTrail) {
      return false;
    }

    return hoveredDay;
  };

  const buildHoverTrailStartMatcher = () => {
    const hideTrail = shouldHideHoverTrail(
      ctx.selected,
      hoveredDay,
      ctx.maxNumberOfDays
    );

    if (hideTrail) {
      return false;
    }

    return ctx.selected.from;
  };

  const getModifierClassName = () => {
    if (!ctx.selected.from || !hoveredDay) {
      return '';
    }
    return dayjs(ctx.selected.from).isAfter(hoveredDay) ? 'up' : 'down';
  };

  return (
    <InfiniteDayPicker
      dayPickerProps={{
        mode: ctx.mode,
        selected: { from: ctx.selected?.from, to: ctx.selected?.to },
        onSelect: (range, triggerDate) =>
          handleOnRangeChange(range as DatePeriod, triggerDate as TZDate),
        max:
          ctx.maxNumberOfDays && ctx.maxNumberOfDays > 1
            ? ctx.maxNumberOfDays - 1
            : ctx.maxNumberOfDays,
        disabled,
        onDayMouseEnter: (day) => handleDayHoverStart(day as TZDate),
        onDayMouseLeave: handleDayHoverEnd,
        modifiers: {
          firstOfMonth: (day) =>
            dayjs
              .tz(day, ctx.timezone)
              .isSame(dayjs.tz(day, ctx.timezone).startOf('month')),
          lastOfMonth: (day) =>
            dayjs
              .tz(day, ctx.timezone)
              .isSame(
                dayjs.tz(day, ctx.timezone).endOf('month').startOf('day')
              ),
          hoverTrail: buildHoverTrailMatcher(),
          hoverTrailEnd: buildHoverTrailEndMatcher(),
          hoverTrailStart: buildHoverTrailStartMatcher(),
        },
        modifiersClassNames: {
          firstOfMonth: 'first-month-day',
          lastOfMonth: 'last-month-day',
          hoverTrail: 'hover-trail',
          hoverTrailStart: `hover-trail-start-${getModifierClassName()}`,
          hoverTrailEnd: `hover-trail-end-${getModifierClassName()}`,
        },
      }}
      initialMonth={ctx.initialMonth}
      endMonth={endMonth}
      startMonth={startMonth}
      ref={ctx.setPickerRef}
    />
  );
};
