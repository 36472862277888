import { SecurityFeature } from '@cast/types';

import { useSingleClusterSettingsQuery } from 'hooks/queries/security-insights';

import { useWasEnabled } from './useWasEnabled';
import { OnboardingPhase2HookProps } from '../types';

export const useWasNetworkMetricsEnabled = ({
  clusterId,
  onSuccess,
  onError,
  enabled,
}: OnboardingPhase2HookProps) => {
  const { settings, error } = useSingleClusterSettingsQuery({
    clusterId,
    refetchInterval: enabled ? 20_000 : false,
    enabled,
  });

  const isNetworkMetricsEnabled = Boolean(
    settings?.features?.includes(SecurityFeature.NETFLOW)
  );

  useWasEnabled({
    currentState: isNetworkMetricsEnabled,
    onSuccess: onSuccess,
    onError: onError,
    error,
    enabled,
  });

  return isNetworkMetricsEnabled;
};
