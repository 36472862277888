import { AxiosInstance } from 'axios';

import {
  AllocationGroupAPIApiFactory,
  WorkloadReportAPIApiFactory,
  ClusterReportAPIApiFactory,
  ReportConfigurationAPIApiFactory,
  NamespaceReportAPIApiFactory,
  PlatformImpactReportAPIApiFactory,
} from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

// prettier-ignore
export const CostReportApiAdapter = (client: AxiosInstance) => {
  const generatedClusterReportApi = ClusterReportAPIApiFactory(AXIOS_CONFIGURATION, '', client);
  const generatedAllocationGroupsApi = AllocationGroupAPIApiFactory(AXIOS_CONFIGURATION, '', client);
  const generatedWorkloadsApi = WorkloadReportAPIApiFactory(AXIOS_CONFIGURATION, '', client);
  const generatedNamespaceApi = NamespaceReportAPIApiFactory(AXIOS_CONFIGURATION, '', client);
  const generatedReportConfigurationApi = ReportConfigurationAPIApiFactory(AXIOS_CONFIGURATION, '', client);
  const platformImpactReportApi = PlatformImpactReportAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getAllocationGroups: generatedAllocationGroupsApi.allocationGroupAPIListAllocationGroups,
    createAllocationGroup: generatedAllocationGroupsApi.allocationGroupAPICreateAllocationGroup,
    updateAllocationGroup: generatedAllocationGroupsApi.allocationGroupAPIUpdateAllocationGroup,
    deleteAllocationGroup: generatedAllocationGroupsApi.allocationGroupAPIDeleteAllocationGroup,
    getAllocationGroupSummaries: generatedAllocationGroupsApi.allocationGroupAPIGetAllocationGroupCostSummaries,
    getAllocationGroupTimedSummaries: generatedAllocationGroupsApi.allocationGroupAPIGetAllocationGroupCostTimedSummaries,
    getAllocationGroupTotalCosts: generatedAllocationGroupsApi.allocationGroupAPIGetAllocationGroupTotalCostTimed,
    getAllocationGroupDataTransferSummary: generatedAllocationGroupsApi.allocationGroupAPIGetCostAllocationGroupDataTransferSummary,
    getAllocationGroupWorkloads: generatedAllocationGroupsApi.allocationGroupAPIGetAllocationGroupWorkloadCosts,
    getAllocationGroupDataTransferWorkloads: generatedAllocationGroupsApi.allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads,
    getAllocationGroupEfficiencySummary:generatedAllocationGroupsApi.allocationGroupAPIGetAllocationGroupEfficiencySummary,
    getAllocationGroupWorkloadsEfficiency:generatedAllocationGroupsApi.allocationGroupAPIGetAllocationGroupWorkloadsEfficiency,

    getSingleWorkloadCostReport: generatedWorkloadsApi.workloadReportAPIGetSingleWorkloadCostReport,
    getWorkloadLabelNames: generatedWorkloadsApi.workloadReportAPIGetWorkloadLabelNames,
    getNamespaces: generatedWorkloadsApi.workloadReportAPIGetNamespacesForClusters,
    getWorkloadsMetadata: generatedWorkloadsApi.workloadReportAPIGetWorkloadsMetadata,
    getWorkloadLabelValues: generatedWorkloadsApi.workloadReportAPIGetWorkloadLabelValues,
    getWorkloadDataTransferCost: generatedWorkloadsApi.workloadReportAPIGetSingleWorkloadDataTransferCost,
    getRightsizingPatchScript: generatedWorkloadsApi.workloadReportAPIGetClusterWorkloadRightsizingPatch,
    getDataTransferCost: generatedWorkloadsApi.workloadReportAPIGetWorkloadDataTransferCost2,
    getWorkloadEfficiencyDetails: generatedWorkloadsApi.workloadReportAPIGetClusterWorkloadEfficiencyReportByName,
    getWorkloadEfficiency: generatedWorkloadsApi.workloadReportAPIGetClusterWorkloadEfficiencyReport2,
    getWorkloadsCostReport: generatedWorkloadsApi.workloadReportAPIGetClusterWorkloadReport2,
    getWorkloadsCostSummaries: generatedWorkloadsApi.workloadReportAPIGetWorkloadCostSummaries,
    getWorkloadTrafficDestinations: generatedWorkloadsApi.workloadReportAPIGetWorkloadTrafficDestinations,
    getWorkloadTrafficDestinationsHistory: generatedWorkloadsApi.workloadReportAPIGetWorkloadTrafficDestinationsHistory,
    getWorkloadsGpuUtilization: generatedWorkloadsApi.workloadReportAPIGetWorkloadsGPUUtilization,
    getWorkloadsGpuUtilizationSummary: generatedWorkloadsApi.workloadReportAPIGetWorkloadsGPUSummary,
    getWorkloadsWastedGPUCostImpact: generatedWorkloadsApi.workloadReportAPIGetWorkloadsWastedGPUCostImpact,
    getWorkloadGpuUtilizationSummary: generatedWorkloadsApi.workloadReportAPIGetSingleWorkloadGPUSummary,

    getNamespace: generatedNamespaceApi.namespaceReportAPIGetSingleNamespaceCostReport,
    getNamespacesCostSummary: generatedNamespaceApi.namespaceReportAPIGetClusterNamespaceCostReportSummaries,
    getNamespacesTotalCosts: generatedNamespaceApi.namespaceReportAPIGetClusterNamespaceCostReport,
    getNamespacesDataTransferCostSummary: generatedNamespaceApi.namespaceReportAPIGetClusterNamespaceDataTransferCost,
    getNamespacesDataTransferTotalCosts: generatedNamespaceApi.namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost,
    

    getOrganizationClustersReport: generatedClusterReportApi.clusterReportAPIGetOrganizationClustersCostReport,
    getOrganizationClustersSummary: generatedClusterReportApi.clusterReportAPIGetClustersSummary,
    getOrganizationClustersDailyCosts: generatedClusterReportApi.clusterReportAPIGetClustersCostReport,
    getOrganizationClustersEfficiencySummary: generatedClusterReportApi.clusterReportAPIGetOrganizationEfficiencySummary,
    getOrganizationClustersTimedEfficiency: generatedClusterReportApi.clusterReportAPIGetOrganizationEfficiencyReport,
    getOrganizationClustersEfficiencyByCluster: generatedClusterReportApi.clusterReportAPIGetOrganizationClustersEfficiencySummary,

    getEstimatedSavings: generatedClusterReportApi.clusterReportAPIGetSavingsRecommendation,
    getRightsizingSummary: generatedClusterReportApi.clusterReportAPIGetRightsizingSummary,
    getCostHistory: generatedClusterReportApi.clusterReportAPIGetClusterCostHistory,
    getCostReport: generatedClusterReportApi.clusterReportAPIGetClusterCostReport,
    getClusterSummary: generatedClusterReportApi.clusterReportAPIGetClusterSummary,
    getClusterEfficiencyDetails: generatedClusterReportApi.clusterReportAPIGetClusterEfficiencyReport,
    getResourceUsage: generatedClusterReportApi.clusterReportAPIGetClusterResourceUsage,
    getUnscheduledPods: generatedClusterReportApi.clusterReportAPIGetClusterUnscheduledPods,
    getSavingsProgress: generatedClusterReportApi.clusterReportAPIGetClusterSavingsReport,
    getClusterAnomalies: generatedClusterReportApi.clusterReportAPIGetCostAnomalies,

    getReportCapabilities: generatedReportConfigurationApi.reportConfigurationAPIGetReportingCapabilities,
    getGpuExporterScript: generatedReportConfigurationApi.reportConfigurationAPIGetGpuMetricsExporterScript,
    getEgressDScript: generatedReportConfigurationApi.reportConfigurationAPIGetEgressdScript,

    getCostComparisonReport: platformImpactReportApi.platformImpactReportAPIGetCostComparisonReport,

    getTrialStatus: generatedReportConfigurationApi.reportConfigurationAPIGetOrganizationTrial,
    startTrial: generatedReportConfigurationApi.reportConfigurationAPIStartOrganizationTrial,
    markEligibleForTrial: generatedReportConfigurationApi.reportConfigurationAPIMarkOrganizationEligibleForTrial,
    markSalesContacted: generatedReportConfigurationApi.reportConfigurationAPIMarkSalesContactedOrganizationTrial,
  };
};
