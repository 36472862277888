import { ReactNode } from 'react';

import { Stack, Box, Typography, FormControlLabel } from '@mui/material';

import { Checkbox, ConditionalWrapper, Tooltip } from '@cast/design-system';

export type AgentSetupBlockProps = {
  heading: string;
  description: ReactNode;
  icon?: ReactNode;
  labelChip?: ReactNode;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  readonly?: boolean;
  checkboxTestId?: string;
  setupComplete?: boolean;
  isNestedBlock?: boolean;
  showCheckbox?: boolean;
};

export const AgentSetupBlock = ({
  heading,
  description,
  icon,
  labelChip,
  checked,
  onChange,
  readonly,
  checkboxTestId,
  isNestedBlock = false,
  showCheckbox = true,
}: AgentSetupBlockProps) => {
  const renderBlockContent = (): ReactNode => {
    const isPreselected = checked && readonly;
    const content = (
      <Stack gap={2}>
        <Stack direction="row" alignItems="center" gap={8}>
          <Typography
            variant={isNestedBlock ? 'P12B' : 'P14B'}
            color="grey.900"
          >
            {heading}
          </Typography>
          {labelChip}
        </Stack>
        <Typography
          component="div"
          variant="P12R"
          color="textSecondary"
          pr={24}
        >
          {description}
        </Typography>
      </Stack>
    );

    if (showCheckbox) {
      return (
        <FormControlLabel
          sx={{ m: 0 }}
          control={
            <ConditionalWrapper
              condition={!!isPreselected}
              wrapper={(child) => (
                <Tooltip
                  title="Enabled"
                  placement="top"
                  tooltipSx={{ marginLeft: -10 }}
                >
                  {/*
                      Tooltip will NOT show if checkbox is disabled unless wrapped in another element
                      https://mui.com/material-ui/react-tooltip/#disabled-elements
                  */}
                  <Box alignSelf="flex-start">{child}</Box>
                </Tooltip>
              )}
            >
              <Checkbox
                size={isNestedBlock ? 'small' : 'medium'}
                checked={checked}
                disabled={readonly}
                onChange={(_, checked) => onChange?.(checked)}
                testId={checkboxTestId}
                sx={{ alignSelf: 'flex-start', pr: isNestedBlock ? 10 : 12 }}
              />
            </ConditionalWrapper>
          }
          label={content}
          disabled={isPreselected}
        />
      );
    }

    return content;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={12}
      py={isNestedBlock ? 8 : 12}
      borderTop={!isNestedBlock ? 'grey.100' : undefined}
    >
      {renderBlockContent()}
      <Box
        sx={{
          svg: {
            width: 40,
            height: 40,
          },
        }}
      >
        {icon}
      </Box>
    </Stack>
  );
};
