import { useMemo } from 'react';

import dayjs from 'dayjs';

import { TimeSeries } from 'types/metrics';
import { makeChartProps } from 'utils/chart';

export const useChartProps = <T extends TimeSeries<any>>(
  data: T,
  withFake?: boolean,
  timestampKey: keyof T[number] = 'timestamp',
  timezone = dayjs.tz.guess()
) => {
  const props = makeChartProps(data, withFake, timestampKey, timezone);

  const _data = useMemo(() => {
    return props.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.data)]);

  const _xAxisProps = useMemo(() => {
    return props.xAxisProps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.xAxisProps)]);

  return {
    data: _data,
    xAxisProps: _xAxisProps,
  };
};
