import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { demoClusterList } from '@cast/fixtures';
import { Cluster } from '@cast/types';
import { isSecurityDemoMode } from '@cast/utils';

import { useOrganizations } from 'hooks/useOrganizations';
import { enhanceCluster } from 'utils/api-utils';

import { useSecurityDataOrFixture } from './useSecurityDataOrFixture';

type UseClustersOrFixture = {
  clusters: Cluster[];
};

export const useClustersOrFixture = (
  clusterData: Cluster[]
): UseClustersOrFixture => {
  const { pathname } = useLocation();
  const match = pathname.startsWith('/organization/security');
  const { currentOrganization } = useOrganizations();

  const clustersFixture = useMemo(
    () => () =>
      demoClusterList(currentOrganization!.id).then((data) => data.items),
    [currentOrganization]
  );

  let clustersOrFixture =
    useSecurityDataOrFixture(clusterData, clustersFixture) ?? [];

  if (match && isSecurityDemoMode()) {
    clustersOrFixture = clustersOrFixture.map((cluster) =>
      enhanceCluster(cluster)
    );
  }

  return {
    clusters: clustersOrFixture,
  };
};
