import { Divider, Stack } from '@mui/material';

import {
  SecurityInsightsBestPracticesCheckDetailsResponse,
  SecurityInsightsTicketItemType,
} from '@cast/types';
import { isSecurityDemoMode } from '@cast/utils';

import { useAbility } from 'core/ability';

import { ExceptionsButton } from './ExceptionsButton';
import { JiraButton } from '../../../../_components/JiraButton';

type Props = {
  check?: SecurityInsightsBestPracticesCheckDetailsResponse;
};

export const CheckActions = ({ check }: Props) => {
  const [canCreateJiraTickets] = useAbility().canMany([
    ['create', 'OrganizationSecurityCompliance'],
  ]);

  return (
    check &&
    !isSecurityDemoMode() && (
      <Stack direction="row" gap={4}>
        {canCreateJiraTickets && (
          <>
            <JiraButton
              ticket={check.ticket}
              itemType={SecurityInsightsTicketItemType.Check}
              itemName={check.name ?? ''}
              itemId={check.ruleId ?? ''}
            />
            <Divider orientation="vertical" flexItem sx={{ my: 4 }} />
          </>
        )}
        <ExceptionsButton check={check} />
      </Stack>
    )
  );
};
