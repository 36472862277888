import { useMemo } from 'react';

import { useBestPracticesFilters } from 'hooks/queries/security-insights';

import { useActiveClusters } from '../../../_hooks';
import {
  NamespaceSelectBase,
  NamespaceSelectBaseProps,
} from '../../_components';
import { useClustersOrFixture } from '../../hooks/useClustersOrFixture';

type Props = Omit<NamespaceSelectBaseProps, 'namespaces' | 'isLoading'>;

export const NamespaceSelect = (props: Props) => {
  const { clusters: _clusters } = useActiveClusters();
  const { clusters } = useClustersOrFixture(_clusters);
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { filters, isLoading } = useBestPracticesFilters({
    clusterIds,
  });
  return (
    <NamespaceSelectBase
      namespaces={filters?.namespaces || []}
      isLoading={isLoading}
      {...props}
    />
  );
};
