import dayjs from 'dayjs';

import { DateRange } from '@cast/types';
import { isDemoMode, useCompoundReducer } from '@cast/utils';

import { useQueryParams } from 'hooks/useQueryParams';

import { State, RangePickerProviderProps } from '../providers/types';
import { DateRangeFilter, RangePickerQueryParamKeys } from '../types';
import {
  createDefaultDateRange,
  getDateRangeFilterFromQuery,
  getDateRangeFilterFromValue,
  checkIfDefaultFilter,
  defaultFilters,
  defaultQueryParamKeys,
  getTimezoneFromQuery,
} from '../utils';

const createInitialState = (
  queryParams: URLSearchParams,
  props: RangePickerProviderProps,
  queryParamKeys: RangePickerQueryParamKeys,
  dateRange?: DateRange
): State => {
  const currentDateRange = createDefaultDateRange(
    queryParams,
    props.defaultFilterPreset,
    props.filterPresets,
    queryParamKeys,
    dateRange
  );

  const timezone = isDemoMode()
    ? 'UTC'
    : getTimezoneFromQuery(queryParams, queryParamKeys) || dayjs.tz.guess();

  return {
    filterPresets: props.filterPresets,
    currentDateRange,
    currentDateRangeFilter:
      getDateRangeFilterFromQuery(
        queryParams,
        props.filterPresets,
        queryParamKeys
      ) ||
      getDateRangeFilterFromValue(
        currentDateRange,
        timezone,
        props.filterPresets
      ),
    isDefaultFilter: checkIfDefaultFilter(
      currentDateRange,
      props.defaultFilterPreset,
      timezone
    ),
    timezone,
  };
};

export type Props = {
  filterPresets?: DateRangeFilter[];
  defaultFilterPreset?: DateRangeFilter;
  queryParamKeys?: RangePickerQueryParamKeys;
  applyQueryParams?: boolean;
  dateRange?: DateRange;
};

const handler = {
  setFilterPresets: (state: State, value: any) => {
    return {
      ...state,
      filterPresets: value,
    };
  },
  setCurrentDateRange: (state: State, value: any) => {
    return {
      ...state,
      currentDateRange: value,
    };
  },
  setCurrentDateRangeFilter: (state: State, value: any) => {
    return {
      ...state,
      currentDateRangeFilter: value,
    };
  },
  setIsDefaultFilter: (state: State, value: any) => {
    return {
      ...state,
      isDefaultFilter: value,
    };
  },
  setTimezone: (state: State, value: string) => {
    return {
      ...state,
      timezone: value,
    };
  },
};

export const useRangePickerStateHandler = ({
  filterPresets = defaultFilters,
  defaultFilterPreset = DateRangeFilter.ThisMonth,
  queryParamKeys = defaultQueryParamKeys,
  dateRange,
}: Props) => {
  const queryParams = useQueryParams();

  const { state, dispatcher } = useCompoundReducer(
    handler,
    createInitialState(
      queryParams,
      { filterPresets, defaultFilterPreset },
      queryParamKeys,
      dateRange
    )
  );

  return { ...state, ...dispatcher };
};
