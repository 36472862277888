import { createContext, ReactNode, useState } from 'react';

import { isSecurityDemoMode } from '@cast/utils';

type SecurityDemoProviderState = {
  isBannerDismissed: boolean;
  isSecurityDemoContentVisible: boolean;
  onBannerDismiss: () => void;
};

export const SecurityDemoProviderContext =
  createContext<SecurityDemoProviderState>({} as never);

type SecurityDemoProviderProps = {
  children: ReactNode;
};

export const SecurityDemoProvider = ({
  children,
}: SecurityDemoProviderProps) => {
  const [securityBannerDismissed, setSecurityBannerDismissed] = useState(
    localStorage.getItem('securityDemoBannerDismissed') === 'true'
  );

  const onBannerDismiss = () => {
    localStorage.setItem('securityDemoBannerDismissed', 'true');
    setSecurityBannerDismissed(true);
  };

  return (
    <SecurityDemoProviderContext.Provider
      value={{
        isBannerDismissed: securityBannerDismissed,
        isSecurityDemoContentVisible:
          isSecurityDemoMode() && securityBannerDismissed,
        onBannerDismiss,
      }}
    >
      {children}
    </SecurityDemoProviderContext.Provider>
  );
};
