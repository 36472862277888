import { useEffect, useRef } from 'react';

type Props = {
  currentState?: boolean;
  onSuccess?: () => void;
  error?: any;
  onError?: () => void;
  enabled?: boolean;
};

export const useWasEnabled = ({
  currentState,
  onSuccess,
  onError,
  error,
  enabled,
}: Props) => {
  const wasSuccessHandled = useRef(false);
  useEffect(() => {
    if (enabled && currentState && !wasSuccessHandled.current) {
      onSuccess?.();
      wasSuccessHandled.current = true;
    }
  }, [currentState, enabled, onSuccess]);

  const wasErrorHandled = useRef(false);
  useEffect(() => {
    if (
      enabled &&
      !wasErrorHandled.current &&
      !!error &&
      !wasSuccessHandled.current
    ) {
      onError?.();
      wasErrorHandled.current = true;
    }
  }, [enabled, error, onError]);
};
