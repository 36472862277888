import { Notification } from '@cast/types';

export type GroupedNotification = {
  id: string;
  name: string;
  message?: string;
  items: Notification[];
};

export type NotificationItem = Notification | GroupedNotification;

export const isGroupedNotification = (
  notification: NotificationItem
): notification is GroupedNotification => {
  return (notification as GroupedNotification)?.items !== undefined;
};
