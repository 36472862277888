import { useMemo } from 'react';

import orderBy from 'lodash/orderBy';

import { BestPracticeCheckStandard, K8sProvider } from '@cast/types';

import { useActiveClusters } from '../../_hooks/useActiveClusters';
import { cisByProvider } from '../constants';
import { useClustersOrFixture } from '../hooks/useClustersOrFixture';

const castOptions = [
  {
    title: 'CAST AI',
    value: BestPracticeCheckStandard.cast,
  },
];

export const useStandardCriteria = () => {
  const { clusters: _clusters } = useActiveClusters();
  const { clusters } = useClustersOrFixture(_clusters);

  const options = useMemo(() => {
    if (!clusters?.length) {
      return castOptions;
    }
    const existingProviders = Array.from(
      new Set<K8sProvider>(clusters.map(({ providerType }) => providerType))
    );
    return orderBy(
      [
        ...existingProviders
          .map(
            (provider) => cisByProvider[provider as keyof typeof cisByProvider]
          )
          .filter((cis) => !!cis),
        ...castOptions,
      ],
      ['title'],
      ['asc']
    );
  }, [clusters]);

  const defaultCis = options.find((o) => o.value.includes('cis'));

  return {
    key: 'standard',
    initialValue: (defaultCis?.value ||
      BestPracticeCheckStandard.cast) as BestPracticeCheckStandard,
    options,
  };
};
