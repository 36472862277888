import { useQuery } from '@tanstack/react-query';

import { QueryKeys, apiClient } from 'core/react-query';

type UseSingleClusterSettingsQueryArgs = {
  clusterId: string;
  refetchInterval?: number | false;
  enabled?: boolean;
};

export const useSingleClusterSettingsQuery = ({
  clusterId,
  refetchInterval,
  enabled = true,
}: UseSingleClusterSettingsQueryArgs) => {
  const {
    data: settings,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.SECURITY_CLUSTERS_SETTINGS, clusterId],
    queryFn: async () => {
      const { data } =
        await apiClient.securityInsights.getSingleClusterSettings({
          id: clusterId,
        });
      return data.settings;
    },
    enabled: enabled && !!clusterId,
    refetchInterval,
  });
  return { settings, isLoading, error, refetch };
};
