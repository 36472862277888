import { Stack } from '@mui/material';

import { DialogContent, DialogActions, Button } from '@cast/design-system';

import { AssignCredentialsDialogHeader } from '../../AssignCredentialsDialogHeader';
import { ErrorState, ErrorStateProps } from '../../ErrorState';

type ErrorSlideProps = ErrorStateProps & {
  onRetry: () => void;
  onClose: () => void;
};

export const ErrorSlide = ({
  title,
  testId,
  onRetry,
  onClose,
}: ErrorSlideProps) => {
  return (
    <Stack position="relative" data-testid={testId}>
      <AssignCredentialsDialogHeader dismiss={onClose} />
      <DialogContent>
        <ErrorState title={title} />
      </DialogContent>
      <DialogActions sx={{ py: 16, borderTop: 'grey.100' }}>
        <Button
          onClick={onClose}
          variant="tertiary"
          testId="close-dialog-button"
        >
          Close
        </Button>
        <Button onClick={onRetry} testId="retry-button">
          Retry
        </Button>
      </DialogActions>
    </Stack>
  );
};
