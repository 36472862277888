import { mergeSx, Tooltip, TooltipProps } from '@cast/design-system';

type DataTooltipProps = TooltipProps;

export const DataTooltip = ({
  children,
  title,
  sx,
  ...rest
}: DataTooltipProps) => {
  return (
    <Tooltip
      sx={mergeSx(
        {
          width: 233,
          p: 0,
        },
        sx
      )}
      tooltipSx={{
        backgroundColor: 'unset',
        color: 'grey.900',
        p: 0,
        mt: 0,
        '--ds-tooltip-background': 'white',
      }}
      title={title}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
