import { Stack, Typography } from '@mui/material';

import {
  Button,
  DialogActions,
  DialogContent,
  Icons,
  Spinner,
} from '@cast/design-system';

import { useSwipeableViews } from 'components/boxes';
import { CloseDialogButton } from 'components/dialogs';

type Props = {
  onClose: () => void;
};

export const EnablingSlide = ({ onClose }: Props) => {
  const swipeableViewsCtx = useSwipeableViews();

  return (
    <Stack position="relative">
      <CloseDialogButton onClose={onClose} />
      <DialogContent>
        <Stack direction="row" gap={12}>
          <Spinner color="secondary" size={32} />
          <Stack gap={4}>
            <Typography variant="h6">Enabling Kubernetes automation</Typography>
            <Typography variant="P12R" color="textSecondary">
              Closing this dialog will not affect the process.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ py: 16, borderTop: 'grey.100', justifyContent: 'space-between' }}
      >
        <Button
          variant="ghost"
          size="medium"
          startIcon={<Icons.ArrowLeft />}
          onClick={() => swipeableViewsCtx?.goToPreviousSlide()}
          testId="back-step-button"
        >
          Back
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Stack>
  );
};
