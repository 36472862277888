import { useEffect, useMemo } from 'react';

import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { useField, useForm } from 'react-final-form';

import { mergeSx, ListItem, Tooltip, ListItemText } from '@cast/design-system';
import { SecurityInsightsCheckExceptionsPayload } from '@cast/types';

import { RffAutocomplete } from 'core/forms/rff';
import { useActiveClusters } from 'features/organization/_hooks';
import { useClustersOrFixture } from 'features/organization/security-insights/hooks/useClustersOrFixture';
import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';

import { useGetSelectChipSx } from '../_hooks/useGetSelectChipSx';

type Option = {
  clusterId: string;
  clusterName: string;
  agentEnabled: boolean;
  providerNamespaceId?: string;
};

type Props = {
  index: number;
  sx?: SxProps;
  listSx?: SxProps;
};

export const RffClusterSelect = ({ index, sx, listSx }: Props) => {
  const { input } = useField(`groups[${index}].clusters`);
  const value: string[] = useMemo(() => input.value || [], [input.value]);

  const form = useForm<SecurityInsightsCheckExceptionsPayload>();
  useEffect(() => {
    // When cluster select is cleared, clear the whole form
    if (value.length === 0) {
      const newGroups = form.getState().values.groups?.map((group, i) => {
        if (i === index) {
          return { clusters: [], namespaces: [], kinds: [], names: [] };
        }
        return group;
      });
      form.reset({ groups: newGroups });
    }
  }, [value.length, form, index]);

  const { clusters: _clusters } = useActiveClusters();
  const { clusters } = useClustersOrFixture(_clusters);
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { agentsData, isLoading: isLoadingAgents } = useSecurityInsightsAgents({
    clusterIds,
    enabled: !!clusterIds.length,
  });

  // Selected options will be placed at the top of filter values
  const options: Option[] = useMemo(() => {
    if (isLoadingAgents) {
      return [];
    }

    const selectedOptions: Option[] =
      value.map((clusterId: string) => {
        const cluster = clusters.find((cluster) => cluster.id === clusterId)!;
        return {
          clusterId: cluster.id,
          clusterName: cluster.name,
          agentEnabled: !!agentsData?.agentStatuses?.[cluster.id],
          providerNamespaceId: cluster.providerNamespaceId,
        };
      }) || [];

    // All available options, excluding the selected ones
    const remainingOptions: Option[] = orderBy(
      clusters
        .filter((cluster) => !value.includes(cluster.id))
        .map((cluster) => ({
          clusterId: cluster.id,
          clusterName: cluster.name,
          agentEnabled: !!agentsData?.agentStatuses?.[cluster.id],
          providerNamespaceId: cluster.providerNamespaceId,
        })),
      ['agentEnabled', 'clusterName'],
      ['desc', 'asc']
    );

    return [...selectedOptions, ...remainingOptions];
  }, [isLoadingAgents, value, clusters, agentsData?.agentStatuses]);

  const chipSx = useGetSelectChipSx();

  return (
    <RffAutocomplete
      testId={`cluster-select-${index}`}
      name={`groups[${index}].clusters`}
      size="medium"
      sx={mergeSx(sx, chipSx)}
      listSx={mergeSx(
        { 'li > .DsListItem-root': { alignItems: 'flex-start', py: 5 } },
        listSx
      )}
      inputProps={{ label: 'Clusters' }}
      inputText={clusterIds.length === 0 ? 'Select' : 'Selected'}
      checkable
      multiple
      options={options}
      applySortingWhileSearching={false}
      optionDisabled={(option: Option) => !option.agentEnabled}
      optionKey={(option: Option) => option.clusterId}
      optionLabel={(option: Option) => option.clusterName}
      optionValue={(option: Option) => option.clusterId}
      renderOption={(
        { agentEnabled, providerNamespaceId, clusterId, clusterName }: Option,
        listItemProps
      ) => {
        const content = (
          <Stack>
            <ListItemText primary={clusterName} />
            <ListItemText secondary={providerNamespaceId} />
          </Stack>
        );
        return (
          <ListItem
            {...listItemProps}
            sx={{
              background: 'none!important',
              pointerEvents: 'auto!important',
            }}
            disabled={!agentEnabled}
            testId={'cluster-option-' + clusterId}
          >
            {!agentEnabled ? (
              <Tooltip
                title="Security feature is not enabled. Please visit cluster’s Security report to enable it"
                placement="left"
                arrow
                testId="enable-security-agent"
              >
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </ListItem>
        );
      }}
      isOptionEqualToValue={(option: Option, value: Option | string) => {
        return isEqual(
          option?.clusterId,
          typeof value === 'string' ? value : value.clusterId
        );
      }}
    />
  );
};
