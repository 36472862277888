import {
  ComponentType,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactElement,
  RefObject,
  SVGProps,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { styled } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import max from 'lodash/max';
import sum from 'lodash/sum';
import { nanoid } from 'nanoid';
import {
  Area,
  AreaProps,
  Bar,
  BarProps,
  CartesianGrid,
  Cell,
  ComposedChart,
  DotProps,
  Line,
  LineProps,
  ReferenceDot,
  ReferenceDotProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';

import { mergeSx } from '@cast/design-system';

import { useThemeColors } from 'hooks/theme';
import { useWindowResize } from 'hooks/useWindowResize';
import { ChartConfig, ChartType } from 'types/charts';
import { TimeSeries } from 'types/metrics';
import { anyTimestampToUnix } from 'utils/date';
import { getThemeColor } from 'utils/theme';

import { calculateBarSize, isBarChartProps } from './utils';
import { StackedBarShape } from '../_components';
import { valueResolver } from '../_utils';
import { CustomYAxisTick, CustomYAxisTickProps } from '../CustomYAxisTick';
import { ActiveDot, HiddenDot } from '../line';

const StyledResponsiveContainer = styled(ResponsiveContainer)({});

export const makeCursorWithBarSize =
  (setCursorWidth: (c: number) => void) =>
  (ref: ComposedChartWithEstimateRef) => {
    if (ref?.autoBarSize) {
      setCursorWidth(ref.autoBarSize + Math.min(8, ref.autoBarSize * 0.3));
    }
  };

export type ComposedChartWithEstimateProps<T> = {
  data: TimeSeries<T>;
  TooltipComponent?: ComponentType<{ payload?: any }>;
  tooltipCursor?: boolean | ReactElement | SVGProps<SVGElement>;
  chartConfig: ChartConfig[];
  xAxisProps: XAxisProps;
  yAxisProps?: Omit<YAxisProps, 'tick'> & { tick?: CustomYAxisTickProps };
  isAnimationActive?: boolean;
  isAutoBarResizing?: boolean;
  composedProps?: typeof ComposedChart.defaultProps;
  estimateStartPoint?: number;
  estimateKey?: string;
  hideXAxisOuterTicks?: boolean;
  sx?: SxProps;
  cursorWidth?: number;
  /**
   * It will take a percentage of the maximum value and compare the FIRST item in each stack individually to determine whether it should be rendered.
   */
  barThreshold?: number;
  highlightTimestamp?: (payload: T) => boolean;
  highlighterProps?: ReferenceDotProps;
};

export type ComposedChartWithEstimateRef = {
  gridRef: MutableRefObject<CartesianGrid | null>;
  autoBarSize?: number | null;
} | null;

export const MarkedPoint = ({ cx = 0, cy = 0 }: DotProps) => {
  return (
    <svg
      x={cx - 3}
      y={cy - 16}
      width="6"
      height="18"
      viewBox="0 0 6 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3 5C3.3 4.83431 3.16569 4.7 3 4.7C2.83431 4.7 2.7 4.83431 2.7 5L3.3 5ZM3 14.4C2.11634 14.4 1.4 15.1163 1.4 16C1.4 16.8837 2.11634 17.6 3 17.6C3.88366 17.6 4.6 16.8837 4.6 16C4.6 15.1163 3.88366 14.4 3 14.4ZM2.7 5.6875C2.7 5.85319 2.83431 5.9875 3 5.9875C3.16569 5.9875 3.3 5.85319 3.3 5.6875L2.7 5.6875ZM3.3 7.0625C3.3 6.89681 3.16569 6.7625 3 6.7625C2.83431 6.7625 2.7 6.89681 2.7 7.0625L3.3 7.0625ZM2.7 8.4375C2.7 8.60319 2.83431 8.7375 3 8.7375C3.16569 8.7375 3.3 8.60319 3.3 8.4375L2.7 8.4375ZM3.3 9.8125C3.3 9.64681 3.16569 9.5125 3 9.5125C2.83431 9.5125 2.7 9.64681 2.7 9.8125L3.3 9.8125ZM2.7 11.1875C2.7 11.3532 2.83431 11.4875 3 11.4875C3.16569 11.4875 3.3 11.3532 3.3 11.1875L2.7 11.1875ZM3.3 12.5625C3.3 12.3968 3.16569 12.2625 3 12.2625C2.83431 12.2625 2.7 12.3968 2.7 12.5625L3.3 12.5625ZM2.7 13.9375C2.7 14.1032 2.83431 14.2375 3 14.2375C3.16569 14.2375 3.3 14.1032 3.3 13.9375L2.7 13.9375ZM3.3 15.3125C3.3 15.1468 3.16569 15.0125 3 15.0125C2.83432 15.0125 2.7 15.1468 2.7 15.3125L3.3 15.3125ZM2.7 5L2.7 5.6875L3.3 5.6875L3.3 5L2.7 5ZM2.7 7.0625L2.7 8.4375L3.3 8.4375L3.3 7.0625L2.7 7.0625ZM2.7 9.8125L2.7 11.1875L3.3 11.1875L3.3 9.8125L2.7 9.8125ZM2.7 12.5625L2.7 13.9375L3.3 13.9375L3.3 12.5625L2.7 12.5625ZM2.7 15.3125L2.7 16L3.3 16L3.3 15.3125L2.7 15.3125Z"
        fill="#051922"
      />
      <circle cx="3" cy="3" r="3" fill="#051922" />
      <circle cx="3" cy="3" r="1.5" fill="white" />
    </svg>
  );
};

const InnerComposedChartWithEstimate = <T extends unknown>(
  {
    data,
    TooltipComponent,
    tooltipCursor,
    chartConfig,
    composedProps,
    estimateStartPoint,
    xAxisProps,
    yAxisProps,
    isAnimationActive = true,
    isAutoBarResizing = false,
    hideXAxisOuterTicks,
    sx,
    barThreshold,
    highlightTimestamp,
    highlighterProps,
  }: ComposedChartWithEstimateProps<T>,
  ref: ForwardedRef<ComposedChartWithEstimateRef>
) => {
  if (barThreshold !== undefined && (barThreshold < 0 || barThreshold > 100)) {
    throw new Error('barThreshold should be between 0 and 100');
  }
  useWindowResize(); // Used for rect to get updated width
  if ((estimateStartPoint ?? -1) < 0) {
    estimateStartPoint = undefined;
  }
  const gridRef = useRef<CartesianGrid>(null);
  const [areaRef, setAreaRef] = useState<Area>();
  const [lineRef, setLineRef] = useState<Line>();
  const [barRef, setBarRef] = useState<Bar>();

  const autoBarSize =
    isAutoBarResizing && gridRef.current
      ? calculateBarSize(gridRef.current)
      : null;

  useImperativeHandle(
    ref,
    () => {
      return {
        gridRef,
        autoBarSize,
      };
    },
    [autoBarSize]
  );

  const theme = useTheme();
  chartConfig = chartConfig.map((config) => {
    return {
      ...config,
      config: {
        ...config.config,
        ...(isBarChartProps(config.config) && isAutoBarResizing && !!autoBarSize
          ? {
              barSize:
                !!config.config.barSize && autoBarSize > config.config.barSize!
                  ? autoBarSize
                  : config.config.barSize,
            }
          : {}),
        stroke: getThemeColor(theme, config.config.stroke),
        fill: getThemeColor(theme, config.config.fill),
      },
    };
  });
  const barBorderRadius = 4;
  const barStroke = 2;

  // eslint-disable-next-line
  let startingPointCoords: any;
  // eslint-disable-next-line
  let lastPointCoords: any;

  if (estimateStartPoint) {
    if (areaRef) {
      startingPointCoords = areaRef?.state.curPoints?.[estimateStartPoint];
      lastPointCoords = areaRef?.state.curPoints?.pop();
    }
    if (lineRef) {
      startingPointCoords = lineRef?.state.curPoints?.[estimateStartPoint];
      lastPointCoords = lineRef?.state.curPoints?.pop();
    }
    if (barRef) {
      startingPointCoords = barRef?.state.curData?.[estimateStartPoint];
    }
  }

  const enabledConfigs = useMemo(
    () => chartConfig.filter(({ enabled }) => enabled),
    [chartConfig]
  );

  const [bgColor] = useThemeColors('common.white');
  const strippedBarId = nanoid();
  const strippedBarForecastId = nanoid();

  const areaGradiantIds = enabledConfigs
    .filter(({ type }) => type === ChartType.AREA)
    .map(() => nanoid());
  const lineGradiantIds = enabledConfigs
    .filter(({ type }) => type === ChartType.LINE)
    .map(() => nanoid());

  const renderGradients = () => {
    const x1 = startingPointCoords ? startingPointCoords.x : 0;
    const x2 = lastPointCoords ? lastPointCoords.x : 0;
    const percentage = estimateStartPoint && x1 && x2 ? (x1 * 100) / x2 : 100;

    return (
      <>
        {[...areaGradiantIds, ...lineGradiantIds].map((id, index) => {
          const { config } = enabledConfigs[index];
          const fill =
            config.fill && config.fill !== 'none' ? config.fill : config.stroke;

          return (
            <linearGradient
              key={id}
              id={id}
              x1="0"
              y1="0"
              x2="100%"
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor={fill} />
              <stop offset={`${percentage}%`} stopColor={fill} />
              <stop
                offset={`${percentage}%`}
                stopColor={fill ? alpha(fill, 0.3) : 'none'}
              />
              <stop
                offset="100%"
                stopColor={fill ? alpha(fill, 0.3) : 'none'}
              />
            </linearGradient>
          );
        })}
      </>
    );
  };

  const barConfigs = useMemo(
    () => enabledConfigs.filter(({ type }) => type === ChartType.BAR),
    [enabledConfigs]
  );

  const barChartDataKeys = useMemo(
    () => barConfigs.map((item) => item.config?.dataKey),
    [barConfigs]
  );

  const maxValue = useMemo(
    () =>
      barChartDataKeys.length > 0
        ? max(
            data.reduce((acc, entry) => {
              const totalValue =
                sum(
                  barChartDataKeys.map((dataKey) =>
                    valueResolver(dataKey, entry)
                  )
                ) || 0;
              return [...acc, totalValue];
            }, [] as number[])
          )
        : undefined,
    [barChartDataKeys, data]
  );

  return (
    <StyledResponsiveContainer
      height="100%"
      width="100%"
      sx={mergeSx(
        sx,
        {
          '& .recharts-tooltip-wrapper': {
            boxShadow: 'unset',
            zIndex: 1,
          },
        },
        hideXAxisOuterTicks && {
          '& .xAxis': {
            '& .recharts-cartesian-axis-tick': {
              '&:first-of-type, &:last-of-type': {
                display: 'none',
                paddingTop: '0',
              },
            },
          },
        }
      )}
    >
      <ComposedChart data={data} {...composedProps} syncMethod="index">
        <defs>
          <pattern
            id={strippedBarId}
            patternUnits="userSpaceOnUse"
            width={barStroke * 3}
            height="4.5"
            patternTransform="rotate(140)"
          >
            <line x1="4" y="0" x2="4" y2="9" stroke={bgColor} strokeWidth="6" />
            <line
              x1="0"
              y="0"
              x2="0"
              y2="4.5"
              stroke="#082939"
              strokeWidth={barStroke * 1.5}
            />
          </pattern>

          <pattern
            id={strippedBarForecastId}
            patternUnits="userSpaceOnUse"
            width={barStroke * 3}
            height="4.5"
            patternTransform="rotate(140)"
          >
            <line x1="4" y="0" x2="4" y2="9" stroke={bgColor} strokeWidth="6" />
            <line
              x1="0"
              y="0"
              x2="0"
              y2="4.5"
              stroke={alpha('#082939', 0.3)}
              strokeWidth={barStroke * 1.5}
            />
          </pattern>

          {renderGradients()}
        </defs>

        {enabledConfigs
          .filter(({ type }) => type === ChartType.LINE)
          .map(({ config }, index) => (
            <Line
              key={index}
              isAnimationActive={isAnimationActive}
              strokeWidth={2}
              dot={
                // Show dot if there is only one data point, otherwise, show custom highlighter
                data?.length === 1 ? undefined : <HiddenDot />
              }
              activeDot={(props: any) => {
                if (typeof props.dataKey === 'function') {
                  if (props.dataKey(props.payload) === null) {
                    return null as unknown as ReactElement;
                  }
                } else {
                  if (props.payload[props.dataKey] === null) {
                    return null as unknown as ReactElement;
                  }
                }

                return <ActiveDot {...props} />;
              }}
              {...(config as LineProps)}
              stroke={`url(#${lineGradiantIds[index]})`}
              // eslint-disable-next-line
              ref={index === 0 ? (setLineRef as any) : null}
              strokeDasharray={config.strokeDasharray}
            />
          ))}

        {enabledConfigs
          .filter(({ type }) => type === ChartType.AREA)
          .map(({ config, stackId }, index) => (
            <Area
              key={index}
              stackId={stackId}
              isAnimationActive={isAnimationActive}
              type="monotone"
              dot={<HiddenDot />}
              strokeWidth={0}
              fillOpacity={1}
              activeDot={(props: any) => {
                if (typeof props.dataKey === 'function') {
                  if (props.dataKey(props.payload) === null) {
                    return null as unknown as ReactElement;
                  }
                } else {
                  if (props.payload[props.dataKey] === null) {
                    return null as unknown as ReactElement;
                  }
                }

                return <ActiveDot {...props} />;
              }}
              {...(config as AreaProps)}
              fill={
                config.fill !== 'none'
                  ? `url(#${areaGradiantIds[index]})`
                  : `none`
              }
              stroke={`url(#${areaGradiantIds[index]})`}
              ref={
                index === 0
                  ? (setAreaRef as ((instance: Area) => void) &
                      RefObject<SVGElement>)
                  : null
              }
            />
          ))}

        <CartesianGrid
          stroke={theme.palette.grey[200]}
          opacity={0.5}
          vertical={false}
          ref={
            gridRef as ((instance: CartesianGrid) => void) &
              RefObject<SVGElement>
          }
        />

        {!!TooltipComponent && (
          <Tooltip
            allowEscapeViewBox={{ x: false, y: false }}
            cursor={tooltipCursor}
            wrapperClassName="ComposedChartWithEstimate-TooltipWrapper"
            isAnimationActive={false}
            filterNull={false}
            content={(props) => {
              return (
                <TooltipComponent
                  // eslint-disable-next-line
                  payload={props!.payload as any}
                />
              );
            }}
          />
        )}

        {barConfigs
          .reverse()
          .map(
            (
              { config, stripedBar, stackId, xAxisId },
              configIndex,
              barConfigs
            ) => {
              const minValue =
                maxValue !== undefined && barThreshold !== undefined
                  ? maxValue * (barThreshold / 100)
                  : undefined;
              return (
                <Bar
                  autoReverse
                  key={configIndex}
                  isAnimationActive={isAnimationActive}
                  xAxisId={xAxisId}
                  stackId={stackId}
                  fill={stripedBar ? `url(#${strippedBarId})` : config.fill}
                  stroke={config.stroke ?? config.fill}
                  shape={
                    <StackedBarShape
                      radius={barBorderRadius}
                      last={barConfigs.length - 1 === configIndex}
                      minValue={minValue}
                    />
                  }
                  {...(config as BarProps)}
                  // eslint-disable-next-line
                  ref={configIndex === 0 ? (setBarRef as any) : null}
                >
                  {data.map((entry, index) => {
                    const isForecasted =
                      typeof estimateStartPoint === 'number' &&
                      estimateStartPoint <= index;

                    const value = valueResolver(config.dataKey, entry);

                    const hasMinValue =
                      minValue !== undefined &&
                      value !== null &&
                      configIndex === barConfigs.length - 1 // Apply only for the first bar in stack
                        ? value >= minValue
                        : true;
                    const fill = hasMinValue
                      ? config.fill
                      : barConfigs[configIndex - 1]?.config?.fill ||
                        config.fill;
                    const stroke = hasMinValue
                      ? config.stroke || fill
                      : barConfigs[configIndex - 1]?.config?.stroke || fill;

                    return (
                      <Cell
                        key={`cell-${entry.timestamp}`}
                        fill={
                          fill !== 'none'
                            ? stripedBar
                              ? `url(#${
                                  isForecasted
                                    ? strippedBarForecastId
                                    : strippedBarId
                                })`
                              : isForecasted
                              ? alpha(stroke!, 0.3)
                              : stroke
                            : 'none'
                        }
                        stroke={isForecasted ? alpha(stroke!, 0.3) : stroke}
                        strokeOpacity={isForecasted ? 0.3 : 1}
                        radius={hasMinValue ? barBorderRadius : 0}
                      />
                    );
                  })}
                </Bar>
              );
            }
          )}

        <XAxis
          tickLine={false}
          axisLine={false}
          fontFamily="Poppins"
          {...xAxisProps}
          xAxisId={1}
          hide
        />

        <XAxis
          tickLine={false}
          axisLine={false}
          fontFamily="Poppins"
          {...xAxisProps}
          xAxisId={0}
        />

        <YAxis
          mirror
          tickLine={false}
          axisLine={false}
          padding={{ top: 30 }}
          fontFamily="Poppins"
          {...yAxisProps}
          tick={(props) => (
            <CustomYAxisTick {...props} {...(yAxisProps?.tick || {})} />
          )}
        />
        {highlightTimestamp &&
          data
            .filter((d) => highlightTimestamp(d))
            .map(({ timestamp }) => (
              <ReferenceDot
                key={timestamp}
                x={anyTimestampToUnix(timestamp)}
                y={0}
                shape={<MarkedPoint />}
                {...highlighterProps}
              />
            ))}
      </ComposedChart>
    </StyledResponsiveContainer>
  );
};

export const ComposedChartWithEstimate = forwardRef(
  InnerComposedChartWithEstimate
) as (<T extends unknown>(
  props: ComposedChartWithEstimateProps<T> & {
    ref?: ForwardedRef<ComposedChartWithEstimateRef>;
  }
) => ReactElement) & { displayName: string };

ComposedChartWithEstimate.displayName = 'ComposedChartWithEstimate';
