import dayjs, { UnitTypeShort } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import partition from 'lodash/partition';
import sumBy from 'lodash/sumBy';

import {
  CostReportAllocationGroup,
  NodeResourceOffering,
  NodeMigrationStatusMigrationStatusEnum,
  NodeResponse,
  RebalancingNode,
  WorkloadNode,
} from '@cast/types';
import { DEMO_CLUSTER_ID } from '@cast/utils';

import { inventory } from './generators/constants';

dayjs.extend(utc);

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

type TimestampToken = `__TIMESTAMP:${'-' | '+'}${number}${UnitTypeShort}__`;

export const convertTimestampTokens = (
  token: TimestampToken,
  format: 'iso' | 'unix' = 'iso',
  formatter?: (date: dayjs.Dayjs) => dayjs.Dayjs,
  startDate?: dayjs.Dayjs
) => {
  const offsetString = token.replace(/(__TIMESTAMP:|__)/g, '');
  const match = offsetString.match(/(-|\+)(.*?)([a-z])/);

  if (!match) {
    throw new Error(`Invalid date token: ${token}`);
  }

  const [, modifier, offset, manipulateType] = match;

  let timestamp: dayjs.Dayjs;

  if (modifier === '+') {
    timestamp = (startDate ?? dayjs.utc()).add(
      parseInt(offset),
      manipulateType as UnitTypeShort
    );
  } else {
    timestamp = (startDate ?? dayjs.utc()).subtract(
      parseInt(offset),
      manipulateType as UnitTypeShort
    );
  }

  if (formatter) {
    timestamp = formatter(timestamp);
  }

  if (format === 'iso') {
    return timestamp.toISOString();
  } else {
    return (timestamp.unix() * 1000).toString();
  }
};

export const replaceTimestampTokens = (
  json: string,
  format: 'iso' | 'unix' = 'iso',
  formatter?: (date: dayjs.Dayjs) => dayjs.Dayjs,
  startDate?: dayjs.Dayjs
) => {
  return json.replace(/(__TIMESTAMP:)(.*?)(__)/g, (token) => {
    return convertTimestampTokens(
      token as TimestampToken,
      format,
      formatter,
      startDate
    );
  });
};

export const replaceOrgIdTokens = (json: string, orgId: string) => {
  return json.replace(/__ORG_ID__/g, orgId);
};

export const replaceClusterIdTokens = (
  json: string,
  clusterId = DEMO_CLUSTER_ID
) => {
  return json.replace(/__CLUSTER_ID__/g, clusterId);
};

export const replaceClusterNameTokens = (
  json: string,
  name = 'CASTAI.demo.cluster'
) => {
  return json.replace(/__CLUSTER_NAME__/g, name);
};

export const replaceRebalancingPlanIdTokens = (json: string, id: string) => {
  return json.replace(/__REBALANCING_PLAN_ID__/g, id);
};

export const getNodeResourceOffering = (
  node: NodeResponse
): NodeResourceOffering => {
  if (node.labels?.['scheduling.cast.ai/spot-fallback']) {
    return NodeResourceOffering.FALLBACK;
  }

  if (node.spotConfig?.isSpot) {
    return NodeResourceOffering.SPOT;
  }

  return NodeResourceOffering.ON_DEMAND;
};

export const getWorkloadNode = (node: NodeResponse): WorkloadNode => {
  return {
    id: node.id!,
    name: node.name!,
    specs: {
      milliCpu: node.resources!.cpuCapacityMilli!,
      memoryMib: node.resources!.memCapacityMib!,
      instanceType: node.instanceType!,
    },
    status: {
      migrationStatus: NodeMigrationStatusMigrationStatusEnum.ready,
    },
    totalPods: 0,
    totalProblematicPods: 0,
    workloadReplicas: 0,
  };
};

export const getRebalancingGreenNodes = (nodes: RebalancingNode[]) => {
  const [fallbackNodes, otherNodes] = partition(nodes, 'isSpotFallback');
  const [spotNodes, onDemandNodes] = partition(otherNodes, 'isSpot');

  return [
    ...spotNodes,
    ...fallbackNodes.map((n) => {
      return {
        ...n,
        isSpotFallback: false,
        isSpot: true,
        priceHourly: inventory.spot[n.instanceType!].price,
        createdAt: '',
      };
    }),
    ...onDemandNodes.map((n) => {
      return {
        ...n,
        isSpotFallback: false,
        isSpot: true,
        priceHourly: inventory.spot[n.instanceType!].price,
        createdAt: '',
      };
    }),
  ];
};

export const getRebalancingConfigurationTotal = (nodes: RebalancingNode[]) => {
  const priceHourly = sumBy(nodes, ({ priceHourly }) =>
    parseFloat(priceHourly!)
  );
  const totalPods = sumBy(nodes, 'totalPods');
  return {
    nodes: nodes.length,
    replaceableNodes: nodes.length,
    migratablePods: totalPods,
    milliCpu: sumBy(nodes, 'milliCpu'),
    memoryMib: sumBy(nodes, 'memoryMib'),
    priceHourly: priceHourly.toString(),
    priceMonthly: (priceHourly * 730).toString(),
    pods: totalPods,
    problematicPods: 0,
  };
};

export function* metricsUsageDatapointGenerator(
  current: number,
  pattern: number[],
  unit: 's' | 'm' | 'h' | 'd' = 'h',
  step = 1
) {
  let index = 0;
  let timeValue = 0;
  let value = current;

  while (true) {
    if (timeValue < 0) {
      const multiplier = pattern[index % pattern.length];
      value = Math.floor(current * multiplier);
    }

    yield {
      timestamp: `__TIMESTAMP:${
        timeValue >= 0 ? '+' : ''
      }${timeValue}${unit}__`,
      value: value.toString(),
    };

    timeValue -= step;
    index++;
  }
}

/**
 * @description simple consistent hashing function
 * @param item string value from which hash is calculated
 * @param mod number of buckets to hash into
 */
export const consistentHash = (item: string, mod: number) => {
  let hash = 0;
  for (let i = 0; i < item.length; i++) {
    hash += item.charCodeAt(i);
  }
  return hash % mod;
};

export const groupWorkloadsByNamespaceAndLabels = <
  T extends Array<{ workloadName?: string; namespace?: string }> | undefined
>(
  workloads: T,
  group: CostReportAllocationGroup
): T => {
  return workloads?.filter((w) => {
    if (group.filter?.namespaces?.length) {
      return group.filter.namespaces.includes(w.namespace!);
    }

    if (group.filter?.labels?.length) {
      return consistentHash(w.workloadName!, 10) > 7;
    }

    return true;
  }) as T;
};
