import { useEffect, useState } from 'react';

export const useDataOrFixture = <T>(
  data: T | undefined,
  fixture: () => Promise<T>,
  showFixture: boolean
) => {
  const [fixtureData, setFixtureData] = useState(data);

  useEffect(() => {
    if (showFixture) {
      fixture().then((fixtureData) => {
        setFixtureData(fixtureData);
      });
    }
  }, [fixture, showFixture]);

  return showFixture ? fixtureData : data;
};
