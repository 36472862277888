import dayjs, { Dayjs } from 'dayjs';
import round from 'lodash/round';

import { TimeSeries, TimeSeriesEntry } from 'types/metrics';

export const isFirstCloserThenSecondDatapoint = <T>(
  datapoint1: TimeSeriesEntry<T>,
  datapoint2: TimeSeriesEntry<T>,
  target: Dayjs
): boolean => {
  const firstTime = dayjs.utc(datapoint1.timestamp);
  const secondTime = dayjs.utc(datapoint2.timestamp);

  const isFirstSameDay = firstTime.isSame(target, 'day');
  const isSecondSameDay = secondTime.isSame(target, 'day');

  if (isFirstSameDay && !isSecondSameDay) {
    return true;
  }

  if (!isFirstSameDay && isSecondSameDay) {
    return false;
  }

  const firstDistance = target.diff(firstTime, 'seconds');
  const secondDistance = target.diff(secondTime, 'seconds');
  return Math.abs(firstDistance) < Math.abs(secondDistance);
};

export const findNearestDataPointToTimestamp = <T>(
  metrics: TimeSeries<T>,
  target: Dayjs
): T => {
  if (metrics.length < 2) {
    return metrics[0];
  }

  const middleIndex = round(metrics.length / 2) - 1;

  const isFirstCloserToTarget = isFirstCloserThenSecondDatapoint(
    metrics[middleIndex],
    metrics[middleIndex + 1],
    target
  );

  if (metrics.length === 2) {
    return metrics[isFirstCloserToTarget ? 0 : 1];
  }

  return findNearestDataPointToTimestamp(
    isFirstCloserToTarget
      ? metrics.slice(0, middleIndex + 1)
      : metrics.slice(middleIndex + 1),
    target
  );
};
