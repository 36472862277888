import { useState } from 'react';

import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { Matcher, TZDate } from 'react-day-picker';

import { Button } from '../../../buttons';
import {
  InfiniteDayPicker,
  InfiniteDayPickerProps,
} from '../../components/InfiniteDayPicker';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import {
  DatePeriod,
  InfiniteDayPickerRef,
  PeriodComparisonPickerProviderProps,
} from '../../types';
import { dayjsToTZDate, shouldHideHoverTrail } from '../../utils';

type PeriodComparisonInfinitePickerProps = {
  label: string;
  selectedRange?: DatePeriod;
  setSelectedRange: (range: DatePeriod) => void;
  setEndPlaceholder: (placeholder: string) => void;
  setStartPlaceholder: (placeholder: string) => void;
  infinitePickerProps: Omit<InfiniteDayPickerProps, 'dayPickerProps'>;
  disabledDates?: Matcher | Matcher[];
  otherRange?: DatePeriod;
  setPickerRef: (ref: InfiniteDayPickerRef) => void;
};

export const PeriodComparisonInfinitePicker = ({
  label,
  selectedRange,
  setSelectedRange,
  setEndPlaceholder,
  setStartPlaceholder,
  infinitePickerProps,
  disabledDates,
  otherRange,
  setPickerRef,
}: PeriodComparisonInfinitePickerProps) => {
  const ctx = useDatePickerContext<PeriodComparisonPickerProviderProps>();

  const [hoveredDay, setHoveredDay] = useState<TZDate | undefined>();

  const handleDayHoverStart = (day: TZDate) => {
    const hoveredDayInstance = dayjs(day).tz(ctx.timezone);
    const hovered = hoveredDayInstance.format(ctx.format);
    setHoveredDay(day);

    if (ctx.activePeriod) {
      setStartPlaceholder(hovered);
      setEndPlaceholder(
        hoveredDayInstance.add(ctx.activePeriod, 'minutes').format(ctx.format)
      );
    } else {
      if (selectedRange?.from) {
        setEndPlaceholder(hovered);
      } else {
        setStartPlaceholder(hovered);
      }
    }
  };

  const handleDayHoverEnd = () => {
    setHoveredDay(undefined);
    setStartPlaceholder('');
    setEndPlaceholder('');
  };

  const handleOnRangeChange = (
    dateRange: DatePeriod | undefined,
    triggerDate: TZDate
  ) => {
    const tzTriggerDate = dayjs.tz(triggerDate, ctx.timezone);

    if (ctx.activePeriod) {
      setSelectedRange({
        from: dayjsToTZDate(tzTriggerDate.startOf('day'), ctx.timezone),
        to: dayjsToTZDate(
          tzTriggerDate
            .startOf('day')
            .add(ctx.activePeriod, 'minutes')
            .subtract(1, 'ms'),
          ctx.timezone
        ),
      });
    } else {
      if (
        (!selectedRange?.from && !selectedRange?.to) ||
        (selectedRange.from && selectedRange.to)
      ) {
        // if no range or full range was selected - start from beginning
        setSelectedRange({
          from: dayjsToTZDate(tzTriggerDate.startOf('day'), ctx.timezone),
          to: undefined,
        });
      } else if (
        selectedRange.from &&
        !selectedRange.to &&
        !dateRange?.from &&
        !dateRange?.to
      ) {
        // if clicked again on the same date set full range
        setSelectedRange({
          from: dayjsToTZDate(tzTriggerDate.startOf('day'), ctx.timezone),
          to: dayjsToTZDate(tzTriggerDate.endOf('day'), ctx.timezone),
        });
        ctx.setActivePeriod(60 * 24);
      } else {
        const newFrom = dateRange?.from
          ? dayjs.tz(dateRange.from, ctx.timezone).startOf('day')
          : undefined;
        const newTo = dateRange?.to
          ? dayjs.tz(dateRange.to, ctx.timezone).endOf('day')
          : undefined;

        setSelectedRange({
          from: newFrom ? dayjsToTZDate(newFrom, ctx.timezone) : undefined,
          to: newTo ? dayjsToTZDate(newTo, ctx.timezone) : undefined,
        });

        if (newFrom && newTo) {
          ctx.setActivePeriod(
            Math.abs(newFrom.diff(newTo.add(1, 'ms'), 'minutes'))
          );
        }
      }
    }
  };

  const buildHoverTrailMatcher = () => {
    if (!hoveredDay) {
      return false;
    }
    const hoveredDayInstance = dayjs(hoveredDay).tz(ctx.timezone);

    if (ctx.activePeriod) {
      return {
        before: hoveredDayInstance.add(ctx.activePeriod, 'minutes'),
        after: hoveredDay,
      };
    }

    const hideTrail = shouldHideHoverTrail(
      selectedRange,
      hoveredDay,
      ctx.maxNumberOfDays
    );

    if (hideTrail || !selectedRange?.from || !hoveredDay) {
      return false;
    }

    if (dayjs(selectedRange.from).isAfter(hoveredDay)) {
      return { before: selectedRange.from, after: hoveredDay };
    }

    return { before: hoveredDay, after: selectedRange.from };
  };

  const buildHoverTrailEndMatcher = () => {
    if (!hoveredDay) {
      return false;
    }

    const hoveredDayInstance = dayjs(hoveredDay);

    if (ctx.activePeriod) {
      return dayjsToTZDate(
        hoveredDayInstance.add(ctx.activePeriod, 'minutes').subtract(1, 'ms'),
        ctx.timezone
      );
    }

    const hideTrail = shouldHideHoverTrail(
      selectedRange,
      hoveredDay,
      ctx.maxNumberOfDays
    );

    if (hideTrail) {
      return false;
    }

    return hoveredDay;
  };

  const buildHoverTrailStartMatcher = () => {
    if (!hoveredDay) {
      return false;
    }

    if (ctx.activePeriod) {
      return hoveredDay;
    }

    const hideTrail = shouldHideHoverTrail(
      selectedRange,
      hoveredDay,
      ctx.maxNumberOfDays
    );

    if (hideTrail) {
      return false;
    }

    return selectedRange?.from;
  };

  const getModifierClassName = () => {
    if (ctx.activePeriod) {
      return 'active';
    }

    if (!selectedRange?.from || !hoveredDay) {
      return '';
    }

    return dayjs(selectedRange.from).isAfter(hoveredDay) ? 'up' : 'down';
  };

  const shouldStretchHelp =
    infinitePickerProps.variant === 'main' &&
    !selectedRange?.from &&
    !selectedRange?.to &&
    !otherRange?.from &&
    !otherRange?.to;

  const getHelpMessage = () => {
    if (shouldStretchHelp) {
      if (ctx.activePeriod) {
        return 'Pick a start date for one of the periods';
      } else {
        return `Select a date range from the left ${
          !ctx.hidePresets ? ' or pick a start date for one of the periods' : ''
        }`;
      }
    }

    if (
      selectedRange?.from &&
      selectedRange?.to &&
      ctx.hidePresets &&
      ctx.activePeriod
    ) {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="P10R">
            {ctx.activePeriod} days range set
          </Typography>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              ctx.setActivePeriod();
              ctx.clearRanges();
            }}
          >
            Clear
          </Button>
        </Stack>
      );
    }

    if (
      !selectedRange?.from &&
      !selectedRange?.to &&
      otherRange?.from &&
      otherRange?.to
    ) {
      return `Pick a start date for ${label}`;
    }

    if (selectedRange?.from && !selectedRange.to) {
      const diff = hoveredDay
        ? Math.abs(dayjs(hoveredDay).diff(selectedRange.from, 'days')) + 1
        : 0;
      return `Pick an end date for ${label} ${
        ctx.maxNumberOfDays
          ? `(${diff}/${ctx.maxNumberOfDays} ${pluralize(
              'day',
              ctx.maxNumberOfDays
            )})`
          : ''
      }`;
    }

    return '';
  };

  return (
    <InfiniteDayPicker
      ref={setPickerRef}
      dayPickerProps={{
        className: 'rdp-mode_compare',
        mode: ctx.mode,
        selected: { from: selectedRange?.from, to: selectedRange?.to },
        onSelect: (range, triggerDate) =>
          handleOnRangeChange(range as DatePeriod, triggerDate as TZDate),
        onDayMouseEnter: (day) => handleDayHoverStart(day as TZDate),
        onDayMouseLeave: handleDayHoverEnd,
        disabled: disabledDates,
        max:
          ctx.maxNumberOfDays && ctx.maxNumberOfDays > 1
            ? ctx.maxNumberOfDays - 1
            : ctx.maxNumberOfDays,
        modifiers: {
          firstOfMonth: (day) =>
            dayjs
              .tz(day, ctx.timezone)
              .isSame(dayjs.tz(day, ctx.timezone).startOf('month')),
          lastOfMonth: (day) =>
            dayjs
              .tz(day, ctx.timezone)
              .isSame(
                dayjs.tz(day, ctx.timezone).endOf('month').startOf('day')
              ),
          hoverTrail: buildHoverTrailMatcher(),
          hoverTrailEnd: buildHoverTrailEndMatcher(),
          hoverTrailStart: buildHoverTrailStartMatcher(),
        },
        modifiersClassNames: {
          firstOfMonth: 'first-month-day',
          lastOfMonth: 'last-month-day',
          hoverTrail: 'hover-trail',
          hoverTrailStart: `hover-trail-start-${getModifierClassName()}`,
          hoverTrailEnd: `hover-trail-end-${getModifierClassName()}`,
        },
      }}
      help={getHelpMessage()}
      helpSx={
        shouldStretchHelp
          ? {
              '&.date-picker-help-container': {
                width: 'calc(200% - 48px)',
                bgcolor: 'grey.100',
              },
            }
          : undefined
      }
      {...infinitePickerProps}
    />
  );
};
