import { forwardRef, useContext } from 'react';

import { Field, FieldProps } from 'react-final-form';

import { Checkbox, CheckboxProps } from '@cast/design-system';

import { useControlsGroupState } from './controls-group';
import { FlagsContext } from './flags';
import { useRffFormState } from '../hooks';

type Props = Omit<CheckboxProps, 'name'> & {
  name: string;
  fieldProps?: Partial<FieldProps<string, any>>;
};

export const RffCheckbox = forwardRef<HTMLElement, Props>(
  (
    {
      fieldProps = {},
      name: _name,
      onChange: _onChange,
      ...checkboxProps
    }: Props,
    ref: any
  ) => {
    const ctx = useContext(FlagsContext);
    const { mode } = useRffFormState();
    const { disabled, readOnly, name } = useControlsGroupState({
      disabled: checkboxProps.disabled,
      readOnly: mode === 'view' || checkboxProps?.readOnly,
      name: _name,
    });

    return (
      <Field
        type="CHECKBOX"
        {...fieldProps}
        name={name || _name}
        render={(props) => {
          const { input, meta } = props;
          let onChange = input.onChange;
          let value = input.value;
          if (ctx) {
            onChange = () => {
              ctx.toggleFlag(name || _name);
            };
            value = ctx.isFlagChecked(name || _name);
          }
          return (
            <Checkbox
              onChange={(event, value) => {
                onChange(event, value);
                _onChange?.(event, value);
              }}
              checked={typeof value === 'string' ? value === 'true' : value}
              ref={ref}
              name={name || _name}
              {...checkboxProps}
              disabled={disabled || readOnly}
              error={meta.error}
            />
          );
        }}
      />
    );
  }
);

RffCheckbox.displayName = 'RffCheckbox';
