import { getOrgId, isDemoMode } from '../demo';

const getSecurityDemoKey = (orgId: string) => `security-demo-mode-${orgId}`;

export const setSecurityDemoMode = (orgId?: string | null) => {
  if (!orgId) {
    orgId = new URLSearchParams(window.location.search).get('org');
  }

  if (orgId) {
    sessionStorage.setItem(getSecurityDemoKey(orgId), 'true');
  }
};

export const removeSecurityDemoMode = () => {
  const orgId = new URLSearchParams(window.location.search).get('org');

  if (orgId) {
    sessionStorage.removeItem(getSecurityDemoKey(orgId));
  }
};

export const isSecurityDemoMode = () => {
  const orgId = getOrgId();
  if (orgId) {
    return (
      isDemoMode() || Boolean(sessionStorage.getItem(getSecurityDemoKey(orgId)))
    );
  }
  return false;
};
