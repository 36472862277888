import { useEffect } from 'react';

import { analyticsEvents } from 'core/analytics';
import {
  getCastAiWebCookie,
  ProductValues,
  readCookieParams,
} from 'core/cookies';
import { COOKIE_KEYS, ENTRY_MODES } from 'core/cookies/constants';
import { useMarkEligibleForTrialMutation } from 'hooks/mutations/trial';
import { useNavigate } from 'hooks/useNavigate';
import { deleteCookie } from 'utils/cookie';

const redirectMap: Record<ProductValues, string> = {
  security: '/organization/security/dashboard',
  'ai-enabler': '/llm/report',
  'ai-optimizer': '/llm/report',
};

export const useConsumeCastAiWebCookie = (isLoading: boolean) => {
  const navigate = useNavigate();
  const { mutate: markOrganizationAsEligibleForTrial } =
    useMarkEligibleForTrialMutation();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const cookie = getCastAiWebCookie();

    if (cookie) {
      analyticsEvents.useCameFromCastAiWeb(cookie);
      deleteCookie({
        name: COOKIE_KEYS.CAST_AI_WEB_COOKIE,
        domain:
          document.location.hostname === 'localhost' ? 'localhost' : '.cast.ai',
      });
    }

    if (cookie?.query) {
      const cookieParams = readCookieParams(cookie?.query);
      const markAsEligibleForTrial =
        cookieParams?.get('mode') === ENTRY_MODES.TRIAl_ENTRY;

      if (markAsEligibleForTrial) {
        markOrganizationAsEligibleForTrial();
      }
    }

    if (!cookie?.product) {
      return;
    }

    const redirectPath = redirectMap[cookie.product];
    if (!redirectPath) {
      return;
    }

    navigate(redirectPath, { replace: true });
  }, [isLoading, markOrganizationAsEligibleForTrial, navigate]);
};
