import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { useFormState } from 'react-final-form';

import { Button, withProps, Skeleton, Icons } from '@cast/design-system';
import {
  SecurityInsightsCheckExceptionsGroup,
  SecurityInsightsCheckExceptionsPayload,
  SecurityInsightsCheckExceptionsResponse,
} from '@cast/types';

import { FailedToLoad } from 'components/messages';
import { orgSecurityEvents } from 'core/analytics';
import { InputsStack, RemoveInputs, RffForm } from 'core/forms/rff';
import { useActiveClusters } from 'features/organization/_hooks';
import { nonNamespacedLabel } from 'features/organization/security-insights/constants';
import { useClustersOrFixture } from 'features/organization/security-insights/hooks/useClustersOrFixture';
import { useSaveBestPracticeCheckExceptionsMutation } from 'hooks/mutations/security-insights';
import {
  useBestPracticeCheckExceptionsQuery,
  useBestPracticeCheckExceptionsResourcesQuery,
} from 'hooks/queries/security-insights';

import { KindSelect } from './KindSelect';
import { PrefixInput } from './PrefixInput';
import { Resources } from './Resources';
import { RffClusterSelect } from './RffClusterSelect';
import { RffNamespaceSelect } from './RffNamespaceSelect';
import { makeFormModel } from '../utils';

const InputSkeleton = withProps(Skeleton, {
  height: 32,
  'data-testid': 'input-skeleton',
} as any);

const prepareGroups = (
  groups: Array<SecurityInsightsCheckExceptionsGroup | undefined>
) =>
  groups.map((group) => ({
    ...group,
    namespaces: group?.namespaces?.map((ns) =>
      ns === nonNamespacedLabel ? '' : ns
    ),
  }));

const hasFiltersSet = (
  groups: Array<SecurityInsightsCheckExceptionsGroup | undefined>
) => groups.some((group) => Object.values(group ?? {}).some((x) => x.length));

type InnerProps = {
  isLoadingExceptions: boolean;
  isApplyingExceptions: boolean;
  ruleId: string;
  closeDrawer: () => void;
  onClearAll: () => void;
};

const InnerExceptionsForm = ({
  ruleId,
  isLoadingExceptions,
  isApplyingExceptions,
  closeDrawer,
  onClearAll,
}: InnerProps) => {
  const form = useFormState();
  const groups = form.values.groups as SecurityInsightsCheckExceptionsGroup[];
  const preparedGroups = useMemo(() => prepareGroups(groups), [groups]);
  const isFilterSet = useMemo(() => hasFiltersSet(groups), [groups]);

  const {
    resources,
    isLoading: isLoadingResources,
    error: resourcesError,
    refetch: refetchResources,
  } = useBestPracticeCheckExceptionsResourcesQuery({
    ruleId,
    groups: preparedGroups,
    enabled: isFilterSet,
  });
  const { clusters: _clusters } = useActiveClusters();
  const { clusters } = useClustersOrFixture(_clusters);
  const resourcesData = useMemo(
    () =>
      resources?.map((r) => ({
        ...r,
        providerNamespaceId: clusters.find((c) => c.id === r.clusterId)
          ?.providerNamespaceId,
      })),
    [resources, clusters]
  );

  return (
    <>
      <Stack
        mx={-32}
        padding="14px 32px 24px 32px"
        bgcolor="grey.50"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="grey.200"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={21}
        >
          <Typography variant="P14B" color="grey.900">
            Select the group of resources to be excepted:
          </Typography>
          <Button
            variant="ghost"
            onClick={onClearAll}
            startIcon={<Icons.X />}
            size="small"
            data-testid="clear-all-exceptions"
            disabled={!isFilterSet}
          >
            Clear all
          </Button>
        </Stack>
        <InputsStack
          name="groups"
          withAddRowButton={!isLoadingExceptions}
          addRowButtonText="Add another group"
          addRowButtonTestId="add-group"
          renderInputsRow={({ index }) => {
            return (
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 3fr auto"
                gap={8}
              >
                {isLoadingExceptions ? (
                  <>
                    <InputSkeleton />
                    <InputSkeleton />
                    <InputSkeleton />
                    <InputSkeleton />
                  </>
                ) : (
                  <>
                    <RffClusterSelect index={index} />
                    <RffNamespaceSelect index={index} />
                    <KindSelect index={index} />
                    <PrefixInput
                      index={index}
                      resources={resources ?? []}
                      isLoading={isLoadingResources}
                    />
                    <Box sx={index === 0 ? { mt: 22 } : undefined}>
                      <RemoveInputs
                        testId="remove-group"
                        size={20}
                        style={{ paddingLeft: 0, marginTop: 6 }}
                      />
                    </Box>
                  </>
                )}
              </Box>
            );
          }}
        />
      </Stack>
      <Stack>
        <Typography variant="P14B" color="grey.900" mb={16} mt={24}>
          Summary of resources that will be excepted:
        </Typography>
        <Resources
          resources={resourcesData}
          isLoading={isLoadingResources}
          isError={!!resourcesError}
          refetch={refetchResources}
        />
      </Stack>
      <Box display="flex" justifyContent="flex-end" my={24} gap={16}>
        <Button variant="tertiary" onClick={closeDrawer}>
          Cancel
        </Button>
        <Button
          data-testid="apply-exceptions-button"
          variant="primary"
          type="submit"
          disabled={isLoadingExceptions}
          loading={isApplyingExceptions}
        >
          Apply
        </Button>
      </Box>
    </>
  );
};

type Props = {
  ruleId: string;
  closeDrawer: () => void;
};

export const ExceptionsForm = ({ ruleId, closeDrawer }: Props) => {
  const {
    isLoading: isLoadingExceptions,
    exceptions,
    error: exceptionsError,
    refetch: refetchExceptions,
  } = useBestPracticeCheckExceptionsQuery(ruleId || '');
  const { mutate, isPending } = useSaveBestPracticeCheckExceptionsMutation(
    ruleId || ''
  );

  const { clusters: _clusters } = useActiveClusters();
  const { clusters } = useClustersOrFixture(_clusters);
  // Filter exceptions to only include clusters that are active
  const filteredExceptions =
    useMemo((): SecurityInsightsCheckExceptionsResponse => {
      return {
        groups: exceptions?.groups
          ?.map((group) => {
            return {
              ...group,
              clusters: group?.clusters?.filter((cluster) =>
                clusters.some((c) => c.id === cluster)
              ),
            };
          })
          .filter((group) => !!group?.clusters?.length),
      };
    }, [clusters, exceptions?.groups]);

  if (exceptionsError) {
    return (
      <Stack padding="17px 32px 24px 32px">
        <FailedToLoad refresh={refetchExceptions} />
      </Stack>
    );
  }

  return (
    <RffForm
      mode="create"
      initialValues={makeFormModel(filteredExceptions)}
      initialValuesEqual={isEqual}
      onSubmit={(payload: SecurityInsightsCheckExceptionsPayload) => {
        const { groups = [] } = payload;
        if (hasFiltersSet(groups)) {
          mutate({
            groups: prepareGroups(groups),
            version: exceptions?.version,
          });
        } else {
          mutate({ groups: [], version: exceptions?.version }); // Otherwise API fails
        }
        orgSecurityEvents.complianceCheckExceptionApplied(ruleId);
        closeDrawer();
      }}
      array
    >
      {({ form }) => (
        <InnerExceptionsForm
          isLoadingExceptions={isLoadingExceptions}
          isApplyingExceptions={isPending}
          ruleId={ruleId}
          closeDrawer={closeDrawer}
          onClearAll={() => form.reset(makeFormModel())}
        />
      )}
    </RffForm>
  );
};
