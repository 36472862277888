import { ReactNode, useState } from 'react';

import { Box, Stack, SxProps } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { TZDate } from 'react-day-picker';

import { FilterPresetsList } from './FilterPresetsList';
import { RangeInfinitePicker } from './RangeInfinitePicker';
import { RangePickerInput } from './RangePickerInput';
import { DatePickerFooter } from '../../components/DatePickerFooter';
import {
  DatePickerInput,
  DatePickerInputProps,
} from '../../components/DatePickerInput';
import { DatePickerPopper } from '../../components/DatePickerPopper';
import { defaultDateFormat, defaultDateTimeFormat } from '../../constants';
import DatePickerProvider from '../../DatePickerProvider';
import {
  DatePeriod,
  DatePeriodInTimestamp,
  FilterPreset,
  InfiniteDayPickerRef,
  MarkedDate,
  RangeCondition,
} from '../../types';
import {
  isSelectedFilterPreset,
  utcNow,
  utcToDate,
  utcToRange,
  convertRange,
  buildPresetRange,
  rangeToUtc,
  dateToInputString,
} from '../../utils';

export type RangePickerProps = {
  initialRange?: DatePeriodInTimestamp;
  initialPreset?: string;
  onChange?: (
    range: DatePeriodInTimestamp,
    timezone: string,
    preset?: FilterPreset
  ) => void;
  format?: string;
  triggerInputProps?: DatePickerInputProps['inputProps'];
  filterPresets?: FilterPreset[];
  endMonth?: RangeCondition;
  startMonth?: RangeCondition;
  minDate?: RangeCondition;
  maxDate?: RangeCondition;
  hideRangeInput?: boolean;
  allowTimeInput?: boolean;
  hidePresets?: boolean;
  maxNumberOfDays?: number;
  hideTimezonePicker?: boolean;
  initialTimezone?: string;
  markedDates?: MarkedDate[];
  hint?:
    | ReactNode
    | ((args: {
        range: DatePeriodInTimestamp;
        timezone: string;
        pickerRef: InfiniteDayPickerRef;
      }) => ReactNode);
  inputValue?: (
    range: DatePeriodInTimestamp,
    timezone: string,
    preset?: FilterPreset
  ) => string;
  sx?: SxProps;
};

dayjs.extend(utc);
dayjs.extend(timezone);

export const RangePicker = ({
  initialRange,
  onChange,
  filterPresets = [],
  allowTimeInput = false,
  format: _format = allowTimeInput ? defaultDateTimeFormat : defaultDateFormat,
  triggerInputProps,
  startMonth,
  endMonth,
  minDate,
  maxDate,
  hint,
  maxNumberOfDays,
  hidePresets,
  hideRangeInput,
  hideTimezonePicker = false,
  initialTimezone = dayjs.tz.guess(),
  markedDates = [],
  inputValue,
  sx,
}: RangePickerProps) => {
  const [pickerRef, setPickerRef] = useState<InfiniteDayPickerRef>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [selectedRange, setSelectedRange] = useState<DatePeriod>(() =>
    utcToRange(initialRange, initialTimezone)
  );
  const [initialMonth] = useState<TZDate>(() => {
    const initialDate = initialRange?.from
      ? initialRange.from
      : initialRange?.to;

    return utcToDate(initialDate ? initialDate : utcNow(), initialTimezone);
  });

  const [timezone, setTimezone] = useState<string>(initialTimezone);

  const [startPlaceholder, setStartPlaceholder] = useState('');
  const [endPlaceholder, setEndPlaceholder] = useState('');

  const handleOpen = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClose = () => {
    setTimezone(initialTimezone);
    setSelectedRange(utcToRange(initialRange, initialTimezone));
    handleClose();
  };

  const handleTimezoneChange = (newTimezone: string) => {
    const activePreset = filterPresets.find((preset) =>
      isSelectedFilterPreset(selectedRange, preset, timezone)
    );

    const convertedSelection = activePreset
      ? buildPresetRange(activePreset, newTimezone)
      : convertRange(selectedRange, timezone, newTimezone);

    setSelectedRange(convertedSelection);
    setTimezone(newTimezone);
  };

  const handleApply = () => {
    const activePreset = filterPresets.find((preset) =>
      isSelectedFilterPreset(selectedRange, preset, timezone)
    );
    onChange?.(rangeToUtc(selectedRange), timezone, activePreset);
    handleClose();
  };

  const formattedInputValue = (range: DatePeriod) => {
    if (range?.from && range?.to) {
      return `${dateToInputString(
        range.from,
        _format,
        timezone
      )} - ${dateToInputString(range.to, _format, timezone)}`;
    }

    return '';
  };

  const inputValueFormatter = inputValue
    ? inputValue.bind(null, rangeToUtc(selectedRange), timezone)
    : formattedInputValue.bind(null, selectedRange);

  return (
    <DatePickerProvider
      mode="range"
      format={_format}
      inputValue={inputValueFormatter}
      selected={selectedRange}
      setSelected={setSelectedRange}
      popoverAnchor={anchorEl}
      setPopoverAnchor={setAnchorEl}
      handleOpen={handleOpen}
      handleClose={onClose}
      handleApply={handleApply}
      startMonth={startMonth}
      endMonth={endMonth}
      minDate={minDate}
      maxDate={maxDate}
      initialMonth={initialMonth}
      pickerRef={pickerRef}
      setPickerRef={setPickerRef}
      maxNumberOfDays={maxNumberOfDays}
      filterPresets={filterPresets}
      startPlaceholder={startPlaceholder}
      setStartPlaceholder={setStartPlaceholder}
      endPlaceholder={endPlaceholder}
      setEndPlaceholder={setEndPlaceholder}
      allowTimeInput={allowTimeInput}
      hideTimezonePicker={hideTimezonePicker}
      timezone={timezone}
      setTimezone={handleTimezoneChange}
      markedDates={markedDates}
    >
      <DatePickerInput
        sx={sx}
        inputProps={triggerInputProps}
        popperContent={
          <DatePickerPopper
            footer={
              <DatePickerFooter
                isMini={hidePresets || !filterPresets.length}
                hint={
                  typeof hint === 'function'
                    ? hint({
                        range: rangeToUtc(selectedRange),
                        timezone,
                        pickerRef,
                      })
                    : hint
                }
              />
            }
            aside={
              hidePresets || !filterPresets.length ? null : (
                <Stack
                  minWidth={178}
                  padding="8px 16px"
                  borderRight={(theme) =>
                    `1px solid ${theme.palette.grey[200]}`
                  }
                >
                  <FilterPresetsList />
                </Stack>
              )
            }
          >
            <Stack pt={16} gap={8}>
              {!hideRangeInput && (
                <Box px={24}>
                  <RangePickerInput />
                </Box>
              )}

              <RangeInfinitePicker />
            </Stack>
          </DatePickerPopper>
        }
      />
    </DatePickerProvider>
  );
};
