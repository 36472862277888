import { SecurityVisualSvg } from 'assets/svg';

import {
  AgentSetupBlock,
  AgentSetupBlockProps,
} from './_components/AgentSetupBlock';
import { AgentSetupCompleteBlock } from './_components/AgentSetupCompleteBlock';
import FeatureLabel from './_components/FeatureLabel';

type Props = Partial<AgentSetupBlockProps>;

export const AgentSecuritySetup = (props: Props) => {
  const heading = 'Kubernetes & cloud security';
  const icon = <SecurityVisualSvg />;

  if (props.setupComplete) {
    return (
      <AgentSetupCompleteBlock
        heading={heading}
        icon={icon}
        testId="agent-security-complete"
        {...props}
      />
    );
  }

  return (
    <AgentSetupBlock
      heading={heading}
      description="Protect your cluster with real-time threat detection, vulnerability scanning, and proactive security measures"
      icon={icon}
      labelChip={<FeatureLabel isPremium />}
      checkboxTestId="agent-security-checkbox"
      {...props}
    />
  );
};
