import { AllocationGroup, getLinkToReport, LinkParams } from './navigation';

export const makeOrgCostMonitoringClustersLink = (
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ object: 'clusters' }, {}, queryParams);
};

export const makeOrgCostMonitoringClustersComputeCostLink = (
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'clusters', reportType: 'computeCost' },
    {},
    queryParams
  );
};

export const makeOrgCostMonitoringClustersEfficiencyLink = (
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'clusters', reportType: 'efficiency' },
    {},
    queryParams
  );
};

export const makeOrgCostMonitoringAllocationGroupsLink = (
  params: LinkParams<AllocationGroup> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ object: 'allocationGroups' }, params, queryParams);
};

export const makeOrgCostMonitoringAllocationGroupsComputeCostLink = (
  params: LinkParams<AllocationGroup> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'allocationGroups', reportType: 'computeCost' },
    params,
    queryParams
  );
};

export const makeOrgCostMonitoringAllocationGroupComputeCostLink = (
  params: LinkParams<AllocationGroup> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'allocationGroup', reportType: 'computeCost' },
    params,
    queryParams
  );
};

export const makeOrgCostMonitoringComputeCostLink = (
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'computeCost' }, {}, queryParams);
};

export const makeOrgCostMonitoringNetworkCostLink = (
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'networkCost' }, {}, queryParams);
};

export const makeOrgCostMonitoringEfficiencyLink = (
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'efficiency' }, {}, queryParams);
};

export const makeOrgCostMonitoringGpuUtilizationLink = (
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'gpuUtilization' }, {}, queryParams);
};
