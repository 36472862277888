import { styled } from '@mui/material';

import { StatusChipBase } from './StatusChip';

type Props = {
  label?: string;
  className?: string;
};

export const ClusterDemoStatusChip = styled(({ label, className }: Props) => {
  return (
    <StatusChipBase className={className} hasCaption={false}>
      {label || 'Demo'}
    </StatusChipBase>
  );
})(({ theme }) => ({
  // FIXME: remove ! after old theme.d.ts is removed
  backgroundColor: theme.palette.aqua![100],
  color: theme.palette.aqua![900],
}));

ClusterDemoStatusChip.displayName = 'DemoStatusChip';
