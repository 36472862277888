import {
  RoleAccess,
  RbacGroupRef,
  RbacPermissions,
  UserResourceBinding,
  GroupResourceBinding,
  RbacRoleBinding,
  ServiceAccountResourceBinding,
} from '@cast/types';

type Access = {
  id: string;
  access: RoleAccess;
  accessVia: AccessVia;
  hasAccessTo: RbacPermissions['endpoints'];
};

type AccessWithBinding = Access & { accessViaBinding: AccessViaBinding };

export type ResourceAccessByResourceId = Record<string, ResourceAccess>;

export type ControlData = {
  organizations: ResourceAccessByResourceId;
  clusters: ResourceAccessByResourceId;
};

export type ControlDataBySubjectId = Record<string, ControlData>;

export type AccessControlContextState = {
  isLoading: boolean;
  accessControlData: ControlDataBySubjectId;
  refetch: () => void;
  error: boolean;
};

export type ResourceAccess = Access | AccessWithBinding;

export type ScopeAccess = Record<string, ResourceAccess>;

export type AccessControlData = Record<
  string,
  {
    organizations: ScopeAccess;
    clusters: ScopeAccess;
  }
>;

export type SubjectResourceBinding =
  | UserResourceBinding
  | GroupResourceBinding
  | ServiceAccountResourceBinding;

export const isUserResourceBinding = (
  binding: SubjectResourceBinding
): binding is UserResourceBinding => {
  return (binding as UserResourceBinding).userId !== undefined;
};

export const isGroupResourceBinding = (
  binding: SubjectResourceBinding
): binding is GroupResourceBinding => {
  return (binding as GroupResourceBinding).groupId !== undefined;
};

export const isServiceAccountBinding = (
  binding: SubjectResourceBinding
): binding is ServiceAccountResourceBinding => {
  return (
    (binding as ServiceAccountResourceBinding).serviceAccountId !== undefined
  );
};

export const getResourceBinding = (binding: SubjectResourceBinding) => {
  if (isUserResourceBinding(binding)) {
    return binding.userId;
  }

  if (isGroupResourceBinding(binding)) {
    return binding.groupId;
  }

  if (isServiceAccountBinding(binding)) {
    return binding.serviceAccountId;
  }
};

export type AccessViaBinding = {
  bindingId: string;
  definition: RbacRoleBinding['definition'];
  status: 'active' | 'processing' | 'error';
  statusChangedAt?: string;
  errorMessage?: string;
};

export type AccessVia = 'direct' | 'inherited' | RbacGroupRef[];
