import { RuntimeV1ListEntryKind } from '@cast/openapi';

export type {
  RuntimeV1GetAnomaliesResponse as AnomaliesResponse,
  RuntimeV1GetAnomalyResponse as AnomalyDetailsResponse,
  RuntimeV1AnomalyEvent as AnomalyEvent,
  RuntimeV1Anomaly as Anomaly,
  RuntimeV1GetAnomaliesOverviewResponse as AnomaliesOverviewResponse,
  RuntimeV1GetRulesResponse as RuntimeRulesResponse,
  RuntimeV1Rule as RuntimeRule,
  RuntimeV1CreateRuleRequest as CreateRuleRequest,
  RuntimeSecurityAPIEditRuleRequest as EditRuleRequest,
  RuntimeV1GetRuleResponse as RuntimeRuleResponse,
  RuntimeV1GetAnomalyEventsResponse as AnomalyEventsResponse,
  RuntimeV1GetRuntimeEventsResponse as RuntimeEventsResponse,
  RuntimeV1GetRuntimeEventsResponseRuntimeEvent as RuntimeEvent,
  RuntimeV1GetRuntimeEventGroupsResponse as RuntimeEventsGroupsResponse,
  RuntimeV1GetRuntimeEventsProcessTreeResponseEventProcess as ProcessTreeEvent,
  RuntimeV1GetRuntimeEventsProcessTreeResponse as ProcessTreeResponse,
  RuntimeV1ListHeader as CustomDenyList,
  RuntimeV1ListHeaderWithRules as CustomDenyListWithRules,
  RuntimeV1GetListsResponse as CustomDenyListsResponse,
  RuntimeV1ListEntry as CustomDenyListEntry,
  RuntimeV1GetListEntriesResponse as CustomDenyListEntriesResponse,
} from '@cast/openapi';

export {
  RuntimeV1AnomalyStatus as AnomalyStatus,
  RuntimeV1CloseReason as AnomalyCloseReason,
  RuntimeSecurityAPIGetRulesSeverityEnum as RuntimeRuleSeverity,
  RuntimeV1RuleEngineType as RuleEngineType,
  RuntimeV1ListEntryKind as CustomDenyListEntryKind,
} from '@cast/openapi';

export const CustomDenyListEntryKindValues = Object.fromEntries(
  Object.entries(RuntimeV1ListEntryKind).filter(
    ([_, value]) => value !== 'LIST_ENTRY_KIND_UNKNOWN'
  )
);
