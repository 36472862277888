import { Cluster } from '@cast/types';

import { isAKSCluster } from 'types/cluster';

import { ProductName } from '../../types';

type GenerateRedirectPathArgs = {
  featureSelection: ProductName[];
  cluster: Cluster;
  isInSecurityPage: boolean;
};

export const generateRedirectPath = ({
  featureSelection,
  cluster,
  isInSecurityPage,
}: GenerateRedirectPathArgs): string | undefined => {
  const autoscalerEnabled = featureSelection.includes('autoscaler');
  const securityEnabled = featureSelection.includes('security');
  const onlyReportingEnabled = !autoscalerEnabled && !securityEnabled;

  if (isInSecurityPage || (securityEnabled && !autoscalerEnabled)) {
    return '/organization/security/dashboard';
  } else if (!isAKSCluster(cluster) && !onlyReportingEnabled) {
    return `/external-clusters/${cluster.id}/autoscaler`;
  }
};
