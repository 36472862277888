import { useMemo } from 'react';

import { useActiveClusters } from 'features/organization/_hooks';
import { useBestPracticesFilters } from 'hooks/queries/security-insights';

import { LabelsSelect as LabelsSelectBase } from '../../_components/LabelsSelect';
import { useClustersOrFixture } from '../../hooks/useClustersOrFixture';

type Props = {
  initialValue?: string[];
};

export const LabelsSelect = ({ initialValue }: Props) => {
  const { clusters: _clusters } = useActiveClusters();
  const { clusters } = useClustersOrFixture(_clusters);
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { isLoading, filters, error, refetch } = useBestPracticesFilters({
    clusterIds,
  });

  return (
    <LabelsSelectBase
      isLoading={isLoading}
      labels={filters?.labels}
      error={!!error}
      refetch={refetch}
      criterion="labels"
      initialValue={initialValue}
    />
  );
};
