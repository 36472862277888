import {
  ComponentType,
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { ClickAwayListener } from '@mui/base';
import { useTheme } from '@mui/material';
import reduce from 'lodash/reduce';
import { nanoid } from 'nanoid';

import { drawerInitialState, drawerReducer } from './drawerReducer';
import {
  DrawerReducerState,
  DrawerReducerAction,
  ComponentProps,
} from './types';
import { findChildrenDrawerIds, findParentDrawerIds } from './utils';

export type OpenDrawerOptions = {
  parentId?: string;
  keepChildren?: boolean;
};

export type DrawerInstance = {
  id: string;
  update: (id: string, props: ComponentProps) => void;
  destroy: (id: string) => void;
};

export type DrawerContextState = {
  open: (
    component: ComponentType<ComponentProps>,
    props: ComponentProps,
    options?: OpenDrawerOptions
  ) => DrawerInstance;
  drawers: DrawerReducerState['drawers'];
};

export const DrawerContext = createContext<DrawerContextState>({} as never);

export const DrawerInstanceContext = createContext<
  DrawerReducerState['drawers']['string'] & {
    close: (onComplete?: () => void) => void;
    shouldRenderGuard: boolean;
    setShouldRenderGuard: (guard: boolean) => void;
    setOnCloseGuard: (guard: boolean) => void;
    isInDrawer: boolean;
  }
>({ isInDrawer: false } as never);

type RenderDrawerProps = {
  id: string;
  state: DrawerReducerState;
  dispatch: Dispatch<DrawerReducerAction>;
};

const getTranslateX = (element: Element) => {
  const style = window.getComputedStyle(element);
  const matrix = new WebKitCSSMatrix(style.transform);
  return matrix.m41 > 0 ? matrix.m41 : 0;
};

const hasClickedDrawer = (e: MouseEvent | TouchEvent, _id: string) => {
  const nestedDrawer = document.getElementById(_id);
  if (nestedDrawer && e.srcElement instanceof Node) {
    const isClickInsidePopover = e
      .composedPath()
      .some(
        (target) =>
          target instanceof Element &&
          target.classList.contains('MuiPopover-root')
      );

    return (
      isClickInsidePopover ||
      nestedDrawer.contains(e.srcElement) ||
      nestedDrawer === e.srcElement
    );
  }

  return false;
};

const RenderDrawer = ({ id, state, dispatch }: RenderDrawerProps) => {
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLDivElement | undefined>();
  const [shouldRenderGuard, setShouldRenderGuard] = useState<boolean>(false);
  const [onCloseGuard, setOnCloseGuard] = useState<boolean>(false);
  const drawerTranslateX = useRef<number>(0);

  const parentDrawerIds = findParentDrawerIds(id, Object.values(state.drawers));
  const childrenDrawerIds = findChildrenDrawerIds(
    id,
    Object.values(state.drawers)
  );

  const {
    component: Component,
    props,
    parentId,
    translateX,
  } = state.drawers[id];
  const parent = parentId ? state.drawers[parentId] : undefined;

  const closeSingleDrawer = (onComplete?: () => void) => {
    // initiates drawer transition
    dispatch({
      type: 'update',
      payload: {
        id,
        props: {
          open: false,
        },
      },
    });

    // initiates parent drawer transition
    if (parentId) {
      const siblingDrawerIds = reduce(
        state.drawers,
        (acc, drawer) => {
          if (drawer.parentId === parentId && drawer.id !== id) {
            acc.push(drawer.id);
          }
          return acc;
        },
        [] as string[]
      );

      if (!siblingDrawerIds.length) {
        dispatch({
          type: 'update',
          payload: {
            id: parentId,
            translateX: -drawerTranslateX.current,
          },
        });
      }
    }

    // waits for transition to end and removes drawer from state
    setTimeout(() => {
      dispatch({ type: 'destroy', payload: { id } });
      onComplete?.();
    }, theme.transitions.duration.leavingScreen);
  };

  const closeAllDrawers = () => {
    Object.keys(state.drawers).forEach((id) => {
      dispatch({
        type: 'update',
        payload: {
          id,
          props: { open: false },
          translateX: 0,
        },
      });
    });

    // waits for transition to end and removes drawer from state
    setTimeout(() => {
      dispatch({ type: 'destroy', payload: { id } });
    }, theme.transitions.duration.leavingScreen);
  };

  const handleClose = (all = false) => {
    if (!onCloseGuard) {
      setShouldRenderGuard(false);
      all ? closeAllDrawers() : closeSingleDrawer();
    } else {
      setShouldRenderGuard(true);
    }
    /* eslint-disable-next-line react/prop-types */
    props?.onClose?.();
  };

  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    if (e.srcElement instanceof Node) {
      const isChildGuardActive = childrenDrawerIds.some(
        (_id) => state.drawers[_id].closeGuardIsActive
      );

      const hasClickedDrawerFn = hasClickedDrawer.bind(null, e);
      const hasClickedChild = childrenDrawerIds.some(hasClickedDrawerFn);
      const hasClickedParent = parentDrawerIds.some(hasClickedDrawerFn);

      if (isChildGuardActive || hasClickedChild) {
        return;
      }

      handleClose(!(hasClickedParent || state.order.length === 1));
    }
  };

  const handleForceClose = (onComplete?: () => void) => {
    closeSingleDrawer(onComplete);
    /* eslint-disable-next-line react/prop-types */
    props?.onClose?.();
  };

  // opening with open false for animation to be present
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch({
        type: 'update',
        payload: {
          id,
          props: { open: true, className: 'DS-Drawer-no-backdrop' },
        },
      });
    }, theme.transitions.duration.enteringScreen);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, id, theme.transitions.duration.enteringScreen]);

  // memorize child ref for translateX calculation
  useEffect(() => {
    if (parentId && parent && ref) {
      drawerTranslateX.current = getTranslateX(
        ref.querySelector('.MuiDrawer-paper')!
      );

      dispatch({
        type: 'update',
        payload: {
          id: parentId,
          translateX: drawerTranslateX.current,
          initialTranslateX: drawerTranslateX.current,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, parentId, ref]);

  useEffect(() => {
    let transform = 'none';

    if (translateX && translateX > 0) {
      transform = `translateX(-${translateX}px)`;
    }
    dispatch({
      type: 'update',
      payload: {
        id,
        props: {
          open: true,
          PaperProps: {
            style: { transform },
          },
        },
      },
    });
  }, [dispatch, id, translateX]);

  useEffect(() => {
    if (!onCloseGuard) {
      setShouldRenderGuard(false);
    }
  }, [onCloseGuard]);

  useEffect(() => {
    dispatch({
      type: 'update',
      payload: {
        id,
        closeGuardIsActive: onCloseGuard,
      },
    });
  }, [dispatch, id, onCloseGuard, shouldRenderGuard]);

  return (
    <DrawerInstanceContext.Provider
      value={{
        ...state.drawers[id],
        shouldRenderGuard,
        setShouldRenderGuard,
        setOnCloseGuard,
        close: handleForceClose,
        isInDrawer: true,
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Component
          {...props}
          ref={(_ref: HTMLDivElement) => {
            setRef(_ref);
          }}
          id={id}
          onClose={handleClose.bind(null, false)}
        />
      </ClickAwayListener>
    </DrawerInstanceContext.Provider>
  );
};

export const DrawerProvider = ({
  children,
  wrapper,
}: PropsWithChildren<{ wrapper?: (children: ReactNode) => ReactNode }>) => {
  const [state, dispatch] = useReducer(drawerReducer, drawerInitialState);

  const update = useCallback((id: string, props: ComponentProps) => {
    dispatch({
      type: 'update',
      payload: {
        id,
        props,
      },
    });
  }, []);

  const destroy = useCallback((id: string) => {
    dispatch({
      type: 'destroy',
      payload: {
        id,
      },
    });
  }, []);

  const open = (
    component: ComponentType<ComponentProps>,
    props: ComponentProps,
    options?: OpenDrawerOptions
  ) => {
    let id: string;
    const visibleDrawer: DrawerReducerState['drawers'][number] | undefined =
      Object.values(state.drawers).find(({ component: c }) => c === component);

    if (visibleDrawer && visibleDrawer?.closeGuardIsActive) {
      id = visibleDrawer.id;
    } else {
      if (
        options?.keepChildren &&
        visibleDrawer &&
        visibleDrawer.parentId === options?.parentId
      ) {
        id = visibleDrawer.id;

        dispatch({
          type: 'update',
          payload: {
            id,
            props,
          },
        });
      } else {
        id = nanoid();

        dispatch({
          type: 'open',
          payload: {
            id,
            component,
            props,
            parentId: options?.parentId,
          },
        });
      }
    }

    return {
      id,
      update,
      destroy,
    };
  };

  return (
    <DrawerContext.Provider
      value={{
        drawers: state.drawers,
        open,
      }}
    >
      {children}

      {wrapper
        ? wrapper(
            <>
              {state.order.map((id) => (
                <RenderDrawer
                  key={id}
                  id={id}
                  state={state}
                  dispatch={dispatch}
                />
              ))}
            </>
          )
        : state.order.map((id) => (
            <RenderDrawer key={id} id={id} state={state} dispatch={dispatch} />
          ))}
    </DrawerContext.Provider>
  );
};
