import { ReactNode, useMemo, useState } from 'react';

import { Box, useTheme } from '@mui/material';
import approx from 'approximate-number';
import round from 'lodash/round';
import sum from 'lodash/sum';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  Sector,
  Tooltip,
  TooltipProps,
} from 'recharts';

import { linearMap, polarToCartesian, useElementWidth } from '@cast/utils';

import { useThemeColor, useThemeColors } from 'hooks/theme';
import { getThemeColor } from 'utils/theme';

import { PieLabel, PieLabelProps } from '../_components';
import { useIsAnimationActive } from '../hooks';

type Data = {
  value: number;
  color: string;
};

const spike = 5;

const getLayer = (data: Data[], fillColor = 'white') => {
  const value = Math.min(sum(data.map((item) => item.value)), 100);
  return [...data, { color: fillColor, value: 100 - value }];
};

const getOverlay = (data?: Data, color?: string) => {
  if (!data) {
    return [{ color: 'transparent', value: 100 }];
  }
  return [
    { color: 'transparent', value: 100 - data.value },
    { ...data, color },
  ] as Data[];
};

const getCells = (data: Data[], stroke = 'none') =>
  data.map((entry, index) => (
    <Cell key={index} fill={entry.color} stroke={stroke} />
  ));

type BorderProps = {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  separatorColor: string;
};
const Border = ({
  cx,
  cy,
  outerRadius,
  innerRadius,
  startAngle,
  separatorColor,
}: BorderProps) => {
  const p1 = polarToCartesian(cx, cy, innerRadius - spike, startAngle);
  const p2 = polarToCartesian(cx, cy, outerRadius + spike, startAngle);
  return (
    <g>
      <line x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} stroke={separatorColor} />
    </g>
  );
};
type AppendixProps = BorderProps & { fill: string };
const Appendix = (props: Partial<AppendixProps>) => {
  return (
    <g>
      <Border {...(props as AppendixProps)} />
      <Sector {...props} />
    </g>
  );
};

type Variant = 'left' | 'right' | 'normal' | 'full';

export type GaugeChartProps = {
  title?: string | number;
  outerLayer: Data[];
  outerLayerBackground?: string;
  innerLayer?: Data[];
  appendix?: Data;
  borderColor?: string;
  subtitle?: ReactNode;
  pieLabelProps?: Partial<PieLabelProps>;
  barWidth?: number;
  tooltip?: ReactNode;
  tooltipProps?: TooltipProps<any, any>;
  variant?: Variant;
};

const anglesByVariant: Record<
  Variant,
  { startAngle: number; endAngle: number }
> = {
  normal: {
    startAngle: 225,
    endAngle: -45,
  },
  left: {
    startAngle: 90,
    endAngle: 270,
  },
  right: {
    startAngle: 90,
    endAngle: -90,
  },
  full: {
    // start filling from the top
    startAngle: 90,
    endAngle: 450,
  },
};

type LayerWithBackgroundProps = {
  size: number;
  isAnimationActive: boolean;
  data: Data[];
  innerRadius: number;
  paddingAngle: number;
  startAngle: number;
  endAngle: number;
  cornerRadius: number;
  outerRadius: number;
  backgroundColor: string;
};
const LayerWithBackground = ({
  isAnimationActive,
  data,
  innerRadius,
  paddingAngle,
  startAngle,
  endAngle,
  cornerRadius,
  outerRadius,
  size,
  backgroundColor,
}: LayerWithBackgroundProps) => {
  const fillerData = [{ value: 100, color: useThemeColor(backgroundColor) }];
  return (
    <>
      <Box position="absolute">
        <PieChart width={size} height={size}>
          <Pie
            isAnimationActive={isAnimationActive}
            dataKey="value"
            data={fillerData}
            innerRadius={innerRadius}
            paddingAngle={paddingAngle}
            startAngle={startAngle}
            endAngle={endAngle}
            cornerRadius={cornerRadius}
            outerRadius={outerRadius}
          >
            {getCells(fillerData)}
          </Pie>
        </PieChart>
      </Box>
      <Box position="absolute">
        <PieChart width={size} height={size}>
          <Pie
            isAnimationActive={isAnimationActive}
            dataKey="value"
            data={data}
            innerRadius={innerRadius}
            paddingAngle={paddingAngle}
            startAngle={startAngle}
            endAngle={endAngle}
            cornerRadius={cornerRadius}
            outerRadius={outerRadius}
          >
            {getCells(data)}
          </Pie>
        </PieChart>
      </Box>
    </>
  );
};

export const GaugeChart = ({
  outerLayer,
  outerLayerBackground = 'white',
  innerLayer,
  appendix,
  title,
  borderColor = 'blue.600',
  subtitle,
  pieLabelProps = {},
  barWidth = 18,
  tooltip,
  variant = 'normal',
  tooltipProps = {},
}: GaugeChartProps) => {
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const containerWidth = useElementWidth(ref) ?? 0;

  const [gaugeBorderColor, appendixColor] = useThemeColors(
    borderColor,
    appendix?.color
  );

  const { startAngle, endAngle } = anglesByVariant[variant];

  const _outerLayer = useMemo(
    () =>
      outerLayer.map((item) => ({
        ...item,
        color: getThemeColor(theme, item.color),
      })),
    [outerLayer, theme]
  );

  const _innerLayer = useMemo(
    () =>
      innerLayer?.map((item) => ({
        ...item,
        color: getThemeColor(theme, item.color),
      })),
    [innerLayer, theme]
  );

  const firstLayer = getLayer(_outerLayer, 'transparent');
  const secondLayer = !!_innerLayer && getLayer(_innerLayer);
  const overlay = getOverlay(appendix, appendixColor);
  const size = containerWidth - spike * 2;
  const outerRadius = (size - 2) / 2;
  const _barWidth = barWidth * (secondLayer ? 1 : 2);
  const cornerRadius = 2;
  const formattedTitle =
    title && typeof title === 'number'
      ? title > 1000
        ? approx(title)
        : round(title, 1)
      : title;

  const isAnimationActive = useIsAnimationActive([
    firstLayer,
    secondLayer,
    overlay,
  ]);

  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        aspectRatio: '1 / 1',
        '& g:focus, & path:focus': { outline: 'none' },
      }}
      ref={setRef}
    >
      <Box position="relative" display="flex" alignItems="center">
        <LayerWithBackground
          size={size}
          isAnimationActive={isAnimationActive}
          data={firstLayer}
          innerRadius={outerRadius - _barWidth}
          paddingAngle={0}
          startAngle={startAngle}
          endAngle={endAngle}
          cornerRadius={cornerRadius}
          outerRadius={outerRadius}
          backgroundColor={outerLayerBackground}
        />
        {secondLayer && (
          <Box position="absolute">
            <PieChart width={size} height={size}>
              <Pie
                isAnimationActive={isAnimationActive}
                dataKey="value"
                data={secondLayer}
                innerRadius={outerRadius - _barWidth * 2}
                paddingAngle={0}
                cornerRadius={cornerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                outerRadius={outerRadius - _barWidth}
              >
                {getCells(secondLayer)}
              </Pie>
            </PieChart>
          </Box>
        )}
        <div>
          <PieChart width={size} height={size}>
            <Pie
              isAnimationActive={isAnimationActive}
              dataKey="value"
              data={overlay}
              innerRadius={outerRadius - _barWidth * (secondLayer ? 2 : 1)}
              paddingAngle={0}
              cornerRadius={cornerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              outerRadius={outerRadius}
              activeIndex={1}
              activeShape={
                <Appendix fill={overlay[1]?.color} separatorColor="#082939" />
              }
            >
              {title !== undefined && (
                <Label
                  width={30}
                  position="center"
                  content={
                    <PieLabel
                      title={String(formattedTitle)}
                      {...pieLabelProps}
                    />
                  }
                />
              )}
              {getCells(overlay, gaugeBorderColor)}
            </Pie>
            {tooltip && (
              <Tooltip
                allowEscapeViewBox={{
                  x: true,
                  y: true,
                }}
                content={() => tooltip}
                isAnimationActive={false}
                {...tooltipProps}
              />
            )}
          </PieChart>
        </div>
      </Box>
      {subtitle && (
        <Box
          position="absolute"
          style={{
            transform: `translateY(${linearMap(
              containerWidth,
              195,
              260,
              144,
              196
            )}px)`,
          }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {subtitle}
        </Box>
      )}
    </div>
  );
};
